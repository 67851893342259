import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
`;
export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RouteLink = styled.h4`
  margin: 2.4rem 0 2.4rem var(--margin-large);
  /* padding: 5.8rem 0; */
  text-transform: uppercase;

  a,
  span {
    text-decoration: none;
    color: black;
  }

  @media screen and (max-width: 1022px) {
    margin: 2rem 0 0.3rem 0.5rem;
    font-size: 12px;
  }
`;
