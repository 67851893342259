import {Badge, BlockSpan,TableCell,TableContainer,TableHeader,TableRow} from "../styles/bookingStyles"
import getLeaderPax from "../utils/getLeaderPax";
import statusParse from "../utils/statusParse"

const Booking = ({ bookings }) => {
  return (
    <div>
      <Badge>MIS RESERVAS</Badge>
      <TableContainer>
        <thead>
          <TableRow>
            <TableHeader>NRO DE RESERVA</TableHeader>
            <TableHeader>CIUDAD</TableHeader>
            <TableHeader>HOTEL</TableHeader>
            <TableHeader>TIPO DE HABITACIÓN</TableHeader>
            <TableHeader>RANGO DE FECHA</TableHeader>
            <TableHeader>INFO PASAJERO</TableHeader>
            <TableHeader>ESTADO</TableHeader>
            <TableHeader>PAGO</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {bookings?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item?.reference_code?.slice(item.reference_code.length - 6,item.reference_code.length )}</TableCell>
              <TableCell>{item?.city}</TableCell>
              <TableCell>{item?.hotel}</TableCell>
              <TableCell>{item?.roomType.join("-")}</TableCell>
              <TableCell>
                <BlockSpan>{item?.tracking.checkin.date}</BlockSpan>
                <BlockSpan>{item?.tracking.checkout.date}</BlockSpan>
              </TableCell>
              <TableCell>
                <BlockSpan>
                  {getLeaderPax(item?.tracking.details.room)?.initial}
                </BlockSpan>
                <BlockSpan>
                  {getLeaderPax(item.tracking.details.room)?.surname}
                </BlockSpan>
              </TableCell>
              <TableCell status={item?.tracking.status.code}>{statusParse(item.tracking.status.code)}</TableCell>
              <TableCell>
                <div style={{display:"flex", flexDirection:'column'}}>
                  <span>Precio: {item?.totalNetPrice}</span>
                  <span>Comision: {item?.agencyComission}</span>
                  <span>A pagar: {item?.agencyPayment}</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </TableContainer>
    </div>
  );
};

export default Booking;