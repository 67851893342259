export const initialValuesConverter = (hotelRooms) =>{

    let roomsArr = hotelRooms.map((item, index) => {
        if (item.type === "sgl") {
          return {
            type: "Simple",
            paxes: [{ name: "", passport: "", gender: "", stage: "adult", leader: index === 0 }],
            extrabed: false,
            babyCot: false
          };
        }
        if (item.type === "dbl") {
          return {
            type: "Doble",
            paxes: [
              { name: "", passport: "", gender: "", stage: "adult" },
              { name: "", passport: "", gender: "", stage: "adult" },
            ],
            extrabed: false,
            babyCot: false,
          };
        }
        if (item.type === "twn") {
          return {
            type: "Twin",
            paxes: [
              { name: "", passport: "", gender: "", stage: "adult" },
              { name: "", passport: "", gender: "", stage: "adult" },
            ],
            extrabed: false,
            babyCot: false,
          };
        }
        if (item.type === "trp") {
          return {
            type: "Triple",
            paxes: [
              { name: "", passport: "", gender: "", stage: "adult" },
              { name: "", passport: "", gender: "", stage: "adult" },
              { name: "", passport: "", gender: "", stage: "adult" },
            ],
            extrabed: false,
            babyCot: false,
          };
        }
        return [];
      });

      return roomsArr
}