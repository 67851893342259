import styled from "styled-components";

export const Container = styled.div`
  /* display: flex; */
  /* height: 100vh; */

  @media screen and (max-width: 875px) {
    max-height: 100%;
    overflow: auto;
  }
`;

export const Form = styled.form`
  /* border: 1px solid blue; */
  background-color: whitesmoke;
  /* padding: 80px; */
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 20px;
  gap: 15px;

  
`;

export const Title = styled.div`
  h4 {
    margin: 0;
  }
`;
export const Row = styled.div`
  display: flex;
  gap: 1.3rem;

  @media screen and (max-width: 875px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 3px;
  width: 100%;

  /* border: 1px solid red; */
`

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 0.6rem 0.6rem;
  font-size: 0.688rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 18px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: grey;
  }
`;

export const Select = styled(Input).attrs({ as: "select" })`
`;

export const TextArea = styled(Input).attrs({ as: "textarea" })`
  resize: none;
`;

export const Button = styled.button`
    background-color: rgb(236, 104, 28);
      color: white;
      padding: 0.6rem 35px;
      border: none;
      border-radius: 18px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      cursor: pointer;

  @media screen and (max-width: 875px) {
    width: 100%;
  }
`

export const ErrorMesage = styled.span`
  color: red;
  font-size: 8px;
  margin-left: 5px;
`