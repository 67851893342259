import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/main.slice";
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.mainSlice.user);

    const logout = () => {
            localStorage.removeItem("token");
            dispatch(removeUser());
        window.location.reload()
    };

    return {user, logout, navigate}
};
