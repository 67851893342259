const iso = {
  // # Africa
  AF: [
    "BF",
    "BI",
    "BJ",
    "BW",
    "RW",
    "GW",
    "GQ",
    "GA",
    "GN",
    "GH",
    "TN",
    "ZM",
    "EH",
    "EG",
    "ZA",
    "ET",
    "SO",
    "ZW",
    "ER",
    "MG",
    "MA",
    "ML",
    "MW",
    "MR",
    "UG",
    "XS",
    "NA",
    "NE",
    "NG",
    "CI",
    "CM",
    "CG",
    "CF",
    "CD",
    "SZ",
    "KE",
    "SS",
    "SN",
    "SL",
    "SD",
    "DJ",
    "DZ",
    "LR",
    "LS",
    "TG",
    "TD",
    "LY",
    "AO",
    "TZ",
    "MZ"
  ],
  // # Asia (excluding Middle East)

  OR: [
    "BD",
    "JP",
    "BT",
    "TM",
    "TJ",
    "PK",
    "PH",
    "VN",
    "UZ",
    "MM",
    "MN",
    "MY",
    "NP",
    "CN",
    "KG",
    "KH",
    "KR",
    "KP",
    "KZ",
    "LA",
    "TW",
    "LK",
    "TH",
    "AF",
    "IN",
    "ID"
  ],

  // # Middle East

  MO: [
    "OM",
    "JO",
    "EG",
    "IL",
    "CY",
    "SY",
    "KW",
    "SA",
    "YE",
    "LB",
    "TR",
    "AE",
    "IQ",
    "IR",
    "QA"
  ],

  // # Europe
  EU: [
    "BE",
    "BG",
    "BA",
    "BY",
    "RU",
    "RS",
    "RO",
    "GR",
    "GB",
    "HR",
    "HU",
    "PT",
    "PL",
    "EE",
    "IT",
    "ES",
    "ME",
    "MD",
    "MK",
    "FR",
    "FI",
    "NL",
    "NO",
    "XK",
    "CH",
    "CZ",
    "SK",
    "SI",
    "SE",
    "DK",
    "DE",
    "LV",
    "LT",
    "IS",
    "AL",
    "AT",
    "IE",
    "UA"
  ],
  // # North America
  NA: ["GL", "MX", "CA", "US"],
  // # Central America
  CA: [
    "JM",
    "BS",
    "BZ",
    "GT",
    "HT",
    "HN",
    "PR",
    "PA",
    "NI",
    "CR",
    "CU",
    "SV",
    "DO"
  ],
  // # Pacific Islands
  IP: [
    "PG",
    "SB",
    "FJ",
    "VU",
    "NC",
    "NZ",
    "TF",
    "AU"
  ],

  // # South America
  SA: [
    "BO",
    "BR",
    "GY",
    "PY",
    "PE",
    "EC",
    "FK",
    "CO",
    "CL",
    "SR",
    "UY",
    "TT",
    "VE",
    "AR"
  ]
};

// const iso = [
//   { code: "BF", name: "Burkina Faso" },
//   { code: "BI", name: "Burundi" },
//   { code: "BJ", name: "Benin" },
//   { code: "BW", name: "Botswana" },
//   { code: "RW", name: "Rwanda" },
//   { code: "GW", name: "Guinea-Bissau" },
//   { code: "GQ", name: "Equatorial Guinea" },
//   { code: "GA", name: "Gabon" },
//   { code: "GN", name: "Guinea" },
//   { code: "GH", name: "Ghana" },
//   { code: "TN", name: "Tunisia" },
//   { code: "ZM", name: "Zambia" },
//   { code: "EH", name: "Western Sahara" },
//   { code: "EG", name: "Egypt" },
//   { code: "ZA", name: "South Africa" },
//   { code: "ET", name: "Ethiopia" },
//   { code: "SO", name: "Somalia" },
//   { code: "ZW", name: "Zimbabwe" },
//   { code: "ER", name: "Eritrea" },
//   { code: "MG", name: "Madagascar" },
//   { code: "MA", name: "Morocco" },
//   { code: "ML", name: "Mali" },
//   { code: "MW", name: "Malawi" },
//   { code: "MR", name: "Mauritania" },
//   { code: "UG", name: "Uganda" },
//   { code: "XS", name: "Somaliland" },
//   { code: "NA", name: "Namibia" },
//   { code: "NE", name: "Niger" },
//   { code: "NG", name: "Nigeria" },
//   { code: "CI", name: "Ivory Coast" },
//   { code: "CM", name: "Cameroon" },
//   { code: "CG", name: "Congo Republic" },
//   { code: "CF", name: "Central African Republic" },
//   { code: "CD", name: "Democratic Republic of the Congo" },
//   { code: "SZ", name: "Swaziland" },
//   { code: "KE", name: "Kenya" },
//   { code: "SS", name: "South Sudan" },
//   { code: "SN", name: "Senegal" },
//   { code: "SL", name: "Sierra Leone" },
//   { code: "SD", name: "Sudan" },
//   { code: "DJ", name: "Djibouti" },
//   { code: "DZ", name: "Algeria" },
//   { code: "LR", name: "Liberia" },
//   { code: "LS", name: "Lesotho" },
//   { code: "TG", name: "Togo" },
//   { code: "TD", name: "Chad" },
//   { code: "LY", name: "Libya" },
//   { code: "AO", name: "Angola" },
//   { code: "TZ", name: "Tanzania" },
//   { code: "MZ", name: "Mozambique" },
//   { code: "BD", name: "Bangladesh" },
//   { code: "JP", name: "Japan" },
//   { code: "BT", name: "Bhutan" },
//   { code: "TM", name: "Turkmenistan" },
//   { code: "TJ", name: "Tajikistan" },
//   { code: "PK", name: "Pakistan" },
//   { code: "PH", name: "Philippines" },
//   { code: "VN", name: "Vietnam" },
//   { code: "UZ", name: "Uzbekistan" },
//   { code: "MM", name: "Myanmar" },
//   { code: "MN", name: "Mongolia" },
//   { code: "MY", name: "Malaysia" },
//   { code: "NP", name: "Nepal" },
//   { code: "CN", name: "China" },
//   { code: "KG", name: "Kyrgyzstan" },
//   { code: "KH", name: "Cambodia" },
//   { code: "KR", name: "South Korea" },
//   { code: "KP", name: "North Korea" },
//   { code: "KZ", name: "Kazakhstan" },
//   { code: "LA", name: "Laos" },
//   { code: "TW", name: "Taiwan" },
//   { code: "LK", name: "Sri Lanka" },
//   { code: "TH", name: "Thailand" },
//   { code: "AF", name: "Afghanistan" },
//   { code: "IN", name: "India" },
//   { code: "ID", name: "Indonesia" },
//   { code: "OM", name: "Oman" },
//   { code: "JO", name: "Jordan" },
//   { code: "EG", name: "Egypt" },
//   { code: "IL", name: "Israel" },
//   { code: "CY", name: "Cyprus" },
//   { code: "SY", name: "Syria" },
//   { code: "KW", name: "Kuwait" },
//   { code: "SA", name: "Saudi Arabia" },
//   { code: "YE", name: "Yemen" },
//   { code: "LB", name: "Lebanon" },
//   { code: "TR", name: "Turkey" },
//   { code: "AE", name: "United Arab Emirates" },
//   { code: "IQ", name: "Iraq" },
//   { code: "IR", name: "Iran" },
//   { code: "QA", name: "Qatar" },
//   { code: "BE", name: "Belgium" },
//   { code: "BG", name: "Bulgaria" },
//   { code: "BA", name: "Bosnia and Herzegovina" },
//   { code: "BY", name: "Belarus" },
//   { code: "RU", name: "Russia" },
//   { code: "RS", name: "Serbia" },
//   { code: "RO", name: "Romania" },
//   { code: "GR", name: "Greece" },
//   { code: "GB", name: "United Kingdom" },
//   { code: "HR", name: "Croatia" },
//   { code: "HU", name: "Hungary" },
//   { code: "PT", name: "Portugal" },
//   { code: "PL", name: "Poland" },
//   { code: "EE", name: "Estonia" },
//   { code: "IT", name: "Italy" },
//   { code: "ES", name: "Spain" },
//   { code: "ME", name: "Montenegro" },
//   { code: "MD", name: "Moldova" },
//   { code: "MK", name: "North Macedonia" },
//   { code: "FR", name: "France" },
//   { code: "FI", name: "Finland" },
//   { code: "NL", name: "Netherlands" },
//   { code: "NO", name: "Norway" },
//   { code: "XK", name: "Kosovo" },
//   { code: "CH", name: "Switzerland" },
//   { code: "CZ", name: "Czech Republic" },
//   { code: "SK", name: "Slovakia" },
//   { code: "SI", name: "Slovenia" },
//   { code: "SE", name: "Sweden" },
//   { code: "DK", name: "Denmark" },
//   { code: "DE", name: "Germany" },
//   { code: "LV", name: "Latvia" },
//   { code: "LT", name: "Lithuania" },
//   { code: "IS", name: "Iceland" },
//   { code: "AL", name: "Albania" },
//   { code: "AT", name: "Austria" },
//   { code: "IE", name: "Ireland" },
//   { code: "UA", name: "Ukraine" },
//   { code: "GL", name: "Greenland" },
//   { code: "MX", name: "Mexico" },
//   { code: "CA", name: "Canada" },
//   { code: "US", name: "United States" },
//   { code: "JM", name: "Jamaica" },
//   { code: "BS", name: "Bahamas" },
//   { code: "BZ", name: "Belize" },
//   { code: "GT", name: "Guatemala" },
//   { code: "HT", name: "Haiti" },
//   { code: "HN", name: "Honduras" },
//   { code: "PR", name: "Puerto Rico" },
//   { code: "PA", name: "Panama" },
//   { code: "NI", name: "Nicaragua" },
//   { code: "CR", name: "Costa Rica" },
//   { code: "CU", name: "Cuba" },
//   { code: "SV", name: "El Salvador" },
//   { code: "DO", name: "Dominican Republic" },
//   { code: "PG", name: "Papua New Guinea" },
//   { code: "SB", name: "Solomon Islands" },
//   { code: "FJ", name: "Fiji" },
//   { code: "VU", name: "Vanuatu" },
//   { code: "NC", name: "New Caledonia" },
//   { code: "NZ", name: "New Zealand" },
//   { code: "TF", name: "French Southern and Antarctic Lands" },
//   { code: "AU", name: "Australia" },
//   { code: "BO", name: "Bolivia" },
//   { code: "BR", name: "Brazil" },
//   { code: "GY", name: "Guyana" },
//   { code: "PY", name: "Paraguay" },
//   { code: "PE", name: "Peru" },
//   { code: "EC", name: "Ecuador" },
//   { code: "FK", name: "Falkland Islands" },
//   { code: "CO", name: "Colombia" },
//   { code: "CL", name: "Chile" },
//   { code: "SR", name: "Suriname" },
//   { code: "UY", name: "Uruguay" },
//   { code: "TT", name: "Trinidad and Tobago" },
//   { code: "VE", name: "Venezuela" },
//   { code: "AR", name: "Argentina" }
// ]


export default iso;
