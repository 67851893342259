import styled from "styled-components";
import { Input } from "../../HotelCard/forms/form";

export const Title = styled.div`
  margin-left: 4.1rem;
  margin-right: 4.1rem;
  margin-bottom: 3rem;

  span {
    color: #9e9e9e;
    font-weight: 700;
  }
  hr {
    border-color: whitesmoke;
    margin: 0;
  }

  @media screen and (max-width: 1022px){
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;
export const Content = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: 10.4rem;
  margin-right: 10.4rem;
  margin-bottom: 10.4rem;
  gap: 1.5rem;

  label {
    font-weight: 600;
  }

  button {
    width: 12.5rem;
    margin-left: auto;
  }

  .form_div {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .submit{
    margin-left: auto;
  }

  .options_container{
    display: flex;
    gap: 3.5rem;

  }

  @media screen and (max-width: 1022px){
      margin: 0 1rem 1rem 1rem;

      .options_container{
        flex-direction: column;
        align-items: center;
      }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    font-weight: 400;
  }

  input {
    width: 23px; 
    height: 23px; 
    border: 2px solid #d9d9d999;
    appearance: none;
    background-color: #d9d9d999;
    border-radius: 3px;
    cursor: pointer;

    &:checked{
      background-color: rgba(236, 104, 28, 1);
      border: 1px solid whitesmoke;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  span {
    font-size: 12px;
    position: absolute;
    bottom: -40%;
    left: 3%;
    color: red;
  }

  
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  input {
    height: 1.3rem;
  }

  @media screen and (max-width: 1022px){
     flex-direction: column;
  }
`;

export const Select = styled(Input).attrs({ as: "select" })`

&:focus{
    outline: 1px solid rgba(236, 104, 28, 1);
  }
`;