import styled  from "styled-components";

// COLUMN CONTAINER
export const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* margin: 30px 0 30px 0; */
  height: 70vh;
  gap: 30px;
  /* width: 80vw; */
  user-select: none;

  /* scrollbar-width: thin; 
  scrollbar-color: rgba(80, 80, 80, 0.8) rgba(100, 100, 100, 0.3); */

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(80, 80, 80, 0.8);
  }

  @media screen and (max-width: 876px){
    flex-direction: column;
    height: auto;
  }
`;


export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
`