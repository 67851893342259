
const IStar = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: "new 0 0 50 50",
      }}
      viewBox="10 10 30 30"
      {...props}
    >
      <path
        d="M28.3 32.2c-.2 0-.4-.1-.6-.2L25 30.3 22.3 32c-.4.2-.9.2-1.3-.1-.4-.3-.6-.8-.5-1.2l.7-3.2-2.4-2c-.3-.3-.5-.8-.3-1.3s.5-.8 1-.8l3.2-.3 1.2-2.9c.2-.4.6-.7 1.1-.7s.9.3 1.1.7l1.2 2.9 3.2.3c.5.1.9.4 1 .8.2.5 0 1-.3 1.3l-2.4 2 .5 3.2c.2.4.1.9-.2 1.2-.2.2-.5.3-.8.3zm-.1-1h.2v-.1s0-.1-.1-.2l-.6-3.5c0-.2 0-.4.2-.5l2.6-2.1c.1-.1.1-.2 0-.2 0 0-.1-.1-.2-.1l-3.5-.3c-.2 0-.4-.1-.4-.3l-1.3-3.2c-.1-.1-.1-.1-.2-.1s-.1 0-.2.1l-1.3 3.2c-.1.2-.2.3-.4.3l-3.5.3c-.1 0-.1.1-.2.1v.2l2.6 2.1c.2.1.2.3.2.5l-.7 3.5c0 .1.1.2.1.2s.1.1.2 0l3-2c.2-.1.4-.1.6 0l2.9 2.1z"
        style={{
          fill: "#888",
        }}
      />
    </svg>
  )
  export default IStar