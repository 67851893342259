import styled from "styled-components";
import { Column } from "../../../styledComponents/globals/styles";
import { Span } from "./ItineraryStyle";


export const CombinationContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1rem 0 2rem 0;

    @media screen and (max-width: 876px){
        display: grid;
    }
`

export const InputContainer = styled(Column)`
    gap: 0.7rem;
`

export const Label = styled(Span).attrs({as: "label"})``

// export const Span 
