import React from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const CardContainer = styled.div`
  background-color: #f0f0f0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3rem;
  border-radius: 29px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.38);

`;
export const Image = styled.div`
  width: 100%;
  height: 201px;
  
  img {
      border-radius: 13px;
    width: 100%;
    max-height: 100%;
  }
`;
export const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
    p, h3{
        margin: 0;
        text-align: center;
    }

`;
export const Content = styled.div`
  /* background: rgba(255, 255, 255, 0.56); */
  border-radius: 15px;
  padding: 10px 0;
  width: 318px;
  display: flex;
  flex-direction: column;
  align-items: start; 
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    list-style: none;
    padding: 10px 10px;
    margin: 0 7px;
    li{
        font-size: 14px;
      }
  }
  button {
    margin: 0 auto;
    font-weight: bold;
    display: flex;
    padding: 7px 50px;
    border: none;
    background: #ec681c;
    border-radius: 29px;
    color: white;
    cursor: pointer;
  }
`;

const PackageCard = (props) => {

  const navigate = useNavigate();

  const removeEmojisFromString = (str) => {
    const regexEmoji =
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu;
    return str.replace(regexEmoji, "");
  };


  return (
    <CardContainer>
      <Image>
        <img src={props.itemImages[0]} alt="" />
      </Image>
      <Title>
        <h3>{props.destination}</h3>
        <p>{props.subtitle}</p>
      </Title>
      <Content>
        {/* <ul> */}
          {/* {props.items && props.items?.slice(0,5).map((item,index) => <li key={index}>{removeEmojisFromString(item)}</li>)} */}
          {/* {props?.departureOptions[0] && <li>Fecha: {moment(props.departureOptions[0]).format("DD-MM-YYYY")}</li>} */}
          {/* {props.amount && <li>Costo: ${props.amount}</li>} */}
        {/* </ul> */}
        <button onClick={() => navigate(`/package/${props._id}`)}>VER MÁS</button>
      </Content>
    </CardContainer>
  );
};

export default PackageCard;