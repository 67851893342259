import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;


export const getPackagesByMap = async(code) => {

    try{
        const data = await axios.get(`${API_URL}/get_countries?code=${code}`)
        return data.data.filter(item => item?.visibility !== "hidden");
    }
    catch(err){
        return;
    }
}
