import { useEffect } from "react";
import { getAllFlyers } from "../../services/packages.service";
import { useState } from "react";
import { ItineraryBox } from "../../components/Itinerary/styles/ItineraryStyle";
import styled from "styled-components";
import { Column } from "../../styledComponents/globals/styles";
import Section from "./components/Section";

export const FlyersContainer = styled(Column)`
  /* border: 1px solid red; */
  margin: 5rem;
  gap: 3rem;
`;

const Flyers = () => {
  const [flyers, setFlyers] = useState([]);

  useEffect(() => {
    getAllFlyers()
      .then((resp) => {
        setFlyers(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ItineraryBox>
      <FlyersContainer>
        {Object.keys(flyers).map((item,index) => {
          console.log(item);
          return (
            <Section key={index} {...{ travelType: item, flyers: flyers[item] }} />
          );
        })}
      </FlyersContainer>
    </ItineraryBox>
  );
};

export default Flyers;
