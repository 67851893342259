import { Outlet, Navigate } from "react-router-dom";
import {useSelector} from "react-redux"
import { getMongoIdByToken, getStoredToken } from "../../utils/getStoredToken";

export const AuthRoute = ({children, redirectTo = "/"}) => {
    const user = useSelector((state) => state.mainSlice.user)

    if(user.id){
        return <Navigate to={redirectTo} />
    }
    return children ? children : <Outlet />
}

export const ProtectedRoute = ({children, redirectTo = "/"}) => {
    // const isLogged = useSelector((state) => state.mainSlice.isLogged)
    const token = getMongoIdByToken(getStoredToken())

    if(!token){
        return <Navigate to={redirectTo} />
    }
    return children ? children : <Outlet />
}
