import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\d+$/, "Ingrese solo números")
    .min(10, "El número de teléfono debe tener al menos 10 dígitos")
    .max(12, "El número de teléfono debe tener como máximo 12 dígitos")
    .required("El número de teléfono es requerido"),
  email: Yup.string()
    .email("Ingrese un correo electrónico válido")
    .required("El correo electrónico es requerido"),

  rooms: Yup.array().of(
    Yup.object().shape({
      paxes: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("El nombre es requerido"),
          passport: Yup.string().required(
            "El número de pasaporte es requerido"
          ),
          gender: Yup.string().required("Debe seleccionar un género"),
        })
      ),
    })
  ),
});
