import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../../services/auth/user.service";
import jwtDecode from "jwt-decode"

const useProfile = () => {
  const { id } = jwtDecode(JSON.parse(localStorage.getItem("token")));

  const [dataLoaded, setDataLoaded] = useState(false);
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    cuit: "",
    social: "",
    ivaCondition: "",
    address: "",
    email: "",
    promotionEmail: "",
    legacy: "",
    agency: "",
  });

  useEffect(() => {
    getUserById(id)
      .then((resp) => {
        setProfileData(resp);
        setBookings(resp.bookings);
        setDataLoaded(true);
      })
      .catch(() => {
        navigate("/");
      });
  }, [id]);

  return { profileData, id, dataLoaded, bookings };
};

export default useProfile;
