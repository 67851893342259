import { getPackageIdByFlyer } from "../../../services/packages.service";



const useFlyers = () => {

    const handleDownload = (imageUrl) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.target = "_blank";
        link.download = "imagen_descargada"; // Puedes cambiar el nombre del archivo aquí
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleNavigate = (url) => {
        getPackageIdByFlyer(url)
          .then((resp) =>
            window.open(
              `https://www.interlude.com.ar/package/${resp}`,
              "_blank",
              "noopener noreferrer"
            )
          )
          .catch((err) => console.log(err));
      };

    return {handleDownload, handleNavigate};
}
 
export default useFlyers;