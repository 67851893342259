import styled from "styled-components";
import useColumns from "../../hooks/packages/useColumns";
import { usePackages } from "../../hooks/packages/usePackages";
import { Container, MainContent } from "../../styledComponents/packages/mainLayout";

export const Box = styled(Container)`
  padding: 15px;
  border-radius: 18px;
  margin-bottom: 5.8rem;

  @media screen and (max-width: 876px) {
    gap: 30px;
    align-items: center;
  }
`;

const Nationals = () => {
  const { packages } = usePackages("nacionales");
  const options = [
    <option value="REGULARES">Regulares</option>,
    <option value="GRUPALES">Grupales</option>,
  ];

  const { renderColumns, renderRoute } = useColumns({
    packages,
    initialOption: "REGULARES",
    options,
  });

  return (
    <>
      {renderRoute()}
      <MainContent>
      <Box style={{ gap: "2.5rem" }}>
        {renderColumns()}
      </Box>
      </MainContent>
    </>
  );
};

export default Nationals;
