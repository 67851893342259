import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPackages = async (type) => {
  try {
    const data = await axios.get(`${API_URL}/packages?type=${type}`);
    return data.data?.filter((item) => item?.visibility !== "hidden");
  } catch (err) {
    return;
  }
};

export const getPackageById = async (id) => {
  try {
    const data = await axios.get(`${API_URL}/packages?id=${id}`);
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const packagePurchase = async (values) => {
  try {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios.post(`${API_URL}/purchase`, values, {
      headers: headers,
    });
  } catch (err) {
    throw new Error("No se ha podido reservar el paquete");
  }
};

export const getPackageByRegion = async (region) => {
  try {
    const data = await axios.get(`${API_URL}/packages?region=${region}`);
    return data.data?.filter((item) => item?.visibility !== "hidden");
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getAllFlyers = async () => {
  try {
    const data = await axios.get(`${API_URL}/admin/package/flyers`);
    console.log(data.data);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getPackageIdByFlyer = async (url) => {
  try {
    const data = await axios.get(
      `${API_URL}/admin/package/packageByFlyer?url=${url}`
    );
    return data.data._id;
  } catch (err) {
    console.log(err);
  }
};
