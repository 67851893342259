import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchReviews = async () => {
    try {
        let request = await axios.get(`${API_URL}/getFeedback`)
        return request.data
        
    } catch (error) {
        return {Exception: error}
  } 
}