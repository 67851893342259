import React from 'react';
import styled, { keyframes } from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ background }) => (background ? 'grey' : 'rgba(0, 0, 0, 0.6)')};
  /* background-color: grey; */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  ${({ isLoading }) =>
    isLoading &&
    `
    pointer-events: auto;
  `}
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  border-top: 5px solid rgba(236, 104, 28, 1);
  animation: ${spinAnimation} 1s linear infinite;
 
`;

const Loader = ({ isLoading, background }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Overlay background={background} isLoading={isLoading}>
      <Spinner />
    </Overlay>
  );
};

export default Loader;
