export const links = {
    interSale: "https://interlude-assets.s3.us-east-2.amazonaws.com/banner_inter_sale.png",
    logo:"https://interlude-assets.s3.us-east-2.amazonaws.com/logo_grey.png",
    maldivas:"https://interlude-assets.s3.us-east-2.amazonaws.com/maldivas.png",
    crucero:"https://interlude-assets.s3.us-east-2.amazonaws.com/crucero.png",
    egipto:"https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/egipto.jpg",
    disney:"https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/disney.jpg",
    disney0:"https://interlude-assets.s3.us-east-2.amazonaws.com/viajes+15+portada.png",
    banner_1: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/banner_horizontal_1.jpg",
    banner_2: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/banner_horizontal_2.jpg",
    banner_main: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/banner_inicio.jpg",
    banner_mobile: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/banner_inicio_mobil.jpg"
}