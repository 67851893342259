function getLeaderPax(room) {

    let roomParsed = room
    if (!Array.isArray(roomParsed)) {
      roomParsed = [room]
    }
  
    for (const element of roomParsed) {
      if (element.pax) {
        if (Array.isArray(element.pax)) {
          const leader = element.pax.find((pax) => pax.leader === "true");
          if (leader) {
            return leader;
          }
        } else if (typeof element.pax === "object" && element.pax.leader === "true") {
         return element.pax;
        }
      }
    }
    return null;
  }

  export default getLeaderPax