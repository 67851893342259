import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "@mui/material/Modal";
import { getAdvertisement } from "../../services/images.service";
import styled from "styled-components";

const ImageContainer = styled.div`

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 900px;


  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Promotion = () => {
  const [open, setOpen] = useState(false);
  const [showCount, setShowCount] = useState(0);
  const [image, setImage] = useState("");

  useEffect(() => {

    getAdvertisement()
    .then((resp => {
        if(resp){
            setImage(resp)
        }
        else{
            setOpen(false); 
        }
    }))
    .catch(() => setOpen(false))
    console.log(image)

    const storedShowCount = parseInt(localStorage.getItem("showCount")) || 0;
    const storedLastShownTime =
      parseInt(localStorage.getItem("lastShownTime")) || 0;

    const currentTime = new Date().getTime();

    const elapsedTime = currentTime - storedLastShownTime;

    if (elapsedTime >= 6 * 60 * 60 * 1000) {
      setShowCount(0);
      setOpen(true);
      localStorage.setItem("lastShownTime", currentTime.toString());
    } else if (storedShowCount < 5) {
      setShowCount(storedShowCount);
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);

    if (showCount < 5) {
      const newShowCount = showCount + 1;
      setShowCount(newShowCount);
      localStorage.setItem("showCount", newShowCount.toString());
    }
  };

  const openLink = (url) => {
    if(url) window.open(url, "_blank", "noopener noreferrer");
};

  return (
    <div>
      <Modal
      disableAutoFocus={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageContainer
        >
          <IoMdClose
            style={{
              position: "absolute",
              cursor: "pointer",
              right: "1%",
              top: "3%",
            }}
            size={25}
            onClick={handleClose}
          />
          <img
            onClick={() => openLink(image.link)}
            src={image.image}
            alt=""
            style={{ maxWidth: "100%", cursor: "pointer" }}
          />
        </ImageContainer>
      </Modal>
    </div>
  );
};

export default Promotion;
