import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4{
        color: grey;
        max-width: 100%;
        /* margin-left: 3rem; */
    }
`;


export const ResultsContainer = styled.div`
  padding: 5px 15px;
  border-radius: 30px;
  max-width: 100%;
  background-color: white;
`;
export const Redirect = styled.span`
  margin-top: 15px;
  font-weight: 600;
`

const Results = ({ children, totalSearch }) => {

  const hState = useSelector((state) => state.mainSlice.hotels)
  
  return (
    <>
    <Container id="results">
      <h4>Resultados de la búsqueda ({totalSearch}) 
      {hState.totalFiltered >= 1 && (hState.totalFiltered !== hState.total) && <> filtrados ({hState.totalFiltered})</>}
      </h4>
      <ResultsContainer>
        {/* <h5>No se encontraron resultados :( </h5>
        <p>
          Te puede interesar (variable de fecha, lugar, el resultado más cercano
          a la búsqueda realizada)
        </p> */}
        {children}
      </ResultsContainer>
        <Redirect><Link to={"/hotels"}>Ver todos</Link></Redirect>
    </Container>
    </>
  );
};

export default Results;
