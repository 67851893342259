import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../redux/main.slice";

export const usePackages = (type) => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.mainSlice.packages);
  const user = useSelector((state) => state.mainSlice.user)

  useEffect(() => {
      dispatch(getPackages(type));
  }, [dispatch, user]);

  return { packages };
};
