import { Link } from "react-router-dom";
import { RouteLink } from "../../../../styledComponents/globals/styles";

const RouteBadge = ({packageFound}) => {
    return ( <RouteLink>
        <Link to={`/${packageFound.travelType.toLowerCase()}`}>
          {packageFound.travelType.toLowerCase()}
        </Link>
        {" / "}
        <Link
          to={`/${packageFound.travelType.toLowerCase()}/${packageFound.type.toLowerCase()}`}
        >
          {packageFound.type.toLowerCase()}
        </Link>
        {" / "}{" "}
        {!packageFound.region
          ? packageFound.destination
          : packageFound.region + " / " + packageFound.destination}
      </RouteLink> );
}
 
export default RouteBadge;