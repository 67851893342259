import React from "react";
import styled from "styled-components";
import { Col1, Title } from "./MobilePreHotelCard";
import { ShowMoreButton } from "./PreHotelCardStyle";
import TableCard from "./TableCard";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 30px;
`;
const Submit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    margin: 0 auto;
    font-weight: 600;
  }
`;
export const Col2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;
const Col3 = styled.div`
  grid-column: 1 / span 2;
`;

const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 17px;
`;

const MobileHotelCard = ({hotel, handleSubmitFromParent}) => {
  return (
    <>
      <Container>
        <Col1>
          <Image src={hotel.image ? hotel.image[0]: "https://photo.netstorming.net/hotel/34658/wide/1.jpg"} />
        </Col1>
        <Col2>
          <div>
            <Title>{hotel.name}</Title>
            <span>{hotel.address}</span>
          </div>
          <Submit>
            <span>Total: USD${hotel.totalPrice}</span>
            <ShowMoreButton onClick={handleSubmitFromParent}>RESERVAR</ShowMoreButton>
          </Submit>
        </Col2>
        <Col3>
        <h4>Detalle:</h4>
          <TableCard datos={hotel}/>
        </Col3>
      </Container>
    </>
  );
};

export default MobileHotelCard;
