import styled from "styled-components";
import MapView from "../MapView/MapView";

const Grid = styled.div`
  background-color: white;
  display: grid;
  /* margin: 0 35px 0 35px; */
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "details observations accessibility"
    "policies policies policies";
  padding: 0 35px 35px 35px;
  margin: 0 var(--margin-large) 0 var(--margin-large);
  /* border-radius: 0 0 17px 17px; */

  @media screen and (max-width: 1280px){
    margin: 0 1rem 3rem 1rem;
  }

  @media screen and (max-width: 878px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
  }

  div:nth-child(1) {
    /* Estilos para el segundo div */
    grid-area: details;
  }
  div:nth-child(2) {
    grid-area: observations;
  }
  div:nth-child(3) {
    grid-area: accessibility;
  }
  div:nth-child(4) {
    grid-area: policies;
  }
`;

const List = styled.ul`
  list-style: circle;
  padding: 0 1rem;
`;

const RoomDetails = ({ availableDetails, hotelsFacilities, hotel }) => {
  return (
    <>
      <Grid>
        <div>
          <h4>Detalle del hotel</h4>
          <List>
            {availableDetails &&
              availableDetails.map((i) => {
                return <li>{i}</li>;
              })}
          </List>
        </div>
        <div>
          <h4>Observaciones</h4>
          <List>
            {hotel.agreement?.remarks?.map((remark, index) => (
              <li key={index}>{remark}</li>
            ))}
          </List>
        </div>
        <div>
          <h4>Accesibilidad</h4>
          <div>
            {hotelsFacilities.coords.latitude &&
              hotelsFacilities.coords.longitude && (
                <MapView
                  name={hotel.name}
                  width="100%"
                  hotels={hotelsFacilities}
                  coords={hotelsFacilities?.coords}
                />
              )}
          </div>
        </div>
        {hotel.agreement?.deadline.remarks && 
        (<div>
          <h4>Politicas de cancelación</h4>
          <List>
            {hotel.agreement?.deadline?.remarks?.map((remark, index) => (
              <li key={index}>{remark?.text}</li>
            ))}
          </List>
        </div>)}
      </Grid>
    </>
  );
};

export default RoomDetails;
