import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: var(--margin-large);
  margin-right: var(--margin-large);
  padding: ${({ isScrolled }) =>
    isScrolled ? "5px 0 5px 0" : "15px 0 15px 0"};
  transition: all 0.3s ease-in-out;
  animation: ${slideIn} 0.3s ease;

  .hidden_span{
    display: none;
  }

  @media screen and (max-width: 875px) {
    flex-direction: row-reverse;
    /* justify-content: space-between; */
    align-items: center;
    padding: 10px 15px;
    margin: 0;

    .hidden_span{
        width: 50px;
        display: block;
        visibility: hidden;
    }
  }
`;

export const Nav = styled.nav`
  background: #D9D9D9;

  position: ${({ isScrolled }) => (isScrolled ? "sticky" : "relative")};
  top: 0;
  left: 0;
  width: 100%;

  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "0px 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  z-index: 10;
  font-family: Montserrat;
  color: #595959;
  font-weight: 600;
`;

export const Logo = styled.div`
  cursor: pointer;

  @media screen and (max-width: 875px) {
    margin: auto;
  }
`;

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  gap: 3rem;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1280px) {
    gap: 1rem;
  }

  @media screen and (max-width: 900px) {
    gap: 0.5rem;
  }
  @media screen and (max-width: 875px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  cursor: pointer;
  user-select: none;
  font-size: 0.75rem;
  font-weight: 600;
  /* position: relative; */
  a {
    text-decoration: none;
    color: rgb(89, 89, 89);
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    font-size: 10px;
  }
  /* &:after {
    display: block;
    content: "";
    border-bottom: solid 3px var(--o-500);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
  &.fromRight:after {
    transform-origin: 100% 50%;
  }
  &.fromLeft:after {
    transform-origin: 0% 50%;
  } */
`;

export const BtnMenu = styled.div`
  display: none;
  user-select: none;
  
  /* padding: 10px; */
  /* font-size: 1.5rem; */
  cursor: pointer;

  @media screen and (max-width: 875px) {
    display: block;
  }
`;

export const DropMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #d9d9d9;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 10;
  right: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  animation: ${slideIn} 0.3s ease;

  @media screen and (min-width: 875px) {
    display: none;
  }
`;

export const DropMenuItem = styled.li`
  cursor: pointer;
  user-select: none;
  display: flex;
  a{
    color: #595959;
    padding: 10px;
    text-decoration: none;
    width: 100%;
  }
  &:hover {
    background-color: #e5e5e5;
  }
`;

export const ProfileMenu = styled.div``;

export const MenuItems = styled.div`
  display: none;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  background-color: #ebebeb;
  border-radius: 5px;
  /* margin-top: 10px; */
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s;

  ${ProfileMenu}:hover & {
    display: flex;

    opacity: 1;
    transform: translateY(0);
  }
  a{
    display: flex;
  }
 
`;

export const MenuItem = styled.span`
  position: relative;
  padding: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

  &:last-child::before {
    content: none;
  }
  a {
    text-decoration: none;
  }

  &:hover {
    background-color: #e5e5e5;
  }
`;

export const NavMenu = styled.div`
  display: none;
  flex-direction: column;
  gap: 12px;
  border-radius: 5px;
  position: absolute;
  background-color: #ebebeb;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  animation: ${slideIn} 0.3s ease;

  li:hover{
      background-color: #d1d1d1;
  }

  a{
    width: 100%;
    display: flex;
  }

  ${NavItem}:hover & {
    display: flex;
  }

  ${NavItem} {
    position: relative;
    padding: 10px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -6px;
      width: 100%;
      height: 1px;
      background-color: #ccc;
    }

    &:last-child::before {
      content: none;
    }
    .groups {
      display: flex;
      justify-content: space-between;
    }
    .groups_item {
      display: none;
      position: absolute;
      flex-direction: column;
      gap: 20px;
      padding: 15px;
      width: 100%;
      background-color: #ebebeb;
      border-radius: 5px;
      top: -25%;
      right: -118%;

      a::before {
        content: "";
        position: absolute;
        height: 1px;
        bottom: -10px;
        width: 118%;
        left: -10%;
        background-color: #ccc;
        z-index: 300;
      }
      a:last-child::before {
        content: none;
      }
    }

    &:last-child:hover {
      div {
        display: flex;
      }
    }
  }
`;