import { useNavigate } from "react-router-dom";
import { usePackages } from "../../../hooks/packages/usePackages";
import {
  RowContainer,
  ImageContainer,
  Content,
  ButtonContainer,
} from "./RowCardStyle";
import moment from "moment";
import { Span } from "../../Itinerary/styles/ItineraryStyle";
import { months } from "../../Itinerary/utils/months";
import { useEffect, useState } from "react";

export const RowContentCard = ({ travel }) => {
  const navigate = useNavigate();

  return (
    <RowContainer onClick={() => navigate(`/package/${travel._id}`)}>
      <ImageContainer>
        <img src={travel?.itemImages[0] ? travel.itemImages[0]  : "https://photo.netstorming.net/hotel/34658/wide/1.jpg"} alt="" />
      </ImageContainer>
      <Content>
        <div>
          <h4>{travel.destination}</h4>
          <h5>{travel.subtitle}</h5>
        </div>
        <div>
        {travel?.departureOptions && travel.departureOptions.length >= 1 && <Span>Opciones de salida: </Span>}
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            {travel?.departureOptions?.map((item, index) => {
              let date = moment(item);
              let day = date.format("DD");
              let month = parseInt(date.format("MM"), 10);
              let year = date.format("YYYY");
              return (
                <li key={index}>{`${day} de ${months[month - 1]} ${year}`}</li>
              );
            })}
          </ul>
          {/* {travel.amount && <Span>Costo: ${travel.amount}</Span>} */}
        </div>
      </Content>
      <ButtonContainer>
        <button onClick={() => navigate(`/package/${travel._id}`)}>
          VER MÁS
        </button>
      </ButtonContainer>
    </RowContainer>
  );
};

export const RowCard = ({ label, travelType, isGroup, region }) => {
  const { packages } = usePackages();
  const [packagesFiltered, setPackages] = useState([]);

    useEffect(() => {
      setPackages(packages
        .filter((item) => item.travelType === travelType.toLowerCase())
        .filter((item) =>
          isGroup ? item.region === region : item.type === label.toLowerCase()
        ))
    }, [isGroup, label, travelType, packages, region])

  return (
    <>
      {
        packagesFiltered.length ?
        packagesFiltered?.map((travel, index) => (
          <RowContentCard key={index} travel={travel} onRoute={true} />
        ))
       : 
        <div style={{ width: "100%"}}>
          <h4>¡Muy pronto nuevos paquetes!</h4>
          <p>
            Escribinos para cotizar el destino que buscás y vivir el viaje de tu
            vida.
          </p>
        </div>
      }
    </>
  );
};
