import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(217, 217, 217, 0.6);
    border: 1px solid whitesmoke;
  }
`;
export const SinglePaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;