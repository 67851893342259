import styled from "styled-components";
import { Column } from "../../styledComponents/globals/styles";

export const SectionContainer = styled(Column)`
  gap: 1.5rem;

  h3,
  h4 {
    margin: 0;
  }
`;

export const Grid = styled.div`
  margin: 1rem 0;
  overflow: hidden;
  height: ${({ expanded }) => (expanded ? 'auto' : '0')};
  opacity: ${({ expanded }) => (expanded ? '1' : '0')}; 
  transition: height 0.3s ease, opacity 0.3s ease;

  display: grid;
  place-items: center;
  grid-template-columns: ${({ expanded }) => (expanded ? '1fr 1fr 1fr 1fr' : '0')};
  gap: 1rem;
  row-gap: 1rem;

  @media screen and (max-width: 1300px) {
    grid-template-columns: ${({ expanded }) => (expanded ? '1fr 1fr 1fr' : '0')};
  }

  @media screen and (max-width: 876px) {
    grid-template-columns: ${({ expanded }) => (expanded ? '1fr 1fr' : '0')};
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: ${({ expanded }) => (expanded ? '1fr' : '0')};
  }
`;

export const ImageContainer = styled.div`
  max-width: 200px;

  position: relative;
  overflow: hidden;

  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    transition: filter 0.3s ease;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }

  .overlay:hover {
    opacity: 1;
  }

  .buttons {
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;

    span {
      background-color: white;
      padding: 0.4rem;
      border-radius: 18px;
      cursor: pointer;
    }
  }

  .overlay:hover .buttons {
    opacity: 1;
  }
`;