/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import styled from "styled-components";

const Table = styled.table`
  display: grid;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: center;
  color: rgba(86, 86, 86, 1);
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`;

const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 1fr;
`;

const TableCell = styled.td`
  /* text-align: center; */
  /* padding: 8px; */
  font-size: 14px;
  background-color: ${(props) => (props.active ? "#f0f0f0" : "transparent")};
  height: ${(props) => (props.showFullText ? "auto" : "25px")};
  overflow: hidden;
`;

const ReadMoreButton = styled.button`
  display: ${(props) => (props.showFullText ? "none" : "block")};
  background: none;
  border: none;
  cursor: pointer;
  color: blue;
  margin-top: 5px;
`;

const TableCard = ({ datos }) => {

    let parseRoomType = (type) => {
      if(type === "sgl") return "Simple"
      if(type === "dbl") return "Doble"
      if(type === "twn") return "Twin"
    }
    
    

  return (
    <Table>
      <thead>
       <tr>
        <TableHeader>Habitaciones</TableHeader>
      </tr>
    </thead>
    <tbody>
      {
          datos.rooms.map((element, key) => (
            <tr key={key}>
              <TableCell>
              {parseRoomType(element.type)}: 
                USD ${element.room_prices.totalNetPrice}
              </TableCell>
            </tr>
          ))
        }
        </tbody>
    </Table>
  );
};

export default TableCard;