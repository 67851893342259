import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 18.75rem;
  justify-content: space-between;
  background-color: rgba(240, 240, 240, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.38);
  padding: 20px 25px 30px 25px;
  width: 300px;
  /* height: 200px; */
  border-radius: 30px;

  span {
    font-weight: 600;
  }
  p {
    text-align: start;
    margin-top: 0;
  }


  @media screen and (max-width: 505px) {
      img{
        display: none;
      }
  }
`;