import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


export const logIn = async (user) => {
  try {
    const data = await axios.post(
      `${API_URL}/user/signin`,
      user
    );
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const register = async (user) => {
  try {
    const data = await axios.post(`${API_URL}/user`, user);
    return data;
  } catch (err) {
    console.log(err.response.data.error);
    throw err.response.data.error;
  }
};

export const verify = async () => {


  const token = localStorage.getItem("token");
  const cookie = document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith("accessToken="));

  const tokenCookie = cookie?.substring("accessToken=".length);
  const headers = {
    Authorization: `Bearer ${tokenCookie ? JSON.stringify(tokenCookie) : token}`,
  };

  try {
    const response = await fetch(`${API_URL}/user/verify`, {
      headers,
    });
    const data = await response.json();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return data
  } catch (error) {
    localStorage.removeItem("token")
  }
};

