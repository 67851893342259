import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "@mui/material";
import Slider from "react-slick";

const CarouselModal = ({ carouselModal, handleModal, images }) => {

  const settings = {
    // centerMode:false,     
    draggable:true,    
    arrows:true,    
    autoplay:true,
    autoplaySpeed:1500,     
    slidesToShow:3,     
    slidesToScroll:1,   
    dots:true,
  };

  return (
    <Modal
      open={carouselModal}
      onClose={handleModal}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection:"column"
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        {/* <div style={{display:"flex", flexDirection:"column"}}> */}
        <div >
        {
          images.length > 1 ? <Slider {...settings}>
          {
              images?.map((item, index) => <div className="single-item" key={index} style={{width:"100%", height:"400px"}}>
              <img style={{maxWidth:"435px", height:"100%"}} src={item} alt={index} />
            </div>)
          }
      </Slider> : <img style={{maxWidth:"600px", height:"100%"}} src={images[0]} alt={'card'} />
        }
        </div>
        {/* <hr /> */}
            {/* </div> */}
    </Modal>
  );
};

export default CarouselModal;
