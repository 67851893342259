

const Insta = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      baseProfile="tiny"
      overflow="visible"
      viewBox="0 -5 50 50"
      {...props}
    >
      <g fill="#888">
        <path d="M34.5 10.7H18.1c-2.8 0-5.1 2.3-5.1 5.1v16.4c0 2.8 2.3 5.1 5.1 5.1h16.4c2.8 0 5.1-2.3 5.1-5.1V15.9c0-2.8-2.3-5.2-5.1-5.2zm3.9 21.6c0 2.2-1.8 4-4 4H18.1c-2.2 0-4-1.8-4-4V15.9c0-2.2 1.8-4 4-4h16.4c2.2 0 4 1.8 4 4v16.4z" />
        <path d="M26.3 17.2c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9zm0 12.6c-3.1 0-5.7-2.6-5.7-5.7s2.5-5.7 5.7-5.7S32 21 32 24.1s-2.6 5.7-5.7 5.7z" />
        <circle cx={33.1} cy={17.3} r={1.2} />
      </g>
    </svg>
  )
  export default Insta;