import React, { useState, useEffect } from "react";
import ResponsiveRoom from "./ResponsiveRoom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone"
import { evaluateBooking, getDetails } from "../../services/hotels.service";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/main.slice";
import { roomCounts } from "./utils";
import Slider from 'react-slick'
import { commonAlert } from "../../utils/alerts";

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 50px;
  background-color: white;
  row-gap: 40px;
  padding: 30px 0;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 878px) {
    font-size: 14px;
  }
`;

const TableHeader = styled.div`
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid whitesmoke;
  padding-bottom: 30px;
  gap: 8px;

  button {
    background-color: rgba(236, 104, 28, 1);
    color: white;
    padding: 5px 40px;
    border-radius: 15px;
    font-weight: 600;
    border: none;
  }
`;

const RoomCard = ({ search, agreement, name, image, stars, address, code, basis, meal, deadline, hotelsFacilities }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const hState = useSelector((state) => state.mainSlice.hotels);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleWindowResize = () => {
    setIsDesktop(window.innerWidth >= 878);
  };

  const dateConverter = (date) => {
    if(!date) return ""
    const dateCET = moment.tz(date, 'Europe/Rome');
  const dateLocal = dateCET.local();

  const formattedDate = dateLocal.format('DD-MM-YYYY HH:mm');
  return formattedDate;
  };
  

  // if (!Array.isArray(agreement)) {
  //   agreement = [agreement];
  // }

  const handleNavigate = async (room) => {
    try {
      const { dbl, twn, sgl, trp } = roomCounts(room.room);
      dispatch(setLoading(true));
      const data = await evaluateBooking({
        searchNumber: search,
        hotelCode: code,
        agreementCode: room.id,
        price: room.rawHotelPrice,
        checkIn: hState.checkin,
        checkOut: hState.checkout,
        dbl,
        sgl,
        trp,
        twn,
      });
      dispatch(setLoading(false));
      navigate(`/agreement/${room.id}`, {
        state: {
          rooms: room.room,
          evaluatedSearch: data.search,
          agreement: room,
          code,
          name,
          image,
          stars,
          address,
          total: room.total,
          totalPrice: room.totalRoomsPrice,
          hotelsFacilities,
          
        },
      });
    } catch (err) {
      console.log(err, "ERRORRRR")
      dispatch(setLoading(false));
      commonAlert("Búsqueda sin disponibilidad", "Lo sentimos, esta opción no se encuentra actualmente disponible. Por favor intente seleccionando otra opción.", "error")
      return;
    }
  };

  useEffect(() => {
    handleWindowResize(); // Verificar el tamaño de la pantalla inicialmente

    // Agregar el event listener para el cambio de tamaño de la ventana
    window.addEventListener("resize", handleWindowResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {isDesktop ? (
        <Table>
          <TableHeader>Tipo de habitación</TableHeader>
          <TableHeader>Comidas</TableHeader>
          <TableHeader>Plazo de cancelación</TableHeader>
          <TableHeader>Total</TableHeader>
          <TableHeader>
            <span></span>
          </TableHeader>
          {agreement?.map((room, i) => {

            let roomtype = room.room_type.replace(
              /Non Refundable|Refundable/gi,
              ""
            );
            let refundable = room.room_type.split(" ");
            let isRefundable = refundable.filter(
              (part) =>
                part.toLowerCase() === "refundable" ||
                part.toLowerCase() === "non"
            );
            isRefundable =
              isRefundable.join(" ") === "Non Refundable"
                ? "NO REEMBOLSABLE"
                : `Cancelación hasta ${dateConverter(room?.deadline?.date)}`;
              if(room.deadline){
                return (
                  <React.Fragment key={i}>
                    <TableItem>
                      <span>{roomtype}</span>
                    </TableItem>
                    <TableItem>
                      <span>
                        {room.room_basis}
                      </span>
                      <span>{room.meal_basis}</span>
                    </TableItem>
                    <TableItem>
                      <span style={{color: isRefundable === "NO REEMBOLSABLE" ? "red" : "green", fontWeight: "bold"}}>{isRefundable}</span>
                    </TableItem>
                    <TableItem>
                      <span>USD${room.totalRoomsPrice}</span>
                    </TableItem>
                    <TableItem>
                      <button onClick={() => handleNavigate(room)}>
                        Seleccionar
                      </button>
                    </TableItem>
                  </React.Fragment>
                );
              }
          })}
        </Table>
      ) : (
        <ResponsiveRoom agreement={agreement} deadline={deadline} basis={basis} rooms={agreement} navigate={handleNavigate} />
      )}
    </>
  );
};

export default RoomCard;
