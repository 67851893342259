import React, { useRef, useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
// import TableCard from "./TableCard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PaxForm from "./forms/PaxForm";
import RoomDetails from "./Details";
import MobileHotelCard from "./MobileHotelCard";
import { useCheckWords } from "./hooks/useCheckWords";
import { useCheckClass } from "./hooks/useCheckClass";
import ILocation from "../../assets/icons/hotel/ILocation";
import IStar from "../../assets/icons/hotel/IStar";
import moment from "moment"
import Slider from "react-slick";
import {CheckClassType} from "./PreHotelCardStyle"
import TableCard from "./TableCard";


export const Container = styled.div`
  background-color: white;
  border-radius: 17px 17px 0 0;
  padding: 25px;
  margin: 5.8rem var(--margin-large) 0 var(--margin-large);
  display: grid;
  grid-template-columns: 300px 1fr 1fr;
  column-gap: 15px;


  @media screen and (max-width: 1280px){
    margin: 3rem 1rem 0 1rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  img {
    width: 100%;
    max-width: 95%;
    height: 252px;
    border-radius: 17px;
  }
  span {
    margin: 0 50% 0 0;
    padding-top: 10px;
  }
`;
const Content = styled.div``;
const Title = styled.div``;
const Head = styled.div`
  h3{
    margin-bottom: 0;
  }
`;
const Details = styled.div`
  button {
    width: 100%;
    padding: 8px 0;
    border: none;
    border-radius: 10px;
    background-color: rgba(236, 104, 28, 1);
    font-weight: 600;
    color: white;
  }
  h3 {
    display: flex;
    justify-content: end;
  }
`;
export const Check = styled.div`
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 7px 0;
    }
    a {
      background-color: black;
      border-radius: 8px;
      color: white;
      padding: 4px 8px 4px 8px;
      text-align:center;
      font-weight: bolder;
    }
  }
`;
export const PrevArrowButton = styled.button`
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: -40px;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  z-index: 200;
` 
export const NextArrowButton = styled.button`
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: -40px;
  width: 0;
  height: 0;
  border-right: 0 solid transparent;
  border-left: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  z-index: 200;
` 

export const Background = styled.div`
  background-color: whitesmoke;
  padding: 20px;
  /* margin: 20px 0; */

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

const HotelCard = () => {
  const [localSearch, setLocalSearch] = useState(
    JSON.parse(localStorage.getItem("search"))
  );
  const [isOpen, setIsOpen] = useState(false);
  const hotel = useLocation().state;
  const [isDesktop, setIsDesktop] = useState(false);
  const { words } = useCheckWords(hotel);
  const [open, setOpen] = useState(false)
  const formRef = useRef();
  const scrollRef = useRef(null)

  let quantityStars = []

  const handleWindowResize = () => {
    setIsDesktop(window.innerWidth >= 878);
  };
  let { checkedClass } = useCheckClass(hotel.hotelsFacilities?.classification);
  const handleSubmitFromParent = () => {
    // const scrollDiv = document.getElementById('scrollDiv');
    if (!isOpen) {
      setIsOpen(true);

      setTimeout(() =>{
        if (scrollRef.current) {
          scrollRef?.current.lastElementChild.scrollIntoView({ behavior: 'smooth'});
        }
      }, 400)
      return;
    }
  
    formRef.current.click();
  }
 
  for (let i = 0; i < hotel.stars; i++) {
    quantityStars.push(<IStar height="30px" width="30px"/>);
  }
  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    handleWindowResize(); 

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const PrevArrow = ({onClick}) => (
    <PrevArrowButton onClick={onClick} ></PrevArrowButton>
  )
  const NextArrow = ({onClick}) => (
    <NextArrowButton onClick={onClick}></NextArrowButton>
  )

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dragabble: false,
  };



  return (
    <>
    <Background style={{paddingBottom: "12rem"}}>
      {isDesktop ? (
        <Container>
            <Slider {...settings} >
              {!hotel.image ? (<ImageContainer> <img src="https://photo.netstorming.net/hotel/34658/wide/1.jpg" alt="" /> </ImageContainer>): 
                  hotel.image?.map((element, key) => {
                    return (
                      <ImageContainer onClick={setOpen} key={key} >
                        <img src={element} alt='err' />
                      </ImageContainer>
                    )
                  }) 
                }
            </Slider>
          <Content>
            <Head>
              
              <Title>
                <h3>{hotel.name}</h3>
                <div style={{display:"flex", gap:"5px"}}>
                  <span>{quantityStars}</span>
                  {hotel.hotelsFacilities.classification !== undefined ? (<CheckClassType><a>{checkedClass}</a></CheckClassType> ) : ""}
                  
                </div>
              </Title>
              <span style={{display: "flex", alignItems: "center"}}><i><ILocation width={"30px"} height={"30px"} /></i>{hotel.address}</span>
            </Head>
            <Check>
              <ul>
                <li>{moment(localSearch.checkIn).format("DD/MM/YYYY")}</li>
                <li>{moment(localSearch.checkOut).format("DD/MM/YYYY")}</li>
                <li>Cantidad de noches: {localSearch.nights}</li>
                <li>Cantidad de personas: {localSearch.adults}</li>
                <li>Tipo y cantidad de habitaciones:</li>
                {localSearch.sgl ? <li>{localSearch.sgl}x Simple</li> : null}
                {localSearch.dbl ? <li>{localSearch.dbl}x Doble</li> : null}
                {localSearch.twin ? <li>{localSearch.dbl}x Doble</li> : null}
              </ul>
            </Check>
          </Content>
          <Details>
            <h4>Detalle</h4>
            <TableCard datos={hotel} />
            
            <h3>Total USD${hotel.totalPrice}</h3>
            <button onClick={handleSubmitFromParent} type="button" >RESERVAR</button>
          </Details>
        </Container>
      ) : (
        <MobileHotelCard handleSubmitFromParent={handleSubmitFromParent} hotel={hotel} />
        )}
      <RoomDetails hotel={hotel} hotelsFacilities={hotel?.hotelsFacilities} availableDetails={words} />
      {isOpen && (
        <PaxForm
          hotel={hotel}
          formRef={formRef}
          scrollRef={scrollRef}
          evaluatedSearch={hotel.evaluatedSearch}
          code={hotel.code}
          />
          )}
    </Background>
    <Modal open={open}
      onClose={handleOpen}
      style={{ display: "flex", alignItems: "center", justifyContent: "center"}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
        <div>

      <Slider slidesToScroll={3} slidesToShow={3} autoplay={true} centerMode={true} infinite={true} prevArrow={(<PrevArrow/>)} nextArrow={(<NextArrow/>)} arrows={true} >
              {!hotel.image ? '': 
                  hotel.image.map((element, key) => {
                    return (
                      <ImageContainer key={key} >
                        <img style={{width: "100%"}} src={element} alt='err' />
                      </ImageContainer>
                    )
                  }) 
                }
            </Slider>
                </div>
    </Modal>
          </>
  );
};

export default HotelCard;
