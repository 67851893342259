import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper"
import "./SwiperCarousel.css";
import PackageCard from "../InterCard/InterCard";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;


  h2,
  p {
    margin: 0;
  }
  @media screen and (max-width: 765px) {
    margin-top: 2rem;
  }
`;

const Box = styled.div`
  padding: 0;
  margin-bottom: 7rem;
  /* margin-left: var(--margin-large);
  margin-right: var(--margin-large); */

  @media screen and (max-width: 875px) {
    margin: 0;
  }
`

const SwiperCarousel = ({ title, subtitle, cards }) => {

  const navigate = useNavigate();

  function calculateSpaceBetween() {
    const cardWidth = 220; // Ancho estimado de una tarjeta en píxeles
    const desiredGap = 44; // Espacio deseado entre las tarjetas en píxeles
    return desiredGap - (cardWidth % desiredGap);
  }

  const breakpoints = {
    // Cuando el ancho de la ventana sea igual o mayor a 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 3,
      
    },
  };

  return (
    <Box>
      <Description>
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </Description>

      <Swiper
        allowTouchMove={true}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={false}
        centerInsufficientSlides={true}
        // spaceBetween={calculateSpaceBetween()}
        initialSlide={1}
        slidesPerView={3}
        loop={true}
        breakpoints={breakpoints}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        {cards?.map((e, i) => {
          return <SwiperSlide style={{position: "relative", zIndex: "300"}} key={i}>{e}</SwiperSlide>;
        })}

      <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <span></span>
          </div>
          <div className="swiper-button-next slider-arrow">
            <span></span>
          </div>
        </div>
      </Swiper>
    </Box>
  );
};

export default SwiperCarousel;
