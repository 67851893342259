import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  /* border: 3px solid black; */
  border-radius: 40px !important;
`;

export const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: rgba(218, 218, 218, 1);
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;

  background-color: ${(props) => {
    if (props.status === "cnf") return "rgba(51, 113, 13, 0.1)";
    if (props.status === "pnd") return "rgba(51, 113, 13, 0.1)";
    if (props.status === "rej") return "#f5c6cb";
  }};

  color: ${(props) => {
    if (props.status === "cnf") return "rgba(51, 113, 13, 1)";
    if (props.status === "pnd") return "#c8a700";
    if (props.status === "rej") return "#991717";
  }};
`;
export const Badge = styled.div`
  display: flex;
  align-items: center;
  color: #949494;
  margin: 20px 10px 10px 13px;
  font-weight: 600;

  i{
    cursor: pointer;
  }
`;
export const BlockSpan = styled.span`
  display: block;
`;