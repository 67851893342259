import React from "react";
import { useSelector } from "react-redux";
import PreHotelCard from "../HotelCard/PreHotelCard";
import Results from "../SearchSection/Results";
import styled from "styled-components";

export const Container = styled.div`

  margin-right: var(--margin-large);
  margin-left: var(--margin-large);


  @media screen and (max-width: 1024px){
    margin-left: 10px;
    margin-right: 10px;
  }
`

const HotelsFound = () => {
  let hState = useSelector((state) => state.mainSlice.hotels);

  return (
    <Container>
      {hState.hotels && (
        <Results totalSearch={hState.total}>
          {hState.hotels?.slice(0, 5).map((e, i) => {
            // let agreementArr = e.agreements;
            // console.log(e)
            // if (!Array.isArray(agreementArr)) {
            //   agreementArr = [e.agreement];
            // }
            // let hotelImage = e.innerPics[0];
            // // if (!Array.isArray(hotelImage)) {
            // //   hotelImage = [e?.hotelsFacilities.innerPics];
            // // }
            let total = parseInt(e.agreements[0]?.totalRoomsPrice);
            // for (let i = 0; i < agreementArr.length; i++) {
            //   if (
            //     parseInt(agreementArr[i].totalRoomsPrice) < parseInt(total)
            //   ) {
            //     total = agreementArr[i].totalRoomsPrice;
            //   }
            // }
            return (
              <React.Fragment key={i}>
                <PreHotelCard
                  search={e?.search}
                  agreement={e.agreements}
                  code={e.code}
                  name={e.name}
                  address={e.address}
                  stars={e.stars}
                  checkIn={hState?.checkin}
                  checkOut={hState?.checkout}
                  hotel_image={e.innerPics ? e.innerPics[0] : null}
                  totalPrice={total}
                  pictures={e.innerPics}
                  hotelsFacilities={e.hotelFacilities}
                />
              </React.Fragment>
            );
          })}
        </Results>
      )}
    </Container>
  );
};

export default HotelsFound;
