const IMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    baseProfile="tiny"
    overflow="visible"
    viewBox="0 0 50 50"
    {...props}
  >
    <g fill="#888">
      <path d="M14.3 17.5h21.3c.4 0 .6-.3.6-.6s-.3-.6-.6-.6H14.3c-.4 0-.6.3-.6.6s.3.6.6.6zM35.7 24.1H14.3c-.4 0-.6.3-.6.6s.3.6.6.6h21.3c.4 0 .6-.3.6-.6.1-.3-.2-.6-.5-.6zM35.7 31.9H14.3c-.4 0-.6.3-.6.6s.3.6.6.6h21.3c.4 0 .6-.3.6-.6s-.2-.6-.5-.6z" />
    </g>
  </svg>
)
export default IMenu