import styled, { keyframes } from "styled-components";

export const CardContainer = styled.div`
  display: grid;
  gap: 15px;
  margin: 15px 0;
  padding: 15px 20px;
  border-radius: 30px;
  background-color: white;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-areas:
    "image info submit"
    "link . ."
    "options options options"
    ;
  small {
    grid-area: link;
    margin-left: 10px;
    user-select: none;
  }

  @media screen and (max-width: 685px) {
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "image image"
      "info info"
      "submit submit"
      "link link"
      "options options"
      ;
  }
`;
export const Image = styled.div`
  grid-area: image;

  img {
    border-radius: 15px;
    width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 530px) {
  }
`;
export const Info = styled.div`
  grid-area: info;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 3px 0;
    }
  }
`;
export const Submit = styled.div`
  grid-area: submit;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 15px;
  gap: 50px;
  font-weight: bold;

  @media screen and (max-width: 685px) {
    gap: 10px;
  }
  span {
    text-align: center;
  }
  button {
    border: none;
    border-radius: 10px;
    width: 200px;
    background-color: #ec681c;
    color: white;
    padding: 10px;
  }
`;

const appearAnimation = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 100%; /* Ajusta la altura máxima deseada */
  }
`;

export const Details = styled.div`
  /* Estilos para los detalles */
  grid-area: details;

  opacity: 0;
  max-height: 0;
  overflow: hidden;
  animation: ${appearAnimation} 500ms forwards;
  display: grid;
  grid-template-rows: 1px 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "hr hr hr"
    "details observations accessibility"
    "dbl twin sgl";

    @media screen and (max-width: 878px){
      grid-template-rows: 1px 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "hr hr"
    "details observations" 
    "dbl accessibility"
    "twin sgl";
    }
    @media screen and (max-width: 640px){
      grid-template-rows: 1px auto auto auto auto auto auto;
      justify-content: center;
      align-content: center;
  grid-template-columns: 1fr;
  grid-template-areas:
    "hr"
    "details" 
    "observations" 
    "accessibility"
    "dbl"
    "twin" 
    "sgl";
    

    }

  ul{
    padding-left: 20px;
    list-style: circle;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    li{
    text-overflow: ellipsis; /* Muestra puntos suspensivos (...) cuando el texto se recorta */
    width: 200px; /* Establece el ancho máximo del contenedor */
    margin: 5px 0;
  }

  }
  ${({ menu }) =>
    menu &&
    `
    opacity: 1;
    max-height: 100%; /* Ajusta la altura máxima deseada */
  `
   
  }

div:nth-child(1) {
    /* Estilos para el segundo div */
    grid-area: hr;
    
    hr {
    border-color: rgba(0,0,0, 0.2); /* Cambia el último valor para ajustar la opacidad */
    border-width: 1px;
    width: 100%;
    opacity: 0.5;
    display: block;
  }
  }

  div:nth-child(2) {
    /* Estilos para el segundo div */
    grid-area: details;
  }
  div:nth-child(3) {
    grid-area: observations; 
    
  }
  div:nth-child(4) {
    grid-area: accessibility; 
  }
  div:nth-child(5) {
    grid-area: dbl; 
  }
  div:nth-child(6) {
    grid-area: twin; 
  }
  div:nth-child(7) {
    grid-area: sgl; 
  }
`;



export const Options = styled.div`
  grid-area: options;

  animation: ${appearAnimation} 500ms forwards;
  display: flex;
  flex-direction: column;
`