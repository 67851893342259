import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* width: auto; */
  font-size: 12px;
  flex-direction: ${({ columnCard }) => (columnCard ? "column" : "row")};
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 13px;
  max-width: 250px;
  /* flex-direction: column; */

  @media screen and (max-width: 876px) {
    flex-direction: row;
    /* width: 250px; */
    max-width: 100%;
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  /* min-width: 200px; */
  /* width: 250px; */
  max-width: 100%;
  height: 11.25rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    object-fit: cover;
  }

  @media screen and (max-width: 876px) {
    max-width: 200px;
    /* max-width: 100%; */
  }
`;

export const Content = styled(Column)`
  gap: 0.5rem;
  align-items: start;
  width: 100%;

  @media screen and (max-width: 876px) {
    .subtitle {
      display: ${(props) => (props.subtitle.length > 25 ? "none" : "block")};
    }
  }
`;
export const Items = styled(Column)`
  gap: 2px;
  margin: 0 5px;
`;
export const Titles = styled(Column)`
  margin: 0 5px;
`;

export const Editable = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  right: -35%;

  i {
    cursor: pointer;
  }
`;
