import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  background-color: white;
  border-radius: 25px;

  @media screen and (max-width: 876px) {
    flex-direction: column;
    gap: 0;
    padding-bottom: 15px;
    text-align: center;
  }
`;

export const ImageContainer = styled.div`
  padding: 20px;
  display: flex;
  max-width: 16rem;
  img {
    width: 100%;
    /* max-width: 28.7rem;
    height: 19.2rem; */
    border-radius: 20px;

    @media screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    h4,
    h5 {
      margin: 0;
    }
    h4 {
      font-weight: 700;
    }
    h5 {
      font-weight: 600;
    }
  }
`;

export const ButtonContainer = styled.div`
  /* style={{border: "1px solid red", display: "flex", marginLeft: "auto", padding: "1.9rem"}} */
  /* border: 1px solid red; */
  display: flex;
  margin-left: auto;
  padding: 0 2rem 0 2rem;

  button {
    background-color: rgba(236, 104, 28, 1);
    color: white;
    padding: 8px 25px;
    border-radius: 15px;
    font-weight: 600;
    border: none;
  }

  @media screen and (max-width: 876px) {
    width: 100%;
    /* border: 1px solid red; */
    padding: 0;
    justify-content: center;
    margin: 0.5rem;
  }
`;
