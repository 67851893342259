import styled from "styled-components";
import Columns from "../Nationals/components/Columns/Columns";
import { Container, MainContent } from "../../styledComponents/packages/mainLayout";
import { usePackages } from "../../hooks/packages/usePackages";
import { RouteLink } from "../../styledComponents/globals/styles";
import { Link } from "react-router-dom";

const Box = styled(Container)`
  gap: auto;
  padding: 15px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 18px;
  /* flex-wrap: wrap; */
  /* display: grid; */
  margin-bottom: 5.8rem;

  @media screen and (max-width: 876px) {
    gap: 30px;
    align-items: center;
  }
`;


const Groups = () => {

    const {packages} = usePackages();

    const fullRoute = decodeURI(window.location.pathname.substring(1));
    const sliceRoute = fullRoute.split("/")
    
    return ( <>
    <RouteLink>
      {sliceRoute.map((parte, index) => (
        <span key={index}>
          <Link to={`/${sliceRoute.slice(0, index + 1).join("/")}`}>
            {parte}
          </Link>
          {index !== sliceRoute.length - 1 && " / "}
        </span>
      ))}
      </RouteLink>

    <MainContent>
  <Box>
      <Columns packages={packages} isGroup={true} region={"europa"} columnCard={true} label={"EUROPA"} travelType={"INTERNACIONALES"} />
      <Columns packages={packages} isGroup={true} region={"america"} columnCard={true} label={"AMERICA"} travelType={"INTERNACIONALES"} />
      <Columns packages={packages} isGroup={true} region={"medio oriente"} columnCard={true}  label={"MEDIO ORIENTE"} travelType={"INTERNACIONALES"} />
      <Columns packages={packages} isGroup={true} region={"asia"} columnCard={true} label={"ASIA"} travelType={"INTERNACIONALES"} />
      <Columns packages={packages} isGroup={true} region={"exoticos"} columnCard={true} label={"EXOTICOS"} travelType={"INTERNACIONALES"} />
  </Box> 
      </MainContent>
        </>
  );
}
 
export default Groups;