import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "../styles/bookingStyles";
import axios from "axios";
import { useRef, useState } from "react";
import styled from "styled-components";
import { setLoading } from "../../../redux/main.slice";

export const DeployContainer = styled.div`
  max-height: ${(props) =>
    props.show ? props.deploy.current.scrollHeight : "0"};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const Hotels = () => {
  const user = useSelector((state) => state.mainSlice.user);
  const [hotels, setHotels] = useState([]);
  const [showContainer, setShowContainer] = useState(false);
  const deployRef = useRef(null);
  const dispatch = useDispatch();

  const getHotelsDetails = async () => {
    const details = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/netstorming/hotelDetail?hotelId=${user.hotelsFav.join(",")}`
    );
    setHotels(details.data);
  };

  const toggleContainer = async () => {
    dispatch(setLoading(true));
    try {
      if (hotels.length === 0) {
        await getHotelsDetails();
      }
      setShowContainer(!showContainer);
    } catch (err) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  
  return (
    <div>
      <Badge >
        HOTELES GUARDADOS
        {user.hotelsFav.length !== 0 && <i onClick={toggleContainer}>
          { showContainer ? (
            <MdKeyboardArrowDown size={"30px"} />
          ) : (
            <MdKeyboardArrowUp size={"30px"} />
          )}
        </i>}
      </Badge>
      <DeployContainer deploy={deployRef} ref={deployRef} show={showContainer}>
        <TableContainer>
          <thead>
            <TableRow>
              <TableHeader></TableHeader>
              <TableHeader>HOTEL</TableHeader>
              <TableHeader>CIUDAD</TableHeader>
              <TableHeader>DIRECCIÓN</TableHeader>
              <TableHeader>ESTRELLAS</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {hotels?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                {console.log(item)}
                  <img
                    style={{ width: "120px" }}
                    src={
                      Array.isArray(item?.innerPics)
                        ? item.innerPics[0]?.src
                        : [item.innerPics][0]?.src
                    }
                    alt=""
                  />
                </TableCell>
                <TableCell>{item?.hotelsFacilities?.name}</TableCell>
                <TableCell>{item?.hotelsFacilities?.city}</TableCell>
                <TableCell>{item?.hotelsFacilities?.address}</TableCell>
                <TableCell>{item?.hotelsFacilities?.stars}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </TableContainer>
      </DeployContainer>
    </div>
  );
};

export default Hotels;
