import React, { useRef, useState } from "react";
import {
  FooterContainer,
  About,
  Contact,
  List,
  Social,
  Services,
  Query,
  CopyRight,
  Dollar,
  Button,
} from "./FooterStyle";
import IFace from "../../assets/icons/socialmedia/IFace";
import Insta from "../../assets/icons/socialmedia/Insta";
import IWhatsapp from "../../assets/icons/socialmedia/IWhatsapp";
import useDollar from "./useDollar";
import ModalFooter from "./Modal/ModalFooter";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [textArea, setTextArea] = useState("");
  const { dollar } = useDollar();

  const handleTextArea = (event) => {
    if (event.target.value.length > 5) {
      setError(false);
    }

    setTextArea(event.target.value);
  };
  const onModal = () => {
    if (textArea.length > 5) {
      setError(false);
      setOpen(true);
      return;
    }
    setError(true);
  };

  const ref = useRef("footer");
  return (
    <>
      <div style={{ backgroundColor: "#d9d9d9" }}>
        <FooterContainer ref={ref} id="footer">
          <About>
            <h4>Sobre Nosotros</h4>
            <p>
              Interlude es un Operador de Turismo Joven Familiar, con casi 10
              años de trayectoria y manteniendo las puntuaciones mas altas de
              redes sociales, incluso en Pandemia. Se crea desde la concepción
              del servicio, con el único objetivo de asistir al pasajero en cada
              requerimiento, desde que comienza su partida hasta que tenga su
              retorno, garantizando así la mejor experiencia de viaje posible.
            </p>
          </About>
          <Contact>
            <h4>Contacto</h4>
            <List>
              <div>
                <h5>Argentina</h5>
                <ul>
                  <li>Buenos Aires 2123, Casilda</li>
                  <li>+54 9 3464 62-9654</li>
                  <li>
                    <a href="mailto:info@interlude.com.ar">
                      info@interlude.com.ar
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Estados Unidos</h5>
                <ul>
                  <li>2100 Diana DR, 33009 Hallandale Beach, Florida</li>
                  <li>+1 (786) 727-6858</li>
                  <li>
                    <a href="mailto:info@interlude.com.ar">
                      info@interlude.com.ar
                    </a>
                  </li>
                </ul>
              </div>
            </List>
          </Contact>
          { dollar && dollar !== '' ?           
            <Dollar>
                <h4>Dólar Hoy</h4>
                <span>${dollar}</span>
            </Dollar> : ''
           }
          <Social>
            <h4>Nuestras Redes</h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/interludeviajes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Insta width="35px" height="35px" />
                  <span>@interludeviajes</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/interludevjs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IFace width="35px" height="35px" />
                  <span>Interlude Viajes</span>
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/message/O25R2ZQRRNX3G1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IWhatsapp width="35px" height="35px" />
                  <span>WhatsApp</span>
                </a>
              </li>
            </ul>
          </Social>

          <Services>
            <h4>Enlaces a nuestros servicios</h4>
            <ul>
              <li>
                <a
                  href="https://inter15.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Interquince
                </a>
              </li>
              <li>
                <a
                  href="https://interludeviajes.com/golf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Interlude Golf
                </a>
              </li>
              <li>
                <a
                  href="https://gpahorros.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Plan Ahorros
                </a>
              </li>
            </ul>
          </Services>
          <Query>
            <h4>Dejanos tu consulta</h4>
            <textarea
              onChange={handleTextArea}
              name="textarea"
              value={textArea}
              placeholder="Contanos tu duda"
            ></textarea>
            {error && (
              <span style={{ color: "red" }}>Este campo es obligatorio</span>
            )}
            <Button onClick={onModal}>Enviar</Button>
          </Query>
          <CopyRight>
            <small>
              &copy;2023 All rights reserved | This template is made with
              &hearts; by Interlude Viajes
            </small>
          </CopyRight>
        </FooterContainer>
      </div>
      <ModalFooter {...{ open, setOpen, textArea }} />
    </>
  );
};

export default Footer;
