import { Modal } from "@mui/material";
import { Grid, ImageContainer, ModalContainer } from "./styles";
import { MdOutlineFileDownload } from "react-icons/md";
import ModalImgPreview from "../../../../modals/ModalImgPreview";
import { useState } from "react";
import useFlyers from "../../../../hooks/packages/flyers/useFlyers";

const FlyerModal = ({ onModalFlyer, handleFlyerModal, flyers }) => {
  const [onItem, setOnItem] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const {handleDownload} = useFlyers();

  const onClose = (url) => {
    setSelectedImg(url);
    setOnItem(!onItem);
  };

  return (
    <>
      <Modal
        open={onModalFlyer}
        onClose={handleFlyerModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <h4>Flyers</h4>

          <Grid>
            {flyers?.map((item, index) => (
              <ImageContainer key={index}>
                <img src={item} onClick={() => onClose(item)} alt="flyer" />
                <div className="overlay">
                  <div className="buttons">
                    <span  onClick={() => handleDownload(item)}>
                      <MdOutlineFileDownload size={30} />
                    </span>
                  </div>
                </div>
              </ImageContainer>
            ))}
          </Grid>
          <a href="https://interlude.com.ar/flyers" target="_blank" rel="noopener noreferrer">Ver todos</a>
        </ModalContainer>
      </Modal>
      <ModalImgPreview {...{ onClose, onItem, selectedImg }} />
    </>
  );
};

export default FlyerModal;
