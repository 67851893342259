import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, updateHotels, updateUserHotels } from "../../redux/main.slice";
import { getBasis, getDeadlines } from "../../services/hotels.service";
import MobilePreHotelCard from "./MobilePreHotelCard";
import RoomCard from "./RoomCard";
import ILocation from "../../assets/icons/hotel/ILocation";
import IStar from "../../assets/icons/hotel/IStar";
import { BiShareAlt } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { useCheckClass } from "./hooks/useCheckClass";

import {
  Container,
  ImageContainer,
  Check,
  GreyButton,
  GreyButtonContainer,
  Icons,
  Links,
  ShowMoreButton,
  Submit,
  Spinner,
  CheckClassType
} from "./PreHotelCardStyle";
import { Options } from "./HotelCardStyle";
import { addHotelFav } from "../../services/auth/user.service";
import { Modal } from "@mui/material";
import MapView from "../MapView/MapView";

const Loader = () => (<Spinner>
  <div></div>
</Spinner>)

function PreHotelCard(props) {
  const {
    name,
    hotel_image,
    stars,
    totalPrice,
    address,
    checkIn,
    checkOut,
    pictures,
    code,
    search,
    agreement,
    hotelsFacilities,
    text,
  } = props;
  let searchNumber = useSelector((state) => state.mainSlice.hotels).search;
  let hotels = useSelector((state) => state.mainSlice.hotels);
  let totalFiltered = useSelector((state) => state.mainSlice.hotels).totalFiltered;

  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [basis, setBasis] = useState();
  const [meal, setMeal] = useState();
  const [deadline, setDeadline] = useState();
  const [isDesktop, setIsDesktop] = useState(false);
  const [isGetDeadline, setIsGetDeadline] = useState(false)
  const user = useSelector(state => state.mainSlice.user);
  const [isLoading, setIsLoading] = useState(false);

  let { checkedClass } = useCheckClass(hotelsFacilities?.classification);

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }  
  
  let quantityStars = [];

  const openDetails = () => {
    if (!menu) {
      dispatch(setLoading(true));
      getBasis(agreement, code, searchNumber)
        .then((resp) => {
          const newHotels = JSON.parse(JSON.stringify(hotels.hotels)).map((hotel) => {
            if(hotel.code === code){
              hotel.search = resp.search
              hotel.agreements = resp.agreements
            }
            return hotel
          })
          dispatch(updateHotels({...hotels, hotels: newHotels}))
          setMenu(true);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          console.log(err);
          alert("Algo salió mal, intente nuevamente!");
          dispatch(setLoading(false));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else {
      setMenu(false);
    }
  };

  const getAllDeadlines = async() => {

    try{
        dispatch(setLoading(true))
        const data = await getDeadlines(agreement, code, search)

        const deadlineInserted = agreement.map((item) => {
          if(item.deadline === null){
            for(let i = 0; i < data.length; i++){
              if(data[i].agreement.code === item.id){
                return  {
                  ...item,
                  deadline: {
                    date: data[i].deadline.date,
                    policies: data[i].policies,
                    remarks: data[i].remarks
                  }
                }
              }
            }
          }
          return item
        })
        const updatedHotels = JSON.parse(JSON.stringify(hotels.hotels))
        .map((item) => {
            if(item.code === code){
              return {
                ...item,
                agreements: deadlineInserted
              }
            }
          return item
        })
        dispatch(updateHotels({
          ...hotels,
          hotels: updatedHotels
        }))
        setIsGetDeadline(true)
        dispatch(setLoading(false))
    }
    catch(err){
      dispatch(setLoading(false))
      console.log(err)
    }
  }

  const addHotel = async(id, method) => {

    try{
      if(method === "add"){
        setIsLoading(true)
        if(user.hotelsFav.includes(id)) return;
        const data = await addHotelFav(id, method);
        dispatch(updateUserHotels(data.data));
        setIsLoading(false)
      }
      if(method === "remove"){
        setIsLoading(true)
        await addHotelFav(id, method)
        let filteredArr = user.hotelsFav.filter(item => item !== id);
        dispatch(updateUserHotels(filteredArr))
        setIsLoading(false)
      }
      setIsLoading(false)
    }
    catch(err){
      setIsLoading(false)
      console.log(err)
      return;
    }
  }
  for (let i = 0; i < stars; i++) {
    quantityStars.push(<IStar height="30px" width="30px" />);
  }

  const handleWindowResize = () => {
    setIsDesktop(window.innerWidth >= 878);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setMenu(false)
  }, [totalFiltered, searchNumber])

  useEffect(() => {
      setIsGetDeadline(false);
  }, [search])

  return (
    <>
      {isDesktop ? (
        <Container>
          <ImageContainer>
            <img src={hotel_image ? hotel_image : "https://photo.netstorming.net/hotel/34658/wide/1.jpg"} alt="" />
            {menu && <span>Opciones ({agreement.length ? agreement.length : "1"})</span>}
          </ImageContainer>
          <div>
            <div>
              <div>
                <h3
                  style={{ display: "flex", alignItems: "center", margin: "0" }}
                >
                  {name}{" "} 
                </h3>
                <div style={{display:"flex", gap:"5px" }}>
                  <span>
                    {quantityStars?.map((e, i) => (
                      <React.Fragment key={i}>{e}</React.Fragment> 
                    ))}  
                  </span>
                  {
                  hotelsFacilities.classification !== undefined ?
                  (
                  <CheckClassType>
                    <a>{checkedClass}</a>
                  </CheckClassType>
                  ) : ""  
                  }
                </div>
              </div>
              <span style={{ display: "flex", alignItems: "center" }}>
                <i>
                  <ILocation width={"30px"} height={"30px"} />
                </i>
                {address}
              </span>
            </div>
            <Check>
              <Icons></Icons>
            </Check>
          </div>
          <Links>
            <GreyButtonContainer>
              <span>Desde: USD${totalPrice}</span>
            </GreyButtonContainer>
            <Submit>
              <GreyButton onClick={handleOpen} >MAPA</GreyButton>
              <Modal 
                open={open}
                onClose={handleClose} 
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                    <div style={{width: '90%', margin: 'auto', marginTop: '50px'}} >
                        <MapView name={name} hotels={hotelsFacilities} coords={hotelsFacilities?.coords} width={"100%"} height={"450px"} />
                    </div>
              </Modal>
              <ShowMoreButton onClick={openDetails}>VER MÁS</ShowMoreButton>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                }}
              >
                <BiShareAlt style={{ cursor: "pointer" }} size={"25"} />
                { isLoading ? <Loader /> : user.hotelsFav?.includes(code) ? (
                  <BsBookmarkFill onClick={() => addHotel(code, "remove")} size={"25"} style={{ cursor: "pointer" }} />
                  ) : (
                    <BsBookmark onClick={() => addHotel(code, "add")} size={"25"} style={{ cursor: "pointer" }} />
                    )
                }
                
              </div>
            </Submit>
          </Links>
        </Container>
      ) : (
        <MobilePreHotelCard
          menu={menu}
          agreement={agreement}
          image={hotel_image}
          openDetails={openDetails}
          address={address}
          stars={quantityStars}
          deadline={deadline}
          name={name}
          hotelsFacilities={hotelsFacilities}
          text={text}
          search={search}
        />
      )}
      {menu && (
        <Options menu={menu}>
          <RoomCard
            basis={basis}
            deadline={deadline}
            meal={meal}
            agreement={agreement}
            code={code}
            image={pictures}
            address={address}
            stars={stars}
            name={name}
            hotelsFacilities={hotelsFacilities}
          search={search}
          />
          {
            agreement.length > 5 && !isGetDeadline && <span style={{ margin: "0 auto", fontWeight: "600", userSelect: "none", cursor: "pointer", marginBottom: "30px"}} onClick={getAllDeadlines}>Mostrar todos</span>
          }
        </Options>
      )}
    </>
  );
}

export default PreHotelCard;
