import React from "react";
import Carousel from "./Carousel/Carousel";
import "./Hero.css";
import Search from "../../components/NewSearch/Search.jsx";
import styled from "styled-components";
import Separator from "../../components/Separator/Separator";
import { links } from "../../data";
import Gallery from "../Gallery/Gallery";
import SwiperCarousel from "../../components/Swiper/SwiperCarousel";
import PackageCard from "../../components/InterCard/InterCard";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { usePackages } from "../../hooks/packages/usePackages";
import Feedback from "../Feedback/Feedback";
import inter15 from "../../components/ServiceCard/assets/inter15.png";
import intergolf from "../../components/ServiceCard/assets/golf.png"
import gpa from "../../components/ServiceCard/assets/gpa.png"
import Promotion from "../../components/Promotion/Promotion";


const SearchContainer = styled.div`
  /* margin: 0 70px; */

  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

const Hero = ({setOpenLogin}) => {

  const {packages} = usePackages();

  return (
    <>
      <Carousel />       {/*----SLIDERS-----*/}
      {/* <div style={{marginRight: "210px", marginLeft: "210px"}}> */}
      <SwiperCarousel
        title={"¿Ya elegiste tu próximo destino?"}
        subtitle={"Vos decidís a dónde y nosotros nos encargamos del resto"}
        cards={packages && packages?.filter(item => item.show).map((item, index) => <PackageCard {...item} /> )}
        />
        {/* </div> */}
      <SearchContainer>
        <Search {...{setOpenLogin}}/>
      </SearchContainer>
      <Separator link={links.banner_1} />
      <SwiperCarousel
        title={"Más servicios que tenemos para ofrecerte"}
        subtitle={
          "Te acompañamos en cada uno de tus viajes, para asegurarte el mejor"
        }
        cards={[
          <ServiceCard image={gpa} title="Get Plan Ahorros" url="https://gpahorros.com.ar/" subtitle="Get Plan Ahorros" />,
          <ServiceCard image={inter15} title="Interquince" url="https://inter15.com.ar/" subtitle="Interquince" />,
          <ServiceCard image={intergolf} title="Interlude Golf" url="http://www.interludegolf.com.ar/" subtitle="Interlude Golf" />,
          
        ]}
      />
      <Separator link={links.banner_2} />
      <Gallery />
      <Feedback />
      <Promotion />
    </>
  );
};

export default Hero;
