import { ImageContainer, SectionContainer, Grid } from "../styles";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { capitalizefirstletter } from "../../../utils/pipes";
import { getPackageIdByFlyer } from "../../../services/packages.service";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { useState } from "react";
import useFlyers from "../../../hooks/packages/flyers/useFlyers";

const SubSection = ({ type, flyers }) => {
  const [expanded, setExpanded] = useState(true);
  const {handleDownload, handleNavigate} = useFlyers();

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  

  return (
    <>
      <h4
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={toggleExpanded}
      >
        {capitalizefirstletter(type)}{" "}
        {!expanded ? <FaArrowUp /> : <FaArrowDown />}
      </h4>
      <Grid expanded={expanded}>
        {flyers.map((item, index) => (
          <ImageContainer key={index}>
            <img src={item} alt="" />
            <div className="overlay">
              <div className="buttons">
                <span
                //  onClick={() => handleDownload(item)}
                >
                  <MdOutlineFileDownload
                    onClick={() => handleDownload(item)}
                    size={30}
                  />
                </span>
                <span>
                  <MdOutlineArrowForwardIos
                    onClick={() => handleNavigate(item)}
                    size={30}
                  />
                </span>
              </div>
            </div>
          </ImageContainer>
        ))}
      </Grid>
    </>
  );
};

const Section = ({ flyers, travelType, type }) => {
  return (
    <>
      <h3 style={{ margin: 0 }}>{capitalizefirstletter(travelType)}</h3>
      <SectionContainer>
        {Object.keys(flyers).map((item, index) => (
          <SubSection key={index} {...{ type: item, flyers: flyers[item] }} />
        ))}
      </SectionContainer>
    </>
  );
};

export default Section;
