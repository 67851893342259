import styled from "styled-components"


export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  border-radius: 25px;
  background-color: rgba(217, 217, 217, 1);
  /* border-radius: ${({ isFirst, isLast }) => {
      if (isFirst) return "30px 0 0 30px"; // Border radius for the left side
      if (isLast) return "0 30px 30px 0"; // Border radius for the right side
      return "0"; // No border radius for middle elements
    }}; */

    /* padding: ${({ isFirst, isLast }) => {
      if (isFirst) return "30px 20px 45px 20px"; // Border radius for the left side
      if (isLast) return "30px 20px 45px 10px"; // Border radius for the right side
      return "0"; // No border radius for middle elements
    }}; */
    /* padding: 20px 35px 35px 35px; */

  @media screen and (max-width: 876px){
    /* padding: 30px; */
    /* width: 80%; */
    border-radius: 30px;
}
`;

export const Column = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding: 10px; */
  
`;
export const Header = styled.h4`
  /* background-color: #ccc; */
  /* padding: 10px; */
  font-weight: bold;
  margin: 0;
  margin-left: 8px;
  margin-bottom: 10px;
`;