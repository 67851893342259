import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import {
  Input,
  Container,
  Grid,
  Heading,
  InputContainer,
  Tab,
  ContainerTwo,
  Content,
  Content7,
  Content8,
  FilterContainer,
} from "./SearchStyle";
import { scrollTo } from "../../utils/scroll";
import { Select } from "./SearchStyle";
import RoomMap from "./RoomMap";
import IRecent from "../../assets/icons/menus/IRecent";
import flechaAbajo from "../../assets/iconsSVG/más-op-02.svg";
import { useFormik } from "formik";
import validationSchema from "../../Validators/searchForm.validator";
import { useDispatch, useSelector } from "react-redux";
import { getHotels, setLoading } from "../../redux/main.slice";
import HotelsFound from "./HotelsFound";
import "./Search.css"
import { commonAlert } from "../../utils/alerts";
import Filters from "../HotelList/components/Filters";
import useFilters from "../../hooks/hotels/useFilters";

export const ErrorMessage = styled.span`
  font-size: 10px;
  color: red;
  position: absolute;
  top: 110%;
  /* top: 10px; */
`;

const NewSearch = ({setOpenLogin}) => {
  const {hasMore,setHasMore,isFiltered,scrollReload,setIsFiltered,setScrollReload} = useFilters()
  const [suggestions, setSuggestions] = useState([]);
  const [suggestCode, setSuggestCode] = useState("");
  const [cityName, setCityName] = useState("");
  const user = useSelector(state => state.mainSlice.user)
  const hotels = useSelector(state => state.mainSlice.hotels)


  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      city: "",
      checkIn: "",
      checkOut: "",
      nights: "",
      stars: "",
      //paxes
      childs: "",
      infants: "",
      adults: "",
      room1: { type: "", adults: "", childs: "", infants: "" },
      room2: { type: "", adults: "", childs: "", infants: "" },
      room3: { type: "", adults: "", childs: "", infants: "" },
      room4: { type: "", adults: "", childs: "", infants: "" },
      sgl: "",
      dbl: "",
      twin: "",
      food: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if(!user.id) {

          commonAlert("Porfavor inicie sesión para continuar", "", "error")
          return setOpenLogin(true);
        }
        if(!user.status) return commonAlert("Cuenta no autorizada", "Por favor, aguarde mientras verificamos su cuenta para permitirle realizar reservas. Si esta situación persiste, le invitamos a ponerse en contacto con nuestro equipo de soporte. <b>Si su cuenta ya ha sido autorizada porfavor intente iniciando sesión nuevamente</b>", "error")
        values = { ...values, city: suggestCode ? suggestCode : values.city };
        const roomMap = [
          values.room1,
          values.room2,
          values.room3,
          values.room4,
        ];
        let totalAdults = 0;
        let totalChilds = 0;
        let totalInfants = 0;

        for (const room of roomMap) {
          let adults = room.adults !== "" ? parseInt(room.adults) : 0;
          let childs = room.childs !== "" ? parseInt(room.childs) : 0;
          let infants = room.infants !== "" ? parseInt(room.infants) : 0;

          totalAdults += adults;
          totalChilds += childs;
          totalInfants += infants;
        }
        const roomCounts = roomMap.reduce((acc, room) => {
          const roomType = room.type;
          acc[roomType] = (acc[roomType] || 0) + 1;
          return acc;
        }, {});
        values = { ...values, sgl: roomCounts["sgl"] || "", dbl: roomCounts["dbl"] || "", twin: roomCounts["twn"] || "", adults: totalAdults, childs: totalChilds, infants: totalInfants };

        dispatch(setLoading(true));
        await dispatch(getHotels(values)); 
        localStorage.setItem("search", JSON.stringify(values))
        localStorage.setItem("cityNameSearch", JSON.stringify(cityName))
        localStorage.removeItem("filters")
        scrollTo('results')
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
      }
    },
  });

  useEffect(() => {
    if (form.values.checkIn && form.values.checkOut) {
      const checkInDate = new Date(form.values.checkIn);
      const checkOutDate = new Date(form.values.checkOut);
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(
        Math.abs((checkOutDate - checkInDate) / oneDay)
      );
      form.setValues({ ...form.values, nights: diffDays });
    }
  }, [form.values.checkIn, form.values.checkOut, form.setValues]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    form.setFieldValue(name, value);
  };

  const getSuggestionValue = (suggestion) => suggestion.city;

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const onSuggestionSelected = (event, { suggestion }) => {
    event.target.value = suggestion.name;
    form.setFieldValue("city", suggestion.name);
    setSuggestCode(suggestion.code);
    setCityName(suggestion.name);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Verifica si la tecla presionada es Enter

      const selectedSuggestion = suggestions.find(
        (suggestion) => suggestion.name === event.target.value
      );

      // Si el valor del input coincide con una sugerencia, actualiza el valor del input y el estado
      event.target.value = selectedSuggestion.name;
      form.setFieldValue("city", selectedSuggestion.name);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/netstorming/countries?city=${value.trim()}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSuggestions(data);
      });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Ciudad",
    value: form.values.city,
    onChange: handleInputChange,
    name: "city",
    onKeyDown: onKeyDown,
    onblur: (event) => {
      // set cursor to beginning of input
      event.target.setSelectionRange(0, 0);
      form.handleBlur(event);
    },
  };

  return (
    <>
    <Container id="search" onSubmit={form.handleSubmit}>
      <Heading>
        <h2>¿Buscando algo en particular? </h2>
        <p>Te ayudamos a encontrar al aéreo, hotel o excursión que buscás</p>
      </Heading>
      <Tab>
        <span className="active">Hoteles</span>
        {/* <span>Paquetes</span> */}
        <span>Aéreos</span>
      </Tab>
      <Content>
        <Grid>
          <InputContainer className="first">
            <label htmlFor="destination">Destino</label>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={onSuggestionSelected}
            />
          </InputContainer>
          <ContainerTwo className="sec">
            <InputContainer className="date">
              <label htmlFor="checkIn">Desde *</label>
              <Input
                type="date"
                id="checkIn"
                name="checkIn"
                selected={form.values.checkIn}
                onChange={handleInputChange}
                value={form.values.checkIn}
                placeholder="Destino"
              />
              <div className="flecha-abajo">
                <img src={flechaAbajo} alt="" />
              </div>
              {form.errors.checkIn && form.touched.checkIn ? (
                <ErrorMessage>{form.errors.checkIn}</ErrorMessage>
              ) : null}
            </InputContainer>
            <InputContainer className="date">
              <label htmlFor="checkOut">Hasta *</label>
              <Input
                type="date"
                id="checkOut"
                name="checkOut"
                selected={form.values.checkOut}
                onChange={handleInputChange}
                value={form.values.checkOut}
                placeholder="Destino"
              />
              <div className="flecha-abajo">
                <img src={flechaAbajo} alt="" />
              </div>
              {form.errors.checkOut && form.touched.checkOut ? (
                <ErrorMessage>{form.errors.checkOut}</ErrorMessage>
              ) : null}
            </InputContainer>
            <InputContainer className="nights">
              <label htmlFor="nights">Noches</label>
              <Input
                type="text"
                disabled
                id="nights"
                value={form.values?.nights}
                placeholder=""
              />
            </InputContainer>
          </ContainerTwo>
        </Grid>
        <Content7>
          <RoomMap form={form} />
        </Content7>
      </Content>
      <Content8>
        <button type="submit">BUSCAR</button>
      </Content8>
    </Container>
    {
      hotels.hotels && <div style={{backgroundColor: "whitesmoke",}}>
      <FilterContainer >
            <Filters setHasMore={setHasMore} setIsFiltered={setIsFiltered} setScrollReload={setScrollReload} />
      </FilterContainer>
      </div>
    }
    <div style={{backgroundColor:"whitesmoke"}}>
    <HotelsFound />
    </div>
    </>
  );
};

export default NewSearch;
