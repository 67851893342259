import React from "react";
import { useFormik } from "formik";
import { Container } from "./styles";
import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const MobilePaxForm = ({ paxes }) => {
  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      ...paxes.reduce((acc, pax, index) => {
        acc[`pax${index + 1}`] = "";
        acc[`passport${index + 1}`] = "";
        return acc;
      }, {}),
    },
    onSubmit: (values) => {
    },
  });
  const handleInputChange = (index, field, event) => {
    const { value } = event.target;
    if(field === 'pax' || field === 'passport' ){
      formik.setFieldValue(`${field}${index + 1}`, value);
    }
    formik.setFieldValue(field, value)
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      {paxes?.map((pax, index) => {
        return (
          <React.Fragment key={index}>
            <Container>
              <input
                name={`pax${index + 1}`}
                type="text"
                onChange={(event) => handleInputChange(index, 'pax', event)}
                value={formik.values[`pax${index + 1}`]}
                placeholder="Nombre y Apellido"
              />
            </Container>
            <Container>
              <input
                name={`passport${index + 1}`}
                type="text"
                placeholder="Número de Pasaporte"
                onChange={(event) =>
                  handleInputChange(index, "passport", event)
                }
                value={formik.values[`passport${index + 1}`]}
              />
            </Container>
            <div>
              <hr />
            </div>
          </React.Fragment>
        );
      })}
      <Container>
        <input
          name="phone"
          type="text"
          onChange={(event) => handleInputChange(null, 'phone', event)}
          value={formik.values.phone}
          placeholder="Teléfono*"
        />
      </Container>
      <Container>
        <input
          name="email"
          type="text"
          onChange={(event) => handleInputChange(null, 'email', event)}
          value={formik.values.email}
          placeholder="Email*"
        />
      </Container>
      <Container>
        <input
          style={{
            backgroundColor: "#EC681C",
            color: "white",
            fontWeight: 600,
          }}
          type="submit"
          value="RESERVAR"
        />
      </Container>
    </Form>
  );
};

export default MobilePaxForm;
