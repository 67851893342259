import {ErrorMesage, Label, Input} from "../ProfileStyle"

const ListItem = ({ edit, label, value, id, className, onChange, name, isError, error, onBlur }) => {
  return (
    <li style={{fontSize: "0.81rem", fontWeight: 600}} className={edit ? "li_column" : className}>
      <Label htmlFor={id}>{label}: </Label>
      {edit ? <Input name={name} id={id} value={value} onChange={onChange} onBlur={onBlur} /> : <span>{value}</span>}
      {isError && <ErrorMesage>{error}</ErrorMesage>}
    </li>
  );
};

export default ListItem;
