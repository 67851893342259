import { useFormik } from "formik";
import { useState } from "react";
import {
  Container,
  DetailsContainer,
  EditContainer,
  ImageContainer,
  ListContainer,
  ErrorMesage,
  Label,
  Input
} from "../ProfileStyle";
import ListItem from "./ListItem";
import { profileSchema } from "../validators/profile.validator";
import { updateUser } from "../../../services/auth/user.service";
import { commonAlert } from "../../../utils/alerts";

const Agency = ({ profileData, id }) => {
  const [edit, setEdit] = useState(false);

  const onEdit = () => {
    setEdit(true);
  };

  const onCancelEdit = () => {
    // formik.resetForm();
    setEdit(false);
  };

  const formik = useFormik({
    initialValues: {
      cuit: profileData.cuit,
      social: profileData.social,
      ivaCondition: profileData.ivaCondition,
      address: profileData.address,
      email: profileData.email,
      promotionEmail: profileData.promotionEmail,
      legacy: profileData.legacy,
      agency: profileData.agency,
      username: profileData?.username
    },
    enableReinitialize: true,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      try {
        await updateUser({ ...values, _id: id });
        setEdit(false);
        commonAlert("Información actualizada!", "", "success")
      } catch (err) {
          commonAlert("Algo salió mal", "", "error")
          console.log(err);
      }
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    formik.setFieldValue(name, value);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            top: "-7%",
            left: "1%",
            fontWeight: "600",
            color: "#949494",
          }}
        >
          INFORMACIÓN DE CUENTA
        </span>
        <Container onSubmit={formik.handleSubmit}>
          <ImageContainer>
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt="profile"
            />
          </ImageContainer>
          <DetailsContainer>
            <h4>
              {edit ? (
                <>
                  <Label>Nombre de la agencia: </Label>
                  <Input
                    type="text"
                    name="agency"
                    id="agency"
                    value={formik.values.agency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              ) : (
                formik.values.agency
              )}
            </h4>
            <h5>
              {edit ? (
                <>
                  <Label>Usuario: </Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              ) : (
                formik.values.username
              )}
            </h5>
            {/* <h5>
              <Label>Número de legajo: </Label>
              {edit ? (
                <>
                  <Input
                    id="legacy"
                    value={formik.values.legacy}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="legacy"
                  />
                  {formik.touched.legacy && formik.errors.legacy ? (
                    <ErrorMesage>{formik.errors.legacy}</ErrorMesage>
                  ) : null}
                </>
              ) : (
                formik.values.legacy
              )}
            </h5> */}
            <ListContainer>
              <ul>
              <ListItem
                  id={"legacy"}
                  edit={edit}
                  label={"Legajo"}
                  name="legacy"
                  value={formik.values.legacy}
                  onChange={formik.handleChange}
                  isError={formik.touched.legacy && formik.errors.legacy}
                  error={formik.errors.legacy}
                  onBlur={formik.handleBlur}
                />

                <ListItem
                  id={"cuit"}
                  edit={edit}
                  label={"Cuit"}
                  name="cuit"
                  value={formik.values.cuit}
                  onChange={formik.handleChange}
                  isError={formik.touched.cuit && formik.errors.cuit}
                  error={formik.errors.cuit}
                  onBlur={formik.handleBlur}
                />
                <ListItem
                  id={"social"}
                  edit={edit}
                  label={"Razon Social"}
                  name="social"
                  value={formik.values.social}
                  onChange={handleChange}
                  isError={formik.touched.social && formik.errors.social}
                  error={formik.errors.social}
                  onBlur={formik.handleBlur}
                />
                <ListItem
                  id={"ivaCondition"}
                  edit={edit}
                  label={"Condicion IVA"}
                  name="ivaCondition"
                  value={formik.values.ivaCondition}
                  onChange={handleChange}
                  isError={
                    formik.touched.ivaCondition && formik.errors.ivaCondition
                  }
                  error={formik.errors.ivaCondition}
                  onBlur={formik.handleBlur}
                />
                <ListItem
                  id={"address"}
                  edit={edit}
                  label={"Dirección"}
                  name="address"
                  value={formik.values.address}
                  onChange={handleChange}
                  isError={formik.touched.address && formik.errors.address}
                  error={formik.errors.address}
                  onBlur={formik.handleBlur}
                />
              </ul>
              <ul>
                <ListItem
                  className="li_column"
                  id={"email"}
                  edit={false}
                  label={"Email de registro"}
                  value={profileData.email}
                />
                <ListItem
                  className="li_column"
                  id={"promotionEmail"}
                  edit={edit}
                  label={"Email para recibir promociones"}
                  name={"promotionEmail"}
                  value={formik.values.promotionEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <li className="li_column">
                  <Label>Contraseña: </Label>
                  <Label>********</Label>
                </li>
              </ul>
            </ListContainer>
          </DetailsContainer>
          <EditContainer>
            {!edit ? (
              <button type="button" onClick={onEdit}>
                Editar
              </button>
            ) : (
              <>
                <button type="button" onClick={onCancelEdit}>
                  Cancelar
                </button>
                <button type="submit" onClick={formik.handleSubmit}>
                  Guardar
                </button>
              </>
            )}
          </EditContainer>
        </Container>
      </div>
    </>
  );
};

export default Agency;
