import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


export const sendMail = async(clientEmail, text) => {

    try{
        const data = await axios.post(`${API_URL}`, {clientEmail, text})
        return data.data
    }
    catch(err){
        console.log(err)
    }
}
