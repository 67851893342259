import {
  Box,
  Button,
  Container,
  FormControl,
  Typography,
  TextField,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewPassword,
  verifyRecovery,
} from "../../services/auth/user.service";
import { newPasswordSchema } from "./validator";
import { commonAlert } from "../../utils/alerts";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(236, 104, 28)",
      hover: "rgb(186, 82, 15)",
    },
  },
});

const NewPassword = () => {
  const { token } = useParams();
  const [verify, setVerify] = useState(false);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    try {
      await newPasswordSchema.validate(
        { password, confirmPassword },
        { abortEarly: false }
      );
      await createNewPassword(password, token)
      commonAlert("Contraseña Actualizada", "", "success")
      navigate("/")
    } catch (validationError) {
      const newErrors = {};
      validationError.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    verifyRecovery(token)
      .then((resp) => {
        setVerify(resp);
        if (!resp) navigate("/");
      })
      .catch(() => navigate("/"));
  }, []);

  return (
    <>
      {verify && (
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              padding: 5,
            }}
          >
            <h2>Crear nueva contraseña</h2>
            <FormControl style={{ gap: "15px" }} component={"form"}>
              <TextField
                label="Contraseña nueva"
                type="password"

                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors({ ...errors, password: "" });
                }}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
              <TextField
                label="Confirmar contraseña"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrors({ ...errors, confirmPassword: "" });
                }}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                
              />

              <Button
              onClick={handleSubmit}
                sx={{
                  backgroundColor: "rgb(236, 104, 28)",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                }}
                variant="contained"
              >
                Guardar
              </Button>
            </FormControl>
          </Box>
        </Container>
      )}
    </>
  );
};

export default NewPassword;
