import styled from "styled-components";

export const FooterContainer = styled.div`
  display: grid;
  font-family: Montserrat;
  font-weight: 400;
  padding: 50px 0 50px 0;
  margin-left: var(--margin-large);
  margin-right: var(--margin-large);
  grid-gap: 0 3rem;
  grid-row-gap: 0px;
  border-radius: 30px 30px 0px 0px;
  grid-template-rows: auto auto auto;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-areas:
    "about contact dolar social"
    "services services query query"
    "copyright copyright copyright copyright";

  ul {
    padding: 0;
    list-style: none;
  }

  ul li {
    margin: 5px 0;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  p,
  li {
    font-weight: 400;
  }

  @media screen and (max-width: 1022px) {
    padding: 30px;
    margin: 0;
    grid-gap: 0;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-areas:
      "about about about about"
      "contact contact social social"
      "services services dolar dolar"
      "query query query query"
      "copyright copyright copyright copyright";

    a,
    li {
      font-size: 12px;
    }
    h4,
    h5 {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 400px) {
    /* text-align: start; */
    margin: 0;
    grid-gap: 0;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-areas:
      "about about about about"
      "contact contact social social"
      "services services dolar dolar"
      "query query query query"
      "copyright copyright copyright copyright";

    h4,
    h5 {
      font-size: 12px;
    }
    a,
    li {
      font-size: 12px;
    }
  }
`;

export const About = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: about;
  text-align: justify;
`;

export const Contact = styled.div`
  grid-area: contact;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    margin-top: 0.4rem !important;
  }
  @media screen and (max-width: 420px) {
    ul {
      gap: 0;
    }
  }
`;

export const Dollar = styled.div`
  grid-area: dolar;

  span {
    background-color: #9e9e9e;
    color: white;
    padding: 5px;
    border-radius: 8px;
  }

  h4{
    margin-bottom: 1.3rem !important;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    margin-bottom: 0.5rem !important;
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  @media screen and (max-width: 420px) {
    justify-content: space-between;

    ul {
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: safe;
    gap: 0.5rem;
  }
`;

export const Item = styled.div`
  h5 {
    margin: 0 0 10px 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 5px 0;
    }
  }
`;

export const Social = styled.div`
  grid-area: social;
  justify-content: center;

  a {
    color: rgb(89, 89, 89);
  }

  @media screen and (max-width: 420px) {
    ul {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
    }
  }

  li {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      font-weight: 600;
      text-decoration: none;

      span {
        @media screen and (max-width: 420px) {
          display: none;
          /* font-size: 10px; */
        }
      }
    }
  }
`;

export const Services = styled.div`
  grid-area: services;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    li {
      a {
        font-weight: 600;
        text-decoration: none;
        color: rgb(89, 89, 89);
      }
    }
  }
`;

export const Query = styled.div`
  grid-area: query;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* padding-right: 30px; */

  textarea {
    resize: none;
    width: auto;
    height: 15vh;
    padding: 10px;
    background: rgba(249, 246, 246, 0.81);
    border-radius: 20px;
    border: none;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
  /* @media screen and (max-width:420px) {
  } */
`;

export const CopyRight = styled.div`
  grid-area: copyright;
`;

export const ModalContainer = styled.div`
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 1rem;
  border-radius: 18px;
  position: relative;
`;

export const Button = styled.button`
  width: 30%;
  padding: 5px;
  margin: 0 0 0 auto;
  color: white;
  background: #ec681c;
  border: none;
  border-radius: 10px;
  font-family: Montserrat;
`;
