import styled from "styled-components";
import { MainContent } from "../../styledComponents/packages/mainLayout";
import { RowCard } from "./RowCard/RowCard";
import { RouteLink } from "../../styledComponents/globals/styles";
import { Link } from "react-router-dom";

const Box = styled.div`
  margin: 0 0 5.8rem 0;
  display: flex;
  gap: 30px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 25px;
  width: 100%;
  margin-left: var(--margin-large);
  margin-right: var(--margin-large);

  @media screen and (max-width: 1300px) {
    margin-left: 5rem;
    /* gap: 30px; */
    margin-right: 5rem;
  }

  @media screen and (max-width: 876px) {
    gap: 30px;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    /* gap: 30px; */
    margin-left: 0;
    margin-right: 0;
  }
`;

const PackageByType = ({ travelType, label, region, isGroup }) => {

  const fullRoute = decodeURI(window.location.pathname.substring(1));
  const sliceRoute = fullRoute.split("/")


  return (
    <div>
      <RouteLink>
      {sliceRoute.map((parte, index) => (
        <span key={index}>
          <Link to={`/${sliceRoute.slice(0, index + 1).join("/")}`}>
            {parte}
          </Link>
          {index !== sliceRoute.length - 1 && " / "}
        </span>
      ))}
      </RouteLink>

      <MainContent>
        <Box>
          <RowCard
            label={label}
            travelType={travelType}
            region={region}
            isGroup={isGroup}
          />
        </Box>
      </MainContent>
    </div>
  );
};

export default PackageByType;
