import jwtDecode from "jwt-decode";

export const getStoredToken = () => {
  const token = JSON.stringify(localStorage.getItem("token"));
  return token;
};

export const getMongoIdByToken = (token) => {
  if (!token) return null;

  let decodedToken; 

  try{
    decodedToken = jwtDecode(token)
  }
  catch(err){
    return null
  }

  return decodedToken || null;
};

export const updateLocalStorage = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("accessToken="))
    ?.split("=")[1];
  if (cookieValue) {
    localStorage.setItem("token", cookieValue);
  }
};
