import styled, { keyframes } from "styled-components";

export const Layout = styled.div`
  background-color: #f0efef;
  margin-left: 106px;
  margin-right: 106px;
  border-radius: 25px;
  /* border: 1px solid red; */
  padding-bottom: 40px;

  @media screen and (max-width: 1170px) {
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 106px;
  margin-right: 106px;
  margin-top: 4rem;

  @media screen and (max-width: 876px) {
    margin: 0 12px 0 12px;
  }
`;
export const CardContainer = styled.div`
  padding: 15px;
  border-radius: 30px;
  width: 100%;
  background-color: white;
`;
export const ResultsContainer = styled.div``;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  div {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid rgba(236, 104, 28, 1);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spinAnimation} 1s linear infinite;
  }
`;
