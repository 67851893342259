import styled, {keyframes} from "styled-components";

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr 1fr;
  column-gap: 15px;
  background-color: white;
  padding: 15px 15px;
  /* border-radius: 17px 17px 0 0; */
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 220px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 17px;
  }
  span {
    margin: 0 50% 0 0;
    padding-top: 10px;
    color: grey;
  }
`;

export const Check = styled.div`
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 7px 0;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
  gap: 15px;
`;
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 1.6rem;
  flex-wrap: wrap;
`;
export const GreyButton = styled.button`
  width: 150px;
  height: 35px;
  background-color: #d9d9d9;
  color: white;
  border-radius: 20px;
  border: none;
`;
export const GreyButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 600;
  width: 100%;
  position: relative;

  span {
    position: absolute;
    right: 10%;
  }
`;
export const Submit = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 1.8rem;

  @media screen and (max-width: 1380px) {
      flex-direction: column;
      gap: 1rem;
  }
`;
export const ShowMoreButton = styled.button`
  height: 35px;
  width: 150px;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: rgba(236, 104, 28, 1);
  font-weight: 600;
`;

export const Spinner = styled.div`

display: flex;
  justify-content: center;
  align-items: center;

   div{
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid rgba(236, 104, 28, 1);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: ${spinAnimation} 1s linear infinite;
   }
`
export const CheckClassType = styled.span`
      display: flex;
      align-items: center;
      color: white;
      text-align:center;
      font-weight: bolder;
      font-size: 13px;
      
      a{
        background-color: rgba(236, 104, 28, 1);
        border-radius: 8px;
        padding: 3px 7px 3px 7px;
      }
`;