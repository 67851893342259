import React, { useState, useEffect } from "react";
import styled from "styled-components";
import icon from "./icon_upto.png"

export const Button = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 60px;
  width: 60px;
  background-color: transparent;
  border: 2px solid #EC681C;
  border-radius: 30px;
  cursor: pointer;
`;

const ScrollToTopButton = () => {
  const [backTop, setBackTop] = useState(false);

  useEffect(() => {
    const footer = document.getElementById("footer");
    const footerPosition = footer.getBoundingClientRect().top;

    const handleScroll = () => {
      const currentPosition = window.pageYOffset + window.innerHeight;
      if (currentPosition >= footerPosition) {
        setBackTop(true);
      } else {
        setBackTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div>
      {backTop && (
        <Button onClick={handleScroll}>
          <img style={{ height: "30px" }} src={icon} alt="scroll up" />
        </Button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
