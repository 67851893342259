import ReactPlayer from 'react-player'
import {
  Container,
  ContainerImage,
  Content,
  Row,
  SubTitle,
  Title,
  Details,
  Item,
  Gallery,
  ButtonContainer,
  ItineraryBox,
  DeployContainer,
  Span,
  BoxImage,
  ContentRow,
} from "./styles/ItineraryStyle";
import usePackageData from "../../hooks/packages/usePackageData";
import moment from "moment";
import Form from "./components/Form";
import { useState } from "react";
import { useRef } from "react";
import FlightsMap from "./components/FlightsMap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Mobile from "./components/Mobile";
import { months } from "./utils/months";
import Variables from "./components/Variables";
import useForm from "./hooks/useForm";
import { RouteLink } from "../../styledComponents/globals/styles";
import Slider from "react-slick";
import CarouselModal from "./components/CarouselModal/CarouselModal";
import {
  CombinationContainer,
  InputContainer,
  Label,
} from "./styles/combinations";
import { Select } from "./styles/FormStyle";
import CombinationsSelects from "./components/CombinationsSelects";
import { ContractDetails, IncludeDetails } from "./components/Details/Details";
import FlyerModal from "./components/FlyerModal/FlyerModal";
import RouteBadge from "./components/RouteBadge/RouteBadge";

const Itinerary = () => {
  const [pState, setPstate] = useState();
  const { packageFound, combinations } = usePackageData({ setPstate });
  const [showContainer, setShowContainer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [carouselModal, setCarouselModal] = useState(false);
  const [onModalFlyer, setOnModalFlyer] = useState(false);
  const [price, setPrice] = useState(0);
  const user = useSelector((state) => state.mainSlice.user);
  const deployRef = useRef(null);
  const formRef = useRef(null);
  const { formik, handlePaxes } = useForm({ packageFound, pState });

  const handleModal = () => {
    setCarouselModal(!carouselModal);
  };

  const handleFlyerModal = () => {
    setOnModalFlyer(!onModalFlyer);
  };

  const toggleContainer = () => {
    setShowContainer(true);
    formRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1240); // Define el punto de corte según tus necesidades
    }

    // Agregar el event listener para el evento de redimensionamiento
    window.addEventListener("resize", handleResize);

    // Llamar a la función de manejo de redimensionamiento una vez al cargar la página
    handleResize();

    // Remover el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dragabble: false,
  };
  return (
    <>
      {packageFound ? (
        <>
          <RouteBadge {...{packageFound}} />
          {!isMobile ? (
            <ItineraryBox>
              <Container>
                <Row style={{ maxWidth: "400px", gap: "1.5rem" }}>
                  <ContainerImage
                    onClick={handleModal}
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                  >
                    {packageFound.itemImages?.length === 1 && (
                      <BoxImage>    
                        <img
                          style={{ maxWidth: "100%", height: "100%" }}
                          src={packageFound.itemImages[0]}
                          alt="item"
                        />
                      </BoxImage>
                      
                    )}
                    {
                      !packageFound.itemImages[0] && (
                      <BoxImage>    
                        <img
                          style={{ maxWidth: "100%", height: "100%" }}
                          src={'https://photo.netstorming.net/hotel/34658/wide/1.jpg'}
                          alt="item"
                        />
                      </BoxImage>
                    )
                    }
                    {packageFound?.itemImages?.length > 1 && (
                        <Slider {...settings}>
                          {packageFound?.itemImages?.map((item, index) => {
                            return (
                              <BoxImage
                                 >
                                <img
                                key={index}
                                  style={{ maxWidth: "100%", height: "100%" }}
                                  src={item}
                                  alt={index}
                                />
                              </BoxImage>
                            );
                          })}
                        </Slider>
                    )}
                    {/* </div> */}
                  </ContainerImage>
                  <div style={{ visibility: "hidden" }}>asd</div>
                  <CarouselModal
                    {...{
                      images: packageFound.itemImages,
                      handleModal,
                      carouselModal,
                      settings: { ...settings, slidesToShow: 3 },
                      }}
                  />
                  {
                    packageFound.video && (<div style={{ display:"flex"}}>
                    <ReactPlayer url={packageFound.video} controls />
                  </div>)
                  }
                </Row>
                <Row style={{ width: "100%" }}>
                  <ContentRow
                  >
                    <Content>
                      <Title>
                        <h3>{packageFound.destination}</h3>
                        <span>{packageFound.subtitle}</span>
                      </Title>
                      <Item style={{ flexDirection: "column", gap: "2rem" }}>
                        {price > 0 ? (
                          <Span>Costo: $ {price}</Span>
                        ) : user?.status ? (
                          "Esta combinación no tiene precio"
                        ) : (
                          ""
                        )}
                      </Item>
                    </Content>
                    <ButtonContainer pdf={packageFound?.pdf}>
                      <a
                        href={packageFound?.pdf}
                        style={{ backgroundColor: "#d9d9d9" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        VER ITINERARIO
                      </a>
                      {user.status && packageFound.flyers?.length > 0 && (
                        <button onClick={handleFlyerModal}>FLYERS</button>
                      )}
                        <button disabled={!user.status} 
                          style={{background: !user.status ? "#d9d9d9" : "rgba(236, 104, 28, 1)", cursor: !user.status ? "default" : "pointer"}} 
                         onClick={toggleContainer}>RESERVAR</button>
                    </ButtonContainer>
                  </ContentRow>
                  {combinations && combinations.length > 0 ? (
                    <CombinationsSelects
                      combinations={combinations}
                      setPrice={setPrice}
                    />
                  ) : (
                    ""
                  )}

                  <Details style={{ flexWrap:"wrap" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {packageFound.items?.filter((item) => item !== "")
                        .length >= 1 && <Span>Incluido en el precio:</Span>}
                      <ul>
                        {packageFound.items
                          ?.filter((item) => item !== "")
                          .map((item, index) => {
                            return <li>{item}</li>;
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {packageFound.excluded?.filter((item) => item !== "")
                        .length >= 1 && <Span>No incluído en el precio:</Span>}
                      <ul>
                        {packageFound.excluded?.map((item, index) => {
                          return <li>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </Details>
                  <div style={{ margin: "1rem 0 0.5rem 0" }}>
                    {packageFound.contract?.filter((item) => item !== "")
                      .length >= 1 && <Span>{packageFound?.contractTitle ?  packageFound.contractTitle : 'Condiciones de contratación'}</Span>}
                    <ul
                      style={{
                        margin: "10px 0 0 0",
                        padding: 0,
                        listStyle: "none",
                      }}
                    >
                      {packageFound?.contract?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  <Variables
                    {...{
                      selects: packageFound?.selects,
                      texts: packageFound?.texts,
                      selectTitle: packageFound?.selectTitle,
                      formik,
                    }}
                  />

                  <div
                    ref={formRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3rem",
                      margin: "4rem 0",
                    }}
                  >
                    {packageFound?.flights?.map((flight, index) => (
                      <FlightsMap flight={flight} key={index} />
                    ))}
                  </div>
                </Row>
              </Container>
              <DeployContainer
                deploy={deployRef}
                ref={deployRef}
                show={showContainer}
              >
                <Form {...{ packageFound, formik, handlePaxes }} />
              </DeployContainer>
            </ItineraryBox>
          ) : (
            <Mobile
              {...{
                packageFound,
                formik,
                handlePaxes,
                combinations,
                setPrice,
                user,
                price,
                handleFlyerModal,
              }}
            />
          )}
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
      <FlyerModal
        {...{ handleFlyerModal, onModalFlyer, flyers: packageFound?.flyers }}
      />
    </>
  );
};

export default Itinerary;
