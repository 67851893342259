import React from "react";
import {links} from "../../data";

import styled from "styled-components";

export const CardContainer = styled.div`
  background-color: #f0f0f0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 29px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.38);
  gap: 20px;
`;
export const Image = styled.div`
  width: 318px;
  height: 201px;
  
  img {
      border-radius: 13px;
    width: 100%;
    max-height: 100%;
  }
`;
export const Title = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 15px;
    p, h3{
        margin: 0;
        text-align: center;
    }

`;
export const Content = styled.div`
  border-radius: 15px;
  padding: 10px 0;
  width: 318px;

  a {
    /* width: 100%; */
    text-decoration: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 8px 50px;
    font-weight: bold;
    border: none;
    background: #ec681c;
    border-radius: 29px;
    color: white;
    cursor: pointer;
  }
`;

const ServiceCard = ({image, title, subtitle, url}) => {
  return (
    <CardContainer>
      <Image>
        <img src={image} alt={title} />
      </Image>
      <Title>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </Title>
      <Content>
      <a href={url} target="_blank" rel="noopener noreferrer">VER MÁS</a>
      </Content>
    </CardContainer>
  );
};

export default ServiceCard;
