import {
  InputContainer,
} from "../styles/FormStyle";
import InputItem from "./InputItem";

const FormSlice = ({ formik, index }) => {
  return (
    <>
      <InputContainer>
        <InputItem formik={formik} index={index} name={"name"} placeholder={"Nombre"} />
        <InputItem formik={formik} index={index} name={"lastname"} placeholder={"Apellido"} />
        <InputItem formik={formik} index={index} name={"birthDate"} placeholder={"Fecha de nacimiento"} />
      </InputContainer>
      <InputContainer>
      <InputItem formik={formik} index={index} name={"address"} placeholder={"Domicilio"} />
      <InputItem formik={formik} index={index} name={"email"} placeholder={"Email"} />
      <InputItem formik={formik} index={index} name={"phone"} placeholder={"Celular"} />
      </InputContainer>
      <InputContainer>
      <InputItem formik={formik} index={index} name={"passport"} placeholder={"Pasaporte"} />
      <InputItem formik={formik} index={index} name={"passportExpireDate"} placeholder={"Fecha de vencimiento del pasaporte"} />
      </InputContainer>
    </>
  );
};

export default FormSlice;
