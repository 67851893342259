import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RoomFilter from "./RoomFilter";
import { Select } from "./SearchStyle";

const Container = styled.div`
  
  background: #e5e4e4;
  width: 1227px;
  height: 20rem;
  border-radius: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  @media screen and (max-width: 878px) {
    display: grid;
    gap: 15px;
    padding: 0 0 30px 0;
  }
  
  @media screen and (max-width: 1022px) {
    width: 100%;
    grid-template-columns: 1fr;
    height: auto;
    padding: 0 0 30px 0;

  }

`;
const Box = styled.div`
  width: 100%;
  display: flex;
  gap: 5.7rem;
  user-select: none;

  @media screen and (max-width: 1022px){
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1.5rem;
    }
  
  @media screen and (max-width: 1589px) {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1000px) {
    
  }
  @media screen and (max-width: 540px) {
    padding: 0;
  }
`;
const RoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1022px) {
  }

  @media screen and (max-width: 878px) {

  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 120px;
  margin-left: 7.5rem;
  font-size: 14px;
  gap: 5px;

  @media screen and (max-width: 1022px){
      margin: 0;

    }
`;

const RoomMap = ({ form }) => {
  const [roomNumber, setRoomNumber] = useState(1);

  const defaultRoomValues = {
    type: "sgl",
    adults: "1",
    childs: "0",
    infants: "0",
  };

  const handleRoom = (event) => {
    const selectedRoom = parseInt(event.target.value);
    setRoomNumber(selectedRoom);

    for (let i = selectedRoom + 1; i <= 4; i++) {
      form.setFieldValue(`room${i}.type`, "");
      form.setFieldValue(`room${i}.adults`, "");
      form.setFieldValue(`room${i}.childs`, "");
      form.setFieldValue(`room${i}.infants`, "");
    }
  };

  const handleTypes = (event) => {
    form.setFieldValue(event.target.name, event.target.value)
  };

  
  return (
    <>
      <Box>
        <SelectContainer>
          <label style={{fontSize: "0.688rem"}} htmlFor="">Habitaciones</label>
          <Select onChange={handleRoom}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Select>
        </SelectContainer>
        <Container>
          <RoomContainer>
            <RoomFilter
              num={1}
              enabled={roomNumber}
              handleChange={form.handleChange}
              room={form.values.room1}
            />
          </RoomContainer>
          <RoomContainer>
            <RoomFilter room={form.values.room2} num={2} enabled={roomNumber} handleChange={form.handleChange}/>
          </RoomContainer>
          <RoomContainer>
            <RoomFilter room={form.values.room3} form num={3} enabled={roomNumber} handleChange={form.handleChange}/>
          </RoomContainer>
          <RoomContainer>
            <RoomFilter room={form.values.room4} num={4} enabled={roomNumber} handleChange={form.handleChange}/>
          </RoomContainer>
        </Container>
      </Box>
    </>
  );
};

export default RoomMap;
