import React, {useState} from "react"
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
// import { useEffect, useState } from "react";
import styled from "styled-components";
import iso from "./iso";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/main.slice";
import { getPackageByRegion } from "../../../services/packages.service";

const RadioGroup = styled.div`
  /* padding: 0 1rem; */
  width: 250px;

  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    li{
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  @media screen and (max-width: 876px) {
     width: auto;
    ul{
      flex-direction: row;
      display: grid;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
    }
  }
`;
export const Span = styled.span`
  color: #595959;
  font-weight: 600;

`;

const StyledRadioInput = styled.input`
 
    width: 23px; 
    height: 23px; 
    border: 2px solid #d9d9d999;
    appearance: none;
    background-color: #d9d9d999;
    border-radius: 3px;
    cursor: pointer;

    &:checked{
      background-color: rgba(236, 104, 28, 1);
      border: 1px solid whitesmoke;
    }
  
`;

const ContainerChart = styled.div`
  max-width: 100%;
  height: 400px;
  display: flex;
  margin:  1rem var(--margin-large);
  position: relative;

  @media screen and (max-width: 876px) {
      flex-direction: column;
      margin: 1rem 1.5rem;
  }
`

const MapChart = ({setPackages,setIsFetched, formik, selectedOption, setSelectedOption, scrollToRef}) => {
  const dispatch = useDispatch();

  const handleOptionChange = async(e) => {
    
    setSelectedOption(e.target.value);

    try{
      dispatch(setLoading(true));
      formik.resetForm()
     const data = await getPackageByRegion(e.target.value)
     setPackages(data);
     setIsFetched(true);
     if(data.length >= 1) scrollToRef();
      dispatch(setLoading(false));
    }
    catch(err){
      dispatch(setLoading(false));      
    }
  }

  return (
    <ContainerChart
      
    >
      <Span style={{ position: "absolute", top: "-6%" }}>Región</Span>
      <RadioGroup>
        <ul>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="NA"
              checked={selectedOption === "NA"}
              onChange={handleOptionChange}
            />{" "}
            América del norte
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="CA"
              checked={selectedOption === "CA"}
              onChange={handleOptionChange}
            />{" "}
            Centroamérica
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="SA"
              checked={selectedOption === "SA"}
              onChange={handleOptionChange}
            />{" "}
            América del sur
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="EU"
              checked={selectedOption === "EU"}
              onChange={handleOptionChange}
            />{" "}
            Europa
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="MO"
              checked={selectedOption === "MO"}
              onChange={handleOptionChange}
            />
            Medio Oriente
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="OR"
              checked={selectedOption === "OR"}
              onChange={handleOptionChange}
            />{" "}
            Oriente
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="AF"
              checked={selectedOption === "AF"}
              onChange={handleOptionChange}
            />{" "}
            África
          </li>
          <li>
            <StyledRadioInput
              type="radio"
              name="options"
              value="IP"
              checked={selectedOption === "IP"}
              onChange={handleOptionChange}
            />{" "}
            Islas del Pacifico
          </li>
        </ul>
        {/* <Autocomplete 
        disablePortal
        key={content?.countries?.length}
        onChange={handleOptionChange}
        id="combo-box-demo"
        options={suggestions}
        sx={{width: 200, marginTop: "0.5rem"}}
        renderInput={(params) => <TextField {...params} label="Seleccionar país" />}
        /> */}
      </RadioGroup>
      <VectorMap
        zoomOnScroll={false}
        zoomStep={false}
        zoomButtons={false}
        map={worldMill}
        key={selectedOption}
        containerStyle={{
          width: "100%",
          height: "600px",
        }}
        backgroundColor="white"
        regionStyle={{
          initial: {
            fill: "rgba(167, 169, 172, 1)",
            "fill-opacity": 1,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 1,
          },
          selected: {
            fill: "rgba(236, 104, 29, 1)",
          },
        }}
        selectedRegions={iso[selectedOption]}
        // onRegionSelected={handleCountries}
        // regionsSelectable
        // regionsSelectableGroup
      />
      <script>
     { setTimeout(()=> { Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { el.style.display = 'none' }); },100)}
      </script>
    </ContainerChart>
  );
};

export default MapChart;
