

const ILocation = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Capa_1"
      x={0}
      y={0}
      style={{
        enableBackground: "new 0 0 50 50",
      }}
      viewBox="10 10 30 30"
      {...props}
    >
      <style>{".st0{fill:#888}"}</style>
      <path
        d="M30.2 21.9c-.9-1.9-3-3.2-5.2-3.2-.6 0-2.7.1-4.2 1.8-1 1.1-1.6 2.6-1.5 4.2 0 1.2.2 2.4 1.4 3.9l3 3.8c.3.4.8.7 1.3.7s1-.2 1.3-.6c1-1.2 2-2.5 3-3.7.3-.5.9-1.1 1.2-2.2 0 0 .3-.8.3-1.8 0-1.7-.6-2.9-.6-2.9zm-.7 4.2c-.3.9-.7 1.5-1 1.9-.9 1.2-1.9 2.5-3 3.7-.1.2-.3.3-.5.3s-.4-.1-.5-.3l-3-3.8c-1.1-1.3-1.2-2.2-1.2-3.3 0-1.4.4-2.6 1.2-3.5 1.3-1.4 3-1.5 3.5-1.5 1.8 0 3.5 1.1 4.3 2.6 0 0 .5 1 .5 2.4-.1.8-.2 1.4-.3 1.5z"
        className="st0"
      />
      <path
        d="M25.1 22c-1.3 0-2.3 1-2.3 2.3s1.1 2.3 2.3 2.3c1.3 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3zm0 3.7c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.5 1.3-1.3 1.3z"
        className="st0"
      />
    </svg>
  )
  export default ILocation;