import * as Yup from 'yup';

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      `La contraseña debe incluir al menos una letra minúscula 
      una letra mayúscula, un número y un carácter especial`
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Confirma la contraseña'),
});
