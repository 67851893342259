import MapChart, { Span } from "./MapChart/MapChart";
import PackageCard from "../InterCard/InterCard";
import styled from "styled-components";
import { useState } from "react";
import { Column } from "../../styledComponents/globals/styles";
import InputSuggest from "./AutoSuggest/InputSuggest";
import { useFormik } from "formik";
import { getPackagesByMap } from "../../services/maps.service";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/main.slice";
import { useRef } from "react";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4.3rem;
  margin: 5rem var(--margin-large);
  justify-items: center;

  @media screen and (max-width: 1251px) {
    margin: 5rem 0;
      grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 876px) {
      margin: 5rem 0;
      grid-template-columns: 1fr;
  }

`;

const Search = styled.div`
  margin: 1rem var(--margin-large) 9.3rem var(--margin-large);
  display: flex;
  gap: 3.2rem;

  button {
    background-color: rgb(236, 104, 28);
    color: white;
    padding: 0.6rem 5rem;
    border: medium;
    border-radius: 15px;
    font-weight: 600;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
  }

  @media screen and (max-width: 876px) {
      flex-direction: column;
      margin: 5rem 1.5rem;
  }
`;

export const Results = styled(Column)`

.results{
    margin-right: auto;
    margin-left: var(--margin-large);
  }
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 876px) {
  
    .results{
    margin: 0 auto;
    text-align: center;
    width: 100%;
    }
}
`

const PackageSection = () => {
  const [packages, setPackages] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const resultRef = useRef(null)

  const dispatch = useDispatch()

  const scrollToRef = () => {
    resultRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const formik = useFormik({
    initialValues: {
      country: "",
      code: "",
    },
    onSubmit: async(values) => {

      if(values.country === "" || values.code === "") return;
      try{
        dispatch(setLoading(true));
        setSelectedOption("")
        const data = await getPackagesByMap(values.code);
        setPackages(data)
        setIsFetched(true);
        formik.resetForm();
        if(data.length >= 1) scrollToRef();
        dispatch(setLoading(false));
      }
      catch(err){
        dispatch(setLoading(false));
        setIsFetched(true);
        console.log(err);
      }
    }
  });

  return (
    <Column style={{ marginTop: "5.6rem"}}>
      <Search>
        {/* <Input type="text" placeholder="Ingresar país" /> */}
        <InputSuggest {... {formik}} />
        <button onClick={formik.handleSubmit}>Buscar</button>
      </Search>
      <MapChart {...{ packages, setPackages, setIsFetched, formik, selectedOption, setSelectedOption, scrollToRef }} />
      <Results>
        {
            isFetched && <Span className="results">{packages.length >= 1 ? `Resultados (${packages.length})` : "No hay resultados para esta búsqueda"}</Span>
        }
      <Grid ref={resultRef}>
        {packages?.map((item) => (
            <PackageCard {...item} subtitle={""} />
            ))}
      </Grid>
            </Results>
    </Column>
  );
};

export default PackageSection;
