import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  agency: Yup.string().required('El campo Agencia es obligatorio'),
  legacy: Yup.string().required('El campo Legacy es obligatorio'),
  address: Yup.string().when("country", {
    is: (country) => ['ARGENTINA'].includes(country),
    then: () => Yup.string().required("El campo direccion es obligatorio")
  }),
  cuit: Yup.string().when("country", {
    is: (country) => ['ARGENTINA'].includes(country),
    then: () => Yup.string().required("El campo CUIT es obligatorio")
  }),
  social: Yup.string().when("country", {
    is: (country) => ['ARGENTINA'].includes(country),
    then: () => Yup.string().required("El campo Razon Social es obligatorio")
  }),
  ivaCondition: Yup.string().when("country", {
    is: (country) => ['ARGENTINA'].includes(country),
    then: () => Yup.string().required("El campo direccion es obligatorio")
  }),
  province: Yup.string().when("country", {
    is: (country) => ['ARGENTINA'].includes(country),
    then: () => Yup.string().required("El campo provincia es obligatorio")
  }),
  email: Yup.string().email('Ingrese un correo electrónico válido').required('El campo Correo Electrónico es obligatorio'),
  password: Yup.string()
        .required('El campo Contraseña es obligatorio')
        .min(6, 'La contraseña debe tener al menos 6 caracteres'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .required('El campo Confirmar Contraseña es obligatorio'),
  description: Yup.string().required('El campo Descripción es obligatorio'),
  promotionEmail: Yup.string().email('Ingrese un correo electrónico válido para Promoción'),
  
  phone: Yup.string()
  .required("El número de teléfono es obligatorio")
    .matches(/^\d+$/, "El número de teléfono solo puede contener dígitos")
    .min(10, "El número de teléfono debe tener al menos 10 dígitos")
    .max(15, "El número de teléfono no puede tener más de 15 dígitos"),

  username: Yup.string().required('El campo usuario es obligatorio'),

});

export default validationSchema