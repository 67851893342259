import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchHotels } from "../services/hotels.service";
import { verify } from "../services/auth/login.service";
import { fetchPackages } from "../services/packages.service";
import { fetchCarouselItems } from "../services/images.service";

const initialState = {
  isLoading: false,
  isLogged: false,
  user: {},
  carousel: [],
  hotels: [],
  packages: [],
};

export const getHotels = createAsyncThunk(
  "mainSlice/getHotels",
  async (data) => {
    try {
      const hotels = await fetchHotels(data);
      return hotels;
    } catch (err) {
      return;
    }
  }
);

export const getPackages = createAsyncThunk(
  "mainSlice/getPackages",
  async (type) => {
    try {
        const user = await verify()
        const packages = await fetchPackages(type);
      return packages;
    } catch (err) {
      return [];
    }
  }
);

export const getCarouselItems = createAsyncThunk(
  "mainSlice/getCarouselItems",
  async () => {
    try {
      const data = await fetchCarouselItems();
      return data;
    } catch (err) {
      return [];
    }
  }
);

export const setUser = createAsyncThunk("mainSlice/setUser", async () => {
  try {
    const user = await verify();
    if (!user.id) throw new Error("Error de autenticación");
    return user;
  } catch (err) {
    return {};
  }
});

const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    resetHotels(state, action) {
      state.hotels = [];
    },
    updateHotels: (state, action) => {
      state.hotels = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    addUser(state, action) {
      state.user = action.payload;
      state.isLogged = true;
    },
    updateUserHotels(state, action) {
      state.user = { ...state.user, hotelsFav: action.payload };
    },
    removeUserHotel(state, action) {
      state.user = { ...state.user, hotelsFav: action.payload };
    },
    removeUser(state) {
      state.user = {};
    },
    addPackage(state, action) {
      if (
        !state.packages.some(
          (packageFound) => packageFound._id === action.payload._id
        )
      ) {
        state.packages = [...state.packages, action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHotels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHotels.fulfilled, (state, action) => {
        state.hotels = action.payload;
        state.isLoading = false;
      })
      .addCase(getHotels.rejected, (state, action) => {
        state.isLoading = false;
        console.log(action.error);
      })
      .addCase(setUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLogged = true;
        state.isLoading = false;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        console.log(action.error);
      })
      .addCase(getPackages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packages = action.payload;
        state.isLoading = false;
      })
      .addCase(getPackages.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCarouselItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCarouselItems.fulfilled, (state, action) => {
        state.carousel = action.payload
        state.isLoading = false;
      })
      .addCase(getCarouselItems.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetHotels,
  setLoading,
  addUser,
  updateHotels,
  removeUser,
  addPackage,
  updateUserHotels,
} = mainSlice.actions;

export default mainSlice.reducer;
