import { useFormik } from "formik";
import { useState } from "react";
import Autosuggest from "react-autosuggest";
import { Input } from "../../NewSearch/SearchStyle";

const InputSuggest = ({formik}) => {
  
    const [suggestions, setSuggestions] = useState([]);
    const [suggestCode, setSuggestCode] = useState("");

    const getSuggestionValue = (suggestion) => suggestion.city;

    const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
  
    const onSuggestionSelected = (event, { suggestion }) => {
      event.target.value = suggestion.name;
      formik.setFieldValue("country", suggestion.name);
      formik.setFieldValue("code", suggestion.code);
      setSuggestCode(suggestion.code);
    //   setCityName(suggestion.name);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        fetch(
          `${
            process.env.REACT_APP_API_URL
          }/code_countries?country=${value.trim()}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSuggestions(data);
          });
      };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        formik.setFieldValue(name, value);
      };
    
      const onSuggestionsClearRequested = () => {
        setSuggestions([]);
      };
    
      const inputProps = {
        placeholder: "Ingresar país",
        value: formik.values.country,
        onChange: handleInputChange,
        name: "country",
        
        // onKeyDown: onKeyDown,
        onblur: (event) => {
          // set cursor to beginning of input
          event.target.setSelectionRange(0, 0);
        //   form.handleBlur(event);
        },
      };

    return (<div style={{width:"100%"}}>
        <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              // inputProps={{st}}
              onSuggestionSelected={onSuggestionSelected}
              />
              </div>
     );
}
 
export default InputSuggest;