import { useState, useEffect, useRef } from "react";
import IMenu from "../../assets/icons/menus/IMenu";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import UserIcon from "./usericon.png";
import {
  Nav,
  Logo,
  NavList,
  NavItem,
  BtnMenu,
  DropMenu,
  DropMenuItem,
  Container,
  ProfileMenu,
  MenuItems,
  MenuItem,
  NavMenu,
} from "./NavBarStyle";
import { scrollTo } from "../../utils/scroll";
import { FaSearch } from "react-icons/fa";
import { links } from "../../data";
import { Link } from "react-router-dom";
import { useLogout } from "../../hooks/auth/user";
import logo from "./logo.png";
import LogoSvg from "./LogoSvg";

const NavBar = ({ open, setOpen, setOpenLogin }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { logout, user, navigate } = useLogout();
  const refMenu = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (refMenu.current && !refMenu.current.contains(event.target)) {
  //       setShowMenu(false);
  //     } else {
  //       setShowMenu(true);
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <>
      <Nav isScrolled={isScrolled}>
        <Container isScrolled={isScrolled}>
          <span className="hidden_span"></span>
          <Logo
            style={{ width: "170px", height: "auto" }}
            onClick={() => navigate("/")}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={logo}
              alt="logo"
            />

            {/* <LogoSvg width={"150px"} height={"60px"} /> */}
          </Logo>
          <NavList>
            <NavItem>
              <Link to={"/paquetes"}><FaSearch size={20} /></Link>
              <>
              <NavMenu>
                  <NavItem>
                    <Link to={'/paquetes'}>Por Paquetes</Link>
                  </NavItem>
                  <NavItem>
                    <Link to={'/flyers'}>Por Flyers</Link>
                  </NavItem>  
              </NavMenu>
                </>
            </NavItem>
            <NavItem>
              <Link to={"/internacionales"}>INTERNACIONALES</Link>
              <>
                <NavMenu>
                  <NavItem>
                    <Link to={"/internacionales/paquetes de playa"}>
                      Paquetes de playa
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/internacionales/montañas"}>
                      Montañas
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/internacionales/exoticos"}>
                      Exóticos
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/internacionales/regulares"}>Regulares</Link>
                  </NavItem>
                  <NavItem>
                    <div className="groups">
                      <Link to={"/internacionales/grupales"}>Grupales</Link>
                      <IoIosArrowDroprightCircle />
                      <div className="groups_item">
                        <Link to={"/internacionales/grupales/europa"}>
                          Europa
                        </Link>
                        <Link to={"/internacionales/grupales/asia"}>Asia</Link>
                        <Link to={"/internacionales/grupales/medio oriente"}>
                          Medio oriente
                        </Link>
                        <Link to={"/internacionales/grupales/america"}>
                          América
                        </Link>
                        <Link to={"/internacionales/grupales/exoticos"}>
                          Exóticos
                        </Link>
                      </div>
                    </div>
                  </NavItem>
                </NavMenu>
              </>
            </NavItem>
            <NavItem>
              <Link to={"/nacionales"}>NACIONALES</Link>
              <>
                <NavMenu>
                  <NavItem>
                    <Link to={"/nacionales/regulares"}>Regulares</Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/nacionales/grupales"}>Grupales</Link>
                  </NavItem>
                </NavMenu>
              </>
            </NavItem>
            <NavItem onClick={() => scrollTo("footer")}>CONTACTO</NavItem>
            <NavItem>
              <a
                href="https://gpahorros.com.ar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PLANES DE AHORRO
              </a>
            </NavItem>
            <NavItem className="profile">
              <ProfileMenu>
                <img style={{ width: "30px" }} src={UserIcon} alt="icon" />
                <MenuItems>
                  {!user.id ? (
                    <>
                      <MenuItem>
                        <Link onClick={setOpenLogin}>Ingresar</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link onClick={setOpen}>Registrarse</Link>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Link to={"/profile"}>Perfil</Link>
                      </MenuItem>
                      {user.roles.some((role) => Object.values(role).includes("admin")) && (
                        <MenuItem>
                          <a
                            href="https://admin.interlude.com.ar"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Admin
                          </a>
                        </MenuItem>
                      )}
                      <MenuItem>
                        <Link onClick={logout}>Cerrar sesión</Link>
                      </MenuItem>
                    </>
                  )}
                </MenuItems>
              </ProfileMenu>
            </NavItem>
          </NavList>
          <BtnMenu onClick={toggleMenu}>
            <IMenu width={"50px"} height={"50px"} />
          </BtnMenu>
        </Container>
        {showMenu && (
          <DropMenu ref={refMenu} showMenu={showMenu} className="mobile-menu">
            <DropMenuItem>
              <Link onClick={toggleMenu} to={"/internacionales"}>
                Internacionales
              </Link>
            </DropMenuItem>
            <DropMenuItem>
              <Link onClick={toggleMenu} to={"/nacionales"}>
                Nacionales
              </Link>
            </DropMenuItem>
            <DropMenuItem>
              <Link
                onClick={() => {
                  scrollTo("footer");
                  toggleMenu();
                }}
              >
                Contacto
              </Link>
            </DropMenuItem>
            <DropMenuItem>
              <a
                onClick={toggleMenu}
                href="https://gpahorros.com.ar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Planes de ahorro
              </a>
            </DropMenuItem>
            {/* <DropMenuItem>
              <Link onClick={toggleMenu} to={"/profile"}>Perfil</Link>
            </DropMenuItem> */}
            {!user.id ? (
              <>
                <DropMenuItem
                  onClick={() => {
                    toggleMenu();
                    setOpenLogin(true);
                  }}
                >
                  <Link>Ingresar</Link>
                </DropMenuItem>
                <DropMenuItem>
                  <Link
                    onClick={() => {
                      toggleMenu();
                      setOpen(true);
                    }}
                  >
                    Registrarse
                  </Link>
                </DropMenuItem>
              </>
            ) : (
              <>
                <DropMenuItem>
                  <Link onClick={toggleMenu} to={"/profile"}>
                    Perfil
                  </Link>
                </DropMenuItem>
                <DropMenuItem>
                  <Link
                    onClick={() => {
                      toggleMenu();
                      logout();
                    }}
                  >
                    Cerrar sesión
                  </Link>
                </DropMenuItem>
              </>
            )}
          </DropMenu>
        )}
      </Nav>
    </>
  );
};

export default NavBar;
