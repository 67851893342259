import axios from "axios";
import { commonAlert } from "../utils/alerts";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchHotels = async (body) => {
  try {
    const data = await axios.post(`${API_URL}/netstorming/getHotels`, body, {
      timeout: 3600000,
    });
    axios.defaults.headers.common["Unique-Id"] = data.data.uuid;
    return data.data;
  } catch (e) {
    console.log(e);
    if (e.response.status === 500) {
      commonAlert("Error en el servidor", "<b>intente de nuevo mas tarde</b>", "error")
    }

    if (e.response.status === 401) {
      commonAlert(
        "Error de autorización",
        "Su cuenta no se encuentra autorizada para realizar esta acción",
        "error"
      );
    }
    if (e.response.status === 400) {
      commonAlert(
        "Búsqueda sin resultados",
        "Lo sentimos, no se encontraron resultados que coincidan con tu búsqueda. Por favor, intenta con diferentes criterios de búsqueda o verifica tus filtros.",
        "error"
      );
    }
    return [];
  }
};

export const getDetails = async (body) => {
  try {
    const data = await axios.post(`${API_URL}/netstorming/hotelDetail`, body);
    return data.data;
  } catch (e) {
    return e;
  }
};

export const createBooking = async (body) => {
  try {
    const data = await axios.post(`${API_URL}/netstorming/bookHotel`, body);
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const evaluateBooking = async (body) => {
  try {
    const data = await axios.post(
      `${API_URL}/netstorming/evaluateSearch`,
      body
    );
    return data.data;
  } catch (e) {
    return;
  }
};

export const getBasis = async (agreement, hotelId, availabilityId) => {
  try {
    const search = JSON.parse(localStorage.getItem("search"));
    let codes = [];
    let mealCode = [];
    let agreementArr = agreement;
    if (!Array.isArray(agreement)) {
      agreementArr = [agreement];
    }
    let agreementCode = [];
    agreementArr.forEach((item) => {
      mealCode.push(item.meal_basis);
      codes.push(item.room_basis);
      agreementCode.push(item.id);
    });
    const dataAgreements = await axios.post(
      `${API_URL}/netstorming/getAgreements?${localStorage.getItem("filters")}`,
      {
        hotelCode: hotelId,
        checkIn: search.checkIn,
        checkOut: search.checkOut,
        rooms: [{ sgl: search.sgl }, { dbl: search.dbl }, { twn: search.twin }],
      }
    );
    console.log(dataAgreements);
    // const data = await axios.get(`${API_URL}/netstorming/basis?codes=${codes.join(",")}&mealCode=${mealCode.join(",")}`)
    // const deadline = await axios.post(`${API_URL}/netstorming/deadline` , {availabilityId, hotelId, agreementCode})
    return dataAgreements.data;
  } catch (err) {
    console.log(err);
  }
};

export const hotelFilter = async (values) => {
  try {
    const data = await axios.get(`${API_URL}/hotels/filters?${values}`);
    if (Array.isArray(data.data.hotels)) return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getPagination = async (page, filtered) => {
  try {
    let query = filtered ? "true" : "false";
    const data = await axios.get(
      `${API_URL}/hotels/pagination?page=${page + 1}&filtered=${query}`
    );
    return data.data;
  } catch (err) {
    console.log(err);
};
}

export const getDeadlines = async (agreements, hotelCode, searchNumber) => {
  try {
    const filteredAgreements = agreements
      .filter((item) => item.deadline === null)
      .map((item) => {
        return { id: item.id };
      });
    const data = await axios.post(`${API_URL}/netstorming/getDeadline`, {
      hotelCode,
      searchNumber,
      agreementsCodes: filteredAgreements,
    });
    return data.data;
  } catch (err) {
    console.log(err);
  }
};
