import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { addPackage, setLoading } from '../../redux/main.slice';
import { getPackageById } from '../../services/packages.service';
import { useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

const usePackageData = ({setPstate}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const packages = useSelector((state) => state.mainSlice.packages);
  const [packageFound, setPackageFound] = useState(null); // Establecer inicialmente como null
  const [combinations, setCombinations] = useState(null); // Establecer inicialmente como null


  useEffect(() => {
    if (!hasFetched || !packages.some((packageItem) => packageItem?._id === id)) {
      dispatch(setLoading(true))
      getPackageById(id)
        .then((packageData) => {
          dispatch(addPackage(packageData));
          setPstate(packageData)
          setPackageFound(packageData)
          //! CAMBIAR axios
          if(packageData) axios.get(API_URL + `/sheets?package_id=${packageData._id}`).then((resp) => setCombinations(resp.data))
          setHasFetched(true);
          dispatch(setLoading(false))
        })
        .catch((error) => {
          console.log(error, 'error')
          dispatch(setLoading(false))
          navigate("/");
        }).finally(() => dispatch(setLoading(false)))
    }
  }, [id, hasFetched, packages, dispatch, navigate]);

  useEffect(() => {
    // Actualizar packageFound después de que los datos se carguen correctamente
    const foundPackage = packages.find((item) => item?._id === id);
    if (foundPackage) {
      setPackageFound(foundPackage);
    }
  }, [id, packages]);

  return { packageFound, navigate, combinations };
};

export default usePackageData;
