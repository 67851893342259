import { useFormik } from "formik";
import { Modal } from "@mui/material";
import {
  Button,
  Container,
  Form,
  Input,
  Row,
  TextArea,
  Title,
  InputContainer,
  ErrorMesage,
  Select
} from "./RegisterStyle";
import validationSchema from "./schema";
import { register } from "../../../services/auth/login.service";
import { commonAlert } from "../../../utils/alerts";
import { getDbCountry } from "../../../services/db.services";
import { useEffect, useState } from "react";
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDiv = styled.div`
  animation: ${fadeIn} 1s ease-in-out;
  input {
    margin: 5px;
  }
  select {
    margin-top: 5px;
  }
`;

const Register = ({ open, setOpen }) => {
  const [countries, setCountries] = useState([])
  


  const handleOpen = () => {
    setOpen(!open);
    formik.resetForm();
  };



  const provinces = [
    "Buenos Aires",
    "Catamarca",
    "Chaco",
    "Chubut",
    "Córdoba",
    "Corrientes",
    "Entre Ríos",
    "Formosa",
    "Jujuy",
    "La Pampa",
    "La Rioja",
    "Mendoza",
    "Misiones",
    "Neuquén",
    "Río Negro",
    "Salta",
    "San Juan",
    "San Luis",
    "Santa Cruz",
    "Santa Fe",
    "Santiago del Estero",
    "Tierra del Fuego",
    "Tucumán",
    "Otro"
]
  const formik = useFormik({
    initialValues: {
      agency: "",
      legacy: "",
      address: "",
      cuit: "",
      social: "",
      ivaCondition: "",
      email: "",
      password: "",
      confirmPassword: "",
      description: "",
      promotionEmail: "",
      username: "",
      province: "",
      country:"",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await register(values);
        setOpen(false);
        formik.resetForm();
        commonAlert("Usuario Registrado!", "", "success");
      } catch (err) {
        commonAlert("Error al registrar", err, "error");
      }
    },
  });

  useEffect(() => {
    let data = async () => {
      let request = await getDbCountry()

      setCountries(request.sort())
    }
    data()
  },[])

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:"flex", justifyContent:"center", alignItems: "center"}}
    >
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          <Title>
            <div style={{ position: "relative" }}>
              <i
                onClick={handleOpen}
                style={{
                  position: "absolute",
                  right: "-2%",
                  bottom: "-15%",
                  userSelect: "none",
                  cursor: "pointer"
                }}
              >
                X
              </i>
            </div>
            <h4>Registro de cuenta</h4>
            <span>Ingresá los datos de tu agencia para registrarte</span>
          </Title>
          <Row>
            <InputContainer>
              <Input
                placeholder="Nombre de la agencia"
                name="agency"
                value={formik.values.agency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.agency && formik.touched.agency && (
                <ErrorMesage>{formik.errors.agency}</ErrorMesage>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="Nombre de usuario"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.username && formik.touched.username && (
                <ErrorMesage>{formik.errors.username}</ErrorMesage>
              )}
            </InputContainer>
            <InputContainer>
              {formik.values.country !== 'ARGENTINA' ? 
              (
              <Input
                placeholder="Número de inscripcion impositiva"
                name="legacy"
                value={formik.values.legacy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> 
              ) :
              <Input
                placeholder="Número de legajo"
                name="legacy"
                value={formik.values.legacy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              }
              {formik.errors.legacy && formik.touched.legacy && (
                <ErrorMesage>{formik.errors.legacy}</ErrorMesage>
              )}
            </InputContainer>
           
          </Row>
            <InputContainer>
                <Select
                placeholder="Pais"
                onChange={formik.handleChange}
                id="country"
                value={formik.values.country}
                name="country"
                onBlur={formik.handleBlur}
                >
                <option selected hidden>Seleccione una opcion</option>
                  {countries.map((el) => {
                    return (
                      <option value={el}>{el}</option>
                    )
                  })}

                </Select>
                
            </InputContainer>
          
            {
              formik.values.country.toLowerCase() !== 'argentina' ? (<></>) : 
            <FadeInDiv>  
              <Row>
                <InputContainer>
                  <Input
                    placeholder="Dirección"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.address && formik.touched.address && (
                    <ErrorMesage>{formik.errors.address}</ErrorMesage>
                  )}
                </InputContainer>
                <InputContainer>
                  <Select
                    placeholder="Provincia"
                    name="province"
                    value={formik.values.province}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    
                  >
                  <option value="" disabled>Provincia</option>
                    {provinces.map((item, index) => <option key={index} value={item}>{item}</option>)}
                  </Select>
                  {formik.errors.province && formik.touched.province && (
                    <ErrorMesage>{formik.errors.province}</ErrorMesage>
                  )}
                </InputContainer>
                <InputContainer>
                  <Input
                    placeholder="Cuit"
                    name="cuit"
                    value={formik.values.cuit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.cuit && formik.touched.cuit && (
                    <ErrorMesage>{formik.errors.cuit}</ErrorMesage>
                  )}
                </InputContainer>
              </Row>
              <Row>
                
                <InputContainer>
                  <Input
                    placeholder="Razón Social"
                    name="social"
                    value={formik.values.social}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.social && formik.touched.social && (
                    <ErrorMesage>{formik.errors.social}</ErrorMesage>
                  )}
                </InputContainer>
                <InputContainer>
                  <Input
                    placeholder="Condición IVA"
                    name="ivaCondition"
                    value={formik.values.ivaCondition}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.ivaCondition && formik.touched.ivaCondition && (
                    <ErrorMesage>{formik.errors.ivaCondition}</ErrorMesage>
                  )}
                </InputContainer>

              </Row>
            </FadeInDiv> }
          <Row>
          <InputContainer>
                  <Input
                    placeholder="Email de registro"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMesage>{formik.errors.email}</ErrorMesage>
                  )}
                </InputContainer>
            <InputContainer>
              <Input
                placeholder="Contraseña"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMesage>{formik.errors.password}</ErrorMesage>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="Confirmar contraseña"
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <ErrorMesage>{formik.errors.confirmPassword}</ErrorMesage>
              )}
            </InputContainer>
          </Row>
          <Row>
            <InputContainer>
              <TextArea
                placeholder="¿Cuáles son los destinos que comercializan habitualmente?"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.description && formik.touched.description && (
                <ErrorMesage>{formik.errors.description}</ErrorMesage>
              )}
            </InputContainer>
          </Row>
          <Row>
            <InputContainer>
                <Input
                placeholder="Teléfono"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone && (
                <ErrorMesage>{formik.errors.phone}</ErrorMesage>
              )}
              </InputContainer>
            <InputContainer>
                <Input
                placeholder="Email para recibir promociones (opcional)"
                name="promotionEmail"
                value={formik.values.promotionEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
            </InputContainer>
          </Row>
          <div style={{display:"flex", justifyContent:"center"}}>
              <Button type="submit">CREAR CUENTA</Button>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

export default Register;
