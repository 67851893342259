import "./App.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes, Navigate } from "react-router-dom";

//------------Pages--------------------
import Hero from "./pages/Hero/Hero";
import Footer from "./pages/Footer/Footer";
import NavBar from "./pages/NavBar/NavBar";

//-----------Components----------------

import Auth from "./components/auth/Auth";
import HotelList from "./components/HotelList/HotelList";
import LoginForm from "./components/auth/Login/Login";
import {AuthRoute,ProtectedRoute} from "./components/ProtectedRoute/ProtectedRoute";
import HotelCard from "./components/HotelCard/HotelCard";
//--------------------------------------

import { setUser } from "./redux/main.slice";
import {updateLocalStorage} from './utils/getStoredToken'

import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import Loader from "./components/Loader/Loader";
import Itinerary from "./components/Itinerary/Itinerary";
import NewPassword from "./components/NewPassword/NewPassword";
import Profile from "./components/Profile/Profile";
import Register from "./components/auth/Register/Register";
import Layout from "./components/auth/Login/Login";
import InternationalRoutes from "./routes/packages/internationalRoutes";
import NationalRoutes from "./routes/packages/nationalRoutes";
import PackageSection from "./components/PackageSection/PackageSection";
import Flyers from "./pages/Flyers/Flyers";
import WButton from "./components/WButton/WButton";
// import Promotion from "./components/Promotion/Promotion";


function App() {

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.mainSlice.isLoading)
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  
  useEffect(() =>{
    dispatch(setUser())
  }, [dispatch])

  useEffect(() => {
   
    window.addEventListener('load', updateLocalStorage);
    document.addEventListener('cookie', updateLocalStorage);

    return () => {
      window.removeEventListener('load', updateLocalStorage);
      document.removeEventListener('cookie', updateLocalStorage);
    };
  }, []);
  

  return (
    <div className="App">
        <Loader isLoading={loading} />
      <NavBar open={openRegister} setOpen={setOpenRegister} openLogin={openLogin} setOpenLogin={setOpenLogin}/>
      
      <Register open={openRegister} setOpen={setOpenRegister} />
      <Layout open={openLogin} setOpen={setOpenLogin} />
      <Routes>
        {/* <Route path="/" element={<Hero />} /> */}
        <Route path="*" element={ <Navigate to="/" replace />} />
        <Route path="/" element={<Hero {...{setOpenLogin}} />} />
        {/* <Route path="/package" element={<SearchSection />} /> */}
        <Route path="flyers" element={<Flyers />} />
        <Route path="/hotels" element={<HotelList />} />
          <Route path="/agreement/:id" element={ <HotelCard />} />
          <Route path="/package/:id" element={<Itinerary />}/>
        {/* <Route path="/search" element={<Search />} /> */}
        {/* -------------PROTECTED-ROUTES--------------- */}
        {/* <Route element={<AuthRoute />}>
        </Route> */}
        <Route element={<ProtectedRoute  />}>
        <Route path="/profile" element={<Profile />} />
        </Route>
        {/* -------------------------------------------- */}
        <Route path="/newpassword/:token" element={<NewPassword />} />
        <Route path="/internacionales/*" element={<InternationalRoutes />} />
        <Route path="/nacionales/*" element={<NationalRoutes />} /> 
        <Route path="/paquetes" element={<PackageSection />} />
      </Routes>
      {/* <SwiperCarousel /> */}
      {/* <Promotion /> */}
      <Footer />
      <WButton />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
