import { CheckBoxContainer, Content, Title, Select } from "../styles/FormStyle";
import { ButtonContainer, Span } from "../styles/ItineraryStyle";
// import { paxValidationSchema } from "../utils/validationSchema";
import FormSlice from "./FormSlice";
import moment from "moment";
import { months } from "../utils/months";

const Form = ({packageFound, handlePaxes, formik}) => {
  
 
  return (
    <>
      <Title>
        <span>RESERVAR</span>
        <hr />
      </Title>
      <Content onSubmit={formik.handleSubmit}>
        <div className="options_container" >
          <div
            style={{ display: "flex", gap: "1.5rem", flexDirection: "column" }}
          >
            <label htmlFor="">Fecha y ciudad de salida</label>
        {/* {
          packageFound?.departureOptions?.map((option, index) => {
            let date = moment(option);
            let day = date.format("DD");
            let month = parseInt(date.format("MM"), 10);
            let year = date.format("YYYY");

          return (<CheckBoxContainer key={index}>
          <input
            name="option"
            value={option}
            onChange={formik.handleChange}
            type="radio"
            checked={formik.values.option === option}
          />
          <label htmlFor="">{`${day} de ${months[month - 1]} ${year}`}</label>
        </CheckBoxContainer>)})
        } */}
        <CheckBoxContainer>
          <Span>{formik.values.locationSelected}</Span>
          <Span>
          {formik.values.dateSelected}
          </Span>
          {
            formik.values.priceSelected && <Span>${formik.values.priceSelected}</Span>
          }
        </CheckBoxContainer>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <label>Cantidad de pasajeros</label>
            <Select onChange={handlePaxes}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </Select>
          </div>
        </div>
        
        {formik.values.paxes.map((item, index) => (
          <>
          <div className="form_div" key={index}>
            <FormSlice index={index} formik={formik} />
          </div>
          <hr style={{width: "100%", border: "1px solid #D9D9D9", margin: 0}} />
          </>
        ))}
        <ButtonContainer className="submit">
          <button type="submit">CONFIRMAR</button>
        </ButtonContainer>
      </Content>
    </>
  );
};

export default Form;
