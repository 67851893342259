import React from "react";
import styled from "styled-components";
import ILocation from "../../assets/icons/hotel/ILocation";
import { GreyButton, GreyButtonContainer, Icons, Submit,ShowMoreButton } from "./PreHotelCardStyle";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: 20px;
  gap: 15px;
  border-bottom: 1px solid whitesmoke;

  @media screen and (max-width: 480px){
  grid-template-columns: 1fr;
  }
`;
export const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
`
export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 17px;
`;
export const Col1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
export const Title = styled.h3`
  margin: 0;
  font-size: 14px;
`
export const Col2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Check = styled.div`
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 7px 0;
    }
  }
`;

const MobilePreHotelCard = ({openDetails, image, agreement, address, name, stars, deadline, menu}) => {
  return (
    <>
      <Container>
        <Col1>
        <ImageContainer>
          <Image src={image ? image : "https://photo.netstorming.net/hotel/34658/wide/1.jpg"} alt="hotel" />
        </ImageContainer>
        </Col1>
        <Col2>
          <Check>
            <div>
              <div>
                <Title>{name}
                  </Title>
                  <span>{stars?.map((e, i) => (
                    <React.Fragment key={i}>{e}</React.Fragment>
                  ))}</span>
              </div>
              <span style={{display: "flex", alignItems: "center", fontSize: "14px"}}><i><ILocation width={"30px"} height={"30px"} /></i>{address}</span>
            </div>
            <ul>
            </ul>
          </Check>
        </Col2>
        <Col2>
        <GreyButtonContainer>
            <GreyButton>MAPA</GreyButton>
          </GreyButtonContainer>
          <Submit>
            <ShowMoreButton onClick={openDetails}>VER MÁS</ShowMoreButton>
            {menu && <span>Opciones ({agreement.length ? agreement.length : "1"})</span>}
          </Submit>
          <Icons>
          </Icons>
        </Col2>
      </Container>

    </>
  );
};

export default MobilePreHotelCard;
