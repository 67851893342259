import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreHotelCard from "../HotelCard/PreHotelCard";
import {
  CardContainer,
  Container,
  Layout,
  MessageContainer,
  ResultsContainer1,
  Spinner,
} from "./styles/listStyles";
import { updateHotels } from "../../redux/main.slice";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Filters from "./components/Filters";
import { Span } from "./styles/filterStyles";
import { getPagination } from "../../services/hotels.service";
import useFilters from "../../hooks/hotels/useFilters";
import { commonAlert } from "../../utils/alerts";

const FinalScrollMessage = () => (
  <MessageContainer>
    <h4>No hay más hoteles disponibles.</h4>
  </MessageContainer>
);

const Loader = () => (
  <Spinner>
    <div></div>
  </Spinner>
);

const HotelList = () => {
  const hState = useSelector((state) => state.mainSlice.hotels);
  const {isFiltered, setHasMore, hasMore ,setIsFiltered, setScrollReload, scrollReload} = useFilters()
  const containerRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const fetchData = async () => {
    try {
        if(hState.page === null || ( hState.page >= hState.totalPages)){
          setHasMore(false);
        }
        else{
          const data = await getPagination(hState.page, isFiltered)
          const addHotels = [...hState.hotels].concat(data.hotels)
          dispatch(updateHotels({
            ...hState,
            page: data.page,
            hotels: addHotels
          }))
        }
    } catch (err) {
      console.log(err);
      commonAlert("Error", "Debe realizar la busqueda nuevamente", "error")
      navigate("/")
      dispatch(updateHotels([]))
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      // Verificamos si el usuario ha llegado al final del contenedor específico
      const scrollPosition = container.scrollHeight - container.scrollTop;
      const containerHeight = container.clientHeight;
      const scrollThreshold = 1; // Puedes ajustar este valor si es necesario
      if (scrollPosition <= containerHeight + scrollThreshold) {
        fetchData();
      }
    }
  };

  useEffect(() => {
    // Agregamos el evento scroll al contenedor cuando el componente se monta
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      // Eliminamos el evento scroll del contenedor cuando el componente se desmonta
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  if(hState && hState?.length === 0) return navigate("/")

  return (
    <InfiniteScroll
    key={scrollReload}
      dataLength={hState.hotels.length}
      next={() => fetchData()}
      hasMore={hasMore}
      style={{ background: "white" }}
      loader={<Loader />}
      endMessage={<FinalScrollMessage />}
    >
      <Layout>
        <Container>
          <Filters isList={true} setScrollReload={setScrollReload} setIsFiltered={setIsFiltered} setHasMore={setHasMore}/>
          <div style={{display: "flex", width: "100%", margin: "2rem 0 2rem 0", justifyContent: "space-between"}}>
          <Span>Resultados ({hState.total}) {hState.totalFiltered >= 1 && (hState.totalFiltered !== hState.total) && <>filtrados ({hState.totalFiltered})</>}</Span>
          </div>
          {hState.hotels && (
            <CardContainer ref={containerRef} id="infiniteScroll">
              {hState?.hotels?.map((e, i) => {
               
                let hotelImage = e?.innerPics ? e.innerPics[0] : null;
                
                let total = e.agreements[0]?.totalRoomsPrice
                
                // if (e.innerPics) {
                  return (
                    <React.Fragment key={i}>
                      <PreHotelCard
                        search={e?.search}
                        agreement={e.agreements}
                        code={e.code}
                        name={e.name}
                        address={e.address}
                        stars={e.stars}
                        checkIn={hState?.checkin}
                        checkOut={hState?.checkout}
                        hotel_image={hotelImage}
                        totalPrice={total}
                        pictures={e.innerPics}
                        hotelsFacilities={e.hotelFacilities}
                      />
                    </React.Fragment>
                  );
                // }
              })}
            </CardContainer>
          )
          }
        </Container>
      </Layout>
    </InfiniteScroll>
  );
};

export default HotelList;
