import styled from "styled-components";
import IPlane from "../assets/IPlane";
import lines from "../assets/lines.png"
import { Column } from "../styles/ItineraryStyle";


 const ScaleContainer = styled(Column)`
  align-items: center;
  justify-content: end;
`;


 const Scale = styled.span`
font-size: 12px;
font-weight: 400 !important;
text-align: center;
color: #949494;
`;

const FlightContainer = styled.div`

/* style={{ display: "flex", gap: "0",  position: "relative", marginLeft: "2rem"}} */
display: flex;
gap: 0;
position: relative;
margin-left: 2rem;

.flight_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 200px; */
    gap: 0.5rem;
    img {
      width: 12.5rem;
    }
  }

  .flight_detail {
    display: flex;
    width: 100%;
    justify-content: center;

    span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 0 auto 0;
      color: #949494;
    }
  }


`

const FlightsMap = ({flight}) => {
    return ( <FlightContainer>
      <small style={{position: "absolute", fontWeight: "600", fontSize: "12px", left: "-2rem"}}>
      {flight?.type?.toUpperCase()}
      </small>
    <div className="flight_container">
      <IPlane />
      <img src={lines} alt="" />
      <div className="flight_detail">
        {/* <span>Buenos Aires (EZE) 18HS</span> */}
        <span>{flight?.departureCity} ({flight.departure}) {flight.departureTime}HS</span>
      </div>
    </div>
        <ScaleContainer>
        {flight.scales?.map((scale, index) => <Scale key={index}>{scale?.city} ({scale?.iata})</Scale>)}
        </ScaleContainer>
    <div className="flight_container">
      <IPlane />
      <img src={lines} alt="" />
       <div className="flight_detail">
        {/* <span>Buenos Aires (EZE) 18HS</span> */}
        <span>{flight?.arrivalCity} ({flight.arrival}) {flight.arrivalTime}HS</span>
       </div>
      </div>
    </FlightContainer> );
}
 
export default FlightsMap;