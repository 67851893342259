import React from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";

const ImageContainer = styled.div` 
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  /* flex-direction: column; */
`

const Image = styled.img`
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 878px) {
    width: 100%;
  }
`;

const ModalComponent = ({ show, onClose, imageUrl }) => {

  if (!show) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // width: "50%"
      }}
      onClose={onClose}
      open={show}
    >
      <ImageContainer>
      <Image src={imageUrl} alt="Ampliada" />
      {/* <span>asdasd</span> */}
      </ImageContainer>
    </Modal>
  );
};

export default ModalComponent;
