import React from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const MapView = ({hotels, name, height = "200px", width = "400px", margin = "none", coords, zoom = 13, setHotelCode, formik}) => {

  const Icon = new L.icon({
    iconSize: [24,36],
    iconAnchor: [12,36],
    popupAnchor: [-1, -35],
    iconUrl: require("../../assets/icons/icono_hotel.png"),
  })
  
  return (
    <MapContainer
      key={`${coords?.latitude}-${coords?.longitude}-${zoom}`}
      center={[coords?.latitude, coords?.longitude]}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{ height, width, zIndex: "0", margin }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
         Array.isArray(hotels) ? hotels?.map((hotel) => {
           if(hotel.coords.latitude && hotel.coords.longitude){
            return (
              <Marker icon={Icon} position={[hotel?.coords.latitude, hotel.coords.longitude]}>
              <Popup>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap :"6px"}}>
                  <span>{hotel.name}</span>
                  <span onClick={() => {
                    setHotelCode(hotel.code)
                    formik.handleSubmit()
                  }} style={{fontWeight: 600, userSelect: "none", cursor: "pointer"}}>Buscar</span>
                  </div>
              </Popup>
            </Marker>
           )
           }
         }) : (<Marker icon={Icon} position={[hotels?.coords?.latitude, hotels?.coords?.longitude]}>
          <Popup>{name}</Popup>
        </Marker>)
      }
    </MapContainer>
  );
};

export default MapView;
