import styled from "styled-components";

export const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
`;
export const Form = styled.form`
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 30px;
  h4 {
    margin: 0;
  }
`;
export const Content = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 875px) {
    flex-direction: column;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 0.6rem 0.6rem;
  font-size: 0.688rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 18px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: grey;
  }
`;

export const Button = styled.button`
  background-color: rgb(236, 104, 28);
  color: white;
  padding: 0.6rem 35px;
  border: none;
  border-radius: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin: 0 0 0 auto;

  @media screen and (max-width: 875px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.span`
    color: red;
    font-size: 8px;
    margin-left: 5px;
`