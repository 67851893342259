import styled from "styled-components";

export const FilterContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 30px;
  gap: 2rem;
`;

export const Filter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 878px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    gap: 0;
    column-gap: 20px;
  }
`;

export const Content1 = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 33.3%;
  gap: 1rem;

  @media screen and (max-width: 878px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

export const Content2 = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 33.3%;

  label {
    font-size: 0.688rem;
  }

  hr {
    height: 2px;
    background-color: #cccbcb;
    margin-left: 0%;
    width: 80%;
    border: none;
    margin: 0;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 878px) {
    width: 100%;
    margin-top: 10px;
    hr {
      width: 100%;
    }
  }
`;

export const Content3 = styled.div`
  display: flex;
  justify-content: end;
  width: 33.3%;

  .center{
    /* border: 1px solid blue; */
    width: 100%;
  }
  img {
    width: 100%;

    object-fit: cover;
  }
  @media screen and (max-width: 878px) {
  width: 100%;
    margin: 1rem 0;
  }
`;

export const Content4 = styled.div`
  width: 100%;

  .from-container {
    width: 80%;
    gap: 30px;

    justify-content: space-between;
  }

  @media screen and (max-width: 878px) {
    width: 100%;
    margin-top: 10px;
    .from-container {
      width: 100%;
      gap: 10px;
    }
  }
`;

export const Content9 = styled.div`
  width: 30%;
  margin-right: 10px;
`;

export const Span = styled.span`
  color: #949494;
  font-weight: 600;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 10px 50px 10px 50px;
  /* width: 200px; */
  background-color: rgba(236, 104, 28, 1);
  border: none;
  color: white;
  font-weight: 700;
  border-radius: 17px;
  cursor: pointer;
  margin: 0;
`;

export const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */

  div{
    display: flex;
    align-items: center;
    gap: 1rem;
    select{
      width: 200px;
    }
  }

`;

export const ResetFilter = styled.span`

  background-color: grey;
  margin-left: auto;
  padding: 10px;
  border-radius: 17px;
  font-weight: 600;
  color: white;
  user-select: none;
  cursor: pointer;
`

export const HotelSearchContainer = styled.div`
  display: flex;
  gap: 4rem;


  @media screen and (max-width: 878px) {
    flex-direction: column;
    gap: 2rem;
  }
`