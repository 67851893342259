import moment from "moment";
import Form from "./Form";
import {Button,Container,ContainerImage,Content,Row} from "../styles/MobileStyle"
import { DeployContainer, Label, Span, Title, Item } from "../styles/ItineraryStyle";
import { useRef, useState } from "react";
import { months } from "../utils/months";
import { CombinationContainer, InputContainer } from "../styles/combinations";
import { Select } from "../styles/FormStyle";
import CombinationsSelects from "./CombinationsSelects";



const Mobile = ({ packageFound, formik, handlePaxes, combinations, setPrice, user, price, handleFlyerModal }) => {

  const [show, setShow] = useState(false);
  const refMob = useRef(null);
  const [showMore, setShowMore] = useState(true)

  const toggleContainer = () => {
    setShow(true);
    refMob?.current?.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <Container show={show}>
        {/* <span className="badge_type">
        {packageFound.type}
        </span> */}
      <Row>
        <Content>
          <ContainerImage>
            <img src={packageFound?.image} alt="place" />
          </ContainerImage>
          <div className="details">
            <div style={{ textAlign: "center"}}>
            <h4>{packageFound.destination}</h4>
            <span>{packageFound.subtitle}</span>
            </div>
            <div style={{display: "flex", justifyContent:"space-between", flexWrap: "wrap", gap:"1rem"}}>
              </div>
          <Item style={{flexDirection: "column", gap:"2rem"}}>
                    {price > 0 ? (
                <Span>
                Costo: USD$ {price}
                </Span>
            ) : user?.status ? 'Esta combinación no tiene precio' : ''}
            </Item>
          </div>
        </Content>
      </Row>
      {
         combinations && combinations.length > 0 ? <CombinationsSelects styles={{justifyContent: "space-center", margin: "0 2rem"}} combinations={combinations} setPrice={setPrice} /> : ''
    }
      {/* {
        combinations && combinations.length > 0 ? <CombinationsSelects styles={{justifyContent: "center"}} combinations={combinations} /> : ''
      } */}
      {/* <CombinationContainer style={{justifyContent:"center"}}>
                        <InputContainer>
                    <Label>
                      Lugar de salida:
                      </Label>
                      <Select
                        value={selectedLocation}
                        onChange={handleLocationChange}
                      >
                        <option value="">Selecciona un lugar</option>
                        {uniqueLocations.map((location) => (
                          <option key={location} value={location}>
                            {location}
                          </option>
                        ))}
                      </Select>
                        </InputContainer>
                        <InputContainer>
                    <Label>
                      Fecha:
                        </Label>
                      <Select value={selectedDate} onChange={handleDateChange}>
                        <option value="">Selecciona una fecha</option>
                        {uniqueDates.map((date) => (
                          <option key={date} value={date}>
                            {date}
                          </option>
                        ))}
                      </Select>
                  </InputContainer>

                    {combinations[0]?.length > 3 ? (
                  <InputContainer>
                    <Label>
                      Aerolineas:
                        </Label>
                      <Select value={selectedPlane} onChange={handlePlaneChange}>
                        <option value="">Selecciona una Aerolinea</option>
                        {uniquePlanes.map((date) => (
                          <option key={date} value={date}>
                            {date}
                          </option>
                        ))}
                      </Select>
                  </InputContainer>

                    ) : ''}

                    {combinations[0]?.length > 4 ? (
                        <InputContainer>
                    <Label>
                      Noches:
                        </Label>
                      <Select value={selectedNights} onChange={handleNightsChange}>
                        <option value="">Selecciona la cantidad de noches</option>
                        {uniqueNights.map((date) => (
                          <option key={date} value={date}>
                            {date}
                          </option>
                        ))}
                      </Select>
                  </InputContainer>
                    ) : ''}

        </CombinationContainer> */}
      <Row>
      <Span>Incluido en el precio:</Span>
        <ul>
          {packageFound?.items?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </Row>
      <Row>
      <Span>No incluído en el precio:</Span>
        <ul>
          {packageFound?.excluded?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </Row>
      <Row>
      <Span>Condiciones de contratación:</Span>
        <ul>
          <input onChange={() => setShowMore(!showMore)} value={showMore} id="check" type="checkbox" style={{display: "none"}} />
          {showMore ? packageFound.contract.slice(0,4).map((item, index) => <li key={index}>{item}</li>) : packageFound.contract.map((item) => (
            <li>{item}</li>
          ))}
          {showMore ? <Label htmlFor="check">Ver mas</Label> : <Label htmlFor="check">Ver menos</Label>}
        </ul>
      </Row>
      <Row>
        <div className="list_images">
          {packageFound.itemImages.slice(0, 3).map((item, index) => {
            if(item){
              return (
                <div key={index}>
                  <img src={item} alt="" />
                </div>
              )
            }
          })}
        </div>
      </Row>
      <Row>
        <Button pdf={packageFound?.pdf}>
          <a
            href={packageFound?.pdf}
            style={{ backgroundColor: "#d9d9d9" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            VER ITINERARIO
          </a>
          {user.status && packageFound.flyers?.length > 0 &&
                        (<button onClick={handleFlyerModal}>FLYERS</button>)
                      }
          <button disabled={!user.status} 
          style={{background: !user.status ? "#d9d9d9" : "rgba(236, 104, 28, 1)", cursor: !user.status ? "default" : "pointer"}}  onClick={toggleContainer}>RESERVAR</button>
        </Button>
      </Row>
      <DeployContainer deploy={refMob} ref={refMob} show={show} >
      <Form {...{packageFound, formik, handlePaxes}} />
      </DeployContainer>
    </Container>
  );
};

export default Mobile;
