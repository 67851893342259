import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { validationSchema } from "./validator";
import { useFormik } from "formik";
import { createBooking } from "../../../services/hotels.service";
import {
  CheckBoxContainer,
  Container,
  Form,
  Button,
  Input,
  InputContainer,
  RoomGroup,
  Select,
} from "./form";
import styled from "styled-components";
import { initialValuesConverter } from "./utils";
import { resetHotels, setLoading } from "../../../redux/main.slice";

import { useNavigate } from "react-router-dom";
import { commonAlert } from "../../../utils/alerts";

const Group = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: red;
    font-size: 12px;
  }
`;

const InputGroup = ({ hotel, evaluatedSearch, formRef }) => {
  let hotelState = useSelector((state) => state.mainSlice.hotels);
  let user = useSelector((state) => state.mainSlice.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  let roomTypesArr = hotel.agreement.room;

  if (!Array.isArray(roomTypesArr)) {
    roomTypesArr = [roomTypesArr];
  }

  let hotelRooms = hotel.rooms;
  if (!Array.isArray(hotel.rooms)) {
    hotelRooms = [hotel.rooms];
  }
  let roomsArr = initialValuesConverter(hotelRooms);

  const initialValues = {
    rooms: roomsArr,
    email: "",
    phone: "",
  };
  const onSubmit = async (values) => {
    console.log(hotel)
      const bookingValues = {
        availableSearch: hotel.evaluatedSearch.number,
        hotelCode: hotel.code,
        agreementCode: hotel.agreement.id,
        cityCode: JSON.parse(localStorage.getItem('search')).city,
        checkIn: JSON.parse(localStorage.getItem('search')).checkIn,
        checkOut: JSON.parse(localStorage.getItem('search')).checkOut,
        roomType: hotel.agreement.room.map((item) => item.type),
        email: values.email,
        phone: values.phone,
        rooms: values.rooms,
        city: JSON.parse(localStorage.getItem("cityNameSearch")),
        hotel: hotel.name,
        agency: user.id,
        totalNetPrice: hotel.agreement.totalRoomsPrice,
        addOnPrice: hotel.agreement.addOnPrice,
        rawHotelPrice: hotel.agreement.rawHotelPrice,
        agencyPayment: hotel.agreement.agencyPayment,
        agencyComission: hotel.agreement.agencyComission,
      };
      try {
        dispatch(setLoading(true));
        const reference = await createBooking(bookingValues);
        formik.resetForm();
        dispatch(setLoading(false));
        commonAlert("¡Reserva realizada con éxito!", `Su número de comprobante es: ${reference.envelope.response.reference.code}`, "success")
        dispatch(resetHotels());
        navigate("/");
      } catch (err) {
        dispatch(setLoading(false));
        console.log(err);
        commonAlert("Oops...", "Hubo un problema al realizar la reserva. Por favor, asegúrese de que los datos del formulario estén completos y correctos.", "error")
        return;
      }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handlePasaporteChange = (e, indexRoom, indexPax) => {
    const { name, value } = e.target;

    formik.setFieldValue(
      `rooms[${indexRoom}].paxes[${indexPax}].${name}`,
      value
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container>
        <InputContainer>
          <Group>
            <Input
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Email"
            />
            {formik.touched.email && formik.errors.email && (
              <span>{formik.errors.email}</span>
            )}
          </Group>
          <Group>
            <Input
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              placeholder="Teléfono"
            />
            {formik.touched.phone && formik.errors.phone && (
              <span>{formik.errors.phone}</span>
            )}
          </Group>
        </InputContainer>
        {formik.values.rooms.map((room, indexRoom) => (
          <RoomGroup key={indexRoom}>
            <span>Habitación {room.type}</span>
            {room.paxes.map((pax, indexPax) => (
              <InputContainer key={indexPax}>
                <Group>
                  <Input
                    type="text"
                    name={`rooms[${indexRoom}].paxes[${indexPax}].name`}
                    value={pax.name}
                    onChange={formik.handleChange}
                    placeholder="Nombre y Apellido"
                  />
                  {formik.touched.rooms?.[indexRoom]?.paxes?.[indexPax]?.name &&
                    formik.errors.rooms?.[indexRoom]?.paxes?.[indexPax]
                      ?.name && (
                      <span>
                        {formik.errors.rooms[indexRoom].paxes[indexPax].name}
                      </span>
                    )}
                </Group>
                <Group>
                  <Input
                    type="text"
                    name={`passport`}
                    value={pax.passport}
                    onChange={(e) =>
                      handlePasaporteChange(e, indexRoom, indexPax)
                    }
                    placeholder="Pasaporte"
                  />
                  {formik.touched.rooms?.[indexRoom]?.paxes?.[indexPax]
                    ?.passport &&
                    formik.errors.rooms?.[indexRoom]?.paxes?.[indexPax]
                      ?.passport && (
                      <span>
                        {
                          formik.errors.rooms[indexRoom].paxes[indexPax]
                            .passport
                        }
                      </span>
                    )}
                </Group>
                <Group>
                  <Select
                    name={`rooms[${indexRoom}].paxes[${indexPax}].gender`}
                    value={pax.gender}
                    onChange={formik.handleChange}
                  >
                    <option defaultValue={false} hidden={true}>
                      Seleccione un genero
                    </option>
                    <option value="M">Hombre</option>
                    <option value="F">Mujer</option>
                  </Select>
                  {formik.touched.rooms?.[indexRoom]?.paxes?.[indexPax]
                    ?.gender &&
                    formik.errors.rooms?.[indexRoom]?.paxes?.[indexPax]
                      ?.gender && (
                      <span>
                        {formik.errors.rooms[indexRoom].paxes[indexPax].gender}
                      </span>
                    )}
                </Group>
                <Group>
                  <Select
                    name={`rooms[${indexRoom}].paxes[${indexPax}].stage`}
                    value={pax.stage}
                    onChange={formik.handleChange}
                  >
                    <option defaultValue="adult">Adulto</option>
                    <option value="child">Niño</option>
                    <option value="infant">Infante</option>
                  </Select>
                </Group>
                <CheckBoxContainer>
                  <input
                    type="checkbox"
                    name={`rooms[${indexRoom}].extrabed`}
                    value={pax.extrabed}
                    onChange={formik.handleChange}
                    id={`extrabed${indexRoom}`}
                  />
                  <label htmlFor={`extrabed${indexRoom}`}>Cama extra</label>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <input
                    type="checkbox"
                    name={`rooms[${indexRoom}].babyCot`}
                    value={pax.babyCot}
                    onChange={formik.handleChange}
                    id={`babyCot${indexRoom}`}
                  />
                  <label htmlFor={`babyCot${indexRoom}`}>Cuna</label>
                </CheckBoxContainer>
              </InputContainer>
            ))}
          </RoomGroup>
        ))}
      </Container>
      <Button
        type="submit"
        ref={formRef}
        value={"Enviar"}
        style={{ display: "none" }}
      />
    </Form>
  );
};
export default InputGroup;
