import { Container } from "./FeedBackStyle";
import IStar from "../../assets/icons/hotel/IStar";
import SwiperCarousel from "../../components/Swiper/SwiperCarousel";
import { fetchReviews } from "../../services/reviews.service";
import { useState } from "react";
import { useEffect } from "react";

const Card = ({rating, description, name, image, date}) => {

  

  const starsToRender = (rating) => {
    let starsArray = []
    for(let i = 0;i < rating;i++){
      starsArray.push(<IStar width={"2.5rem"} key={i}/>)
    }
    return starsArray
  }
  
  return (
  <Container>
    <span>
      {/* {starsArray.map((el) => (
        el
      ))} */}
      {starsToRender(rating).map((el) => el)}
    </span>
    <p>
      {description.length > 330 ? description.slice(0, 330) : description}
    </p>
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
    <div style={{display: "flex", flexDirection:"column", gap: "5px"}}>
    <span>{name}</span>
    <small>{date}</small>
    </div>
    <img src={image} alt="user" style={{width:"3rem", height:"3rem"}} />
    </div>
  </Container>
)};

const Feedback = () => {
  const [reviews, setReviews] = useState([])
  
  async function asyncReviews(){
    let data = await fetchReviews()
    console.log(data)
    let dataArr = data?.map((item, index) => <Card  {... {key: index, name: item.author_name, description: item.text, date: item.relative_time_description, rating: item.rating, image: item.profile_photo_url}} />)
    return dataArr
  }
  
  useEffect(() => {
  asyncReviews().then((response) => {
    setReviews(response)
  })

  },[])
  return (
    <>
      <SwiperCarousel
        subtitle={
          "Trabajamos con mucho amor y nos encanta saber sus experiencias, te dejamos algunas acá"
        }
        title={"Opiniones de nuestros viajeros"}
        cards={reviews}
      />
    </>
  );
};
export default Feedback;
