import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 7rem;
`;

export const Title = styled.div`
  text-align: center;
  margin: 0  0 var(--margin-small) 0;
  font-size: 1.2rem;

  h2{
    margin: 0 0 5px 0;
  }

  @media screen and (max-width: 878px) {
    margin-bottom: 15px;
  }

  p {
    margin: 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "image1 image2 image3 image4"
    "image5 image6 image7 image8";
  gap: 30px;
  /* margin: 20px 50px; */
  margin: 0 var(--margin-large) 0 var(--margin-large);
  /* margin-right: var(--margin-large); */

  @media screen and (max-width: 878px) {
    grid-template-columns: auto auto;
    grid-template-areas:
      "image1 image2"
      "image3 image4";
  }
  margin: 1rem;
`;

export const HoverText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: bottom 0.2s ease-in-out;
`;

export const ImageContainer = styled.div`

  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.hovered ? "1" : props.hoveredIndex != null ? "0.5" : "1")};
  transition: opacity 0.8s ease;
  max-width: 300px;

  &:hover {
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
    & > ${HoverText} {
      opacity: 1;
      bottom: 0;
      transition: all 0.3s ease-in-out;
    }
  
  }

  &:nth-of-type(1) {
    grid-area: image1;
  }

  &:nth-of-type(2) {
    grid-area: image2;
  }

  &:nth-of-type(3) {
    grid-area: image3;
  }

  &:nth-of-type(4) {
    grid-area: image4;
  }

  &:nth-of-type(5) {
    grid-area: image5;
    @media screen and (max-width: 878px) {
      display: none;
    }
  }

  &:nth-of-type(6) {
    grid-area: image6;
    @media screen and (max-width: 878px) {
      display: none;
    }
  }

  &:nth-of-type(7) {
    grid-area: image7;
    @media screen and (max-width: 878px) {
      display: none;
    }
  }

  &:nth-of-type(8) {
    grid-area: image8;
    @media screen and (max-width: 878px) {
      display: none;
    }
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
`;


