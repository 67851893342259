import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;

export const getPurchases = async(agencyId) => {

    try{
            const data = await axios.get(`${API_URL}/purchase?agencyId=${agencyId}`)
            if(Array.isArray(data.data)) return data.data
            return []
    }
    catch(err){
        console.log(err)
        return;
    }
}
