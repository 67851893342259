import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/main.slice";
import { packagePurchase } from "../../../services/packages.service";
import { commonAlert } from "../../../utils/alerts";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const useForm = ({packageFound, pState}) => {
    
  const [pax, setPax] = useState(1);
  const dispatch = useDispatch();
  const url = useLocation()
  const reduxPackages = useSelector(state => state.mainSlice.packages)
  const [selects, setSelects] = useState(reduxPackages.find(item => item._id === url.pathname.split("/")[2])?.selects)


  const values = {
    name: "",
    lastname: "",
    birthDate: "",
    passport: "",
    passportExpireDate: "",
    address: "",
    email: "",
    phone: "",
  };
  const initialValues = {
    packageId: packageFound?._id ? packageFound._id : url.pathname.split("/")[2],
    selects:  selects,
    texts: packageFound?.texts ? packageFound.texts : reduxPackages?.find(item => item._id === url.pathname.split("/")[2])?.texts,
    paxes: Array(pax).fill(values),
    option: packageFound?.departureOptions[0] || '',
    locationSelected: "",
    dateSelected: "",
    planeSelected: "",
    nightsSelected: "",
    priceSelected: ""
  };

  const handlePaxes = (e) => {
    const numPaxes = parseInt(e.target.value, 10);
    setPax(numPaxes);
    formik.setFieldValue("paxes", Array(numPaxes).fill(values));
  };

  const formik = useFormik({
    initialValues,

    // validationSchema: paxValidationSchema,
    onSubmit: async(values) => {
      try{
          dispatch(setLoading(true));
           await packagePurchase(values);
          dispatch(setLoading(false))
            commonAlert("Reserva confirmada", "Nos estaremos contactando con usted en breve para concretar la reserva.", "success")
      }
      catch(err){
        dispatch(setLoading(false))
        commonAlert("Error", "Algo salió mal", "error",)
      }
    },
  });

  useEffect(() => {
        formik.setValues({...initialValues, selects: pState?.selects, texts: pState?.texts, packageId: packageFound?._id})
  }, [pState])

    return {formik, handlePaxes};
}
 
export default useForm;