export const images = [
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/fotos+grupales/fotos+grupales/viajes+15+portada.jpg",
      text: "Disney, Noviembre 2018"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/medio_oriente_portada.jpg",
      text: "Egipto, Noviembre 2018"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/grupal_02.jpg",
      text: "Madrid, Noviembre 2018"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/grupal_01.jpg",
      text: "Texto de imagen 4"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/fotos+grupales/fotos+grupales/viajes+15+portada.jpg",
      text: "Texto de imagen 5"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/medio_oriente_portada.jpg",
      text: "Texto de imagen 6"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/grupal_02.jpg",
      text: "Texto de imagen 7"
    },
    {
      url: "https://interlude-assets.s3.us-east-2.amazonaws.com/images-opt/grupal_01.jpg",
      text: "Texto de imagen 8"
    },
  ];
  