import * as Yup from 'yup';

export const profileSchema = Yup.object().shape({
    cuit: Yup.string().required('Este campo es requerido'),
    social: Yup.string().required('Este campo es requerido'),
    ivaCondition: Yup.string().required('Este campo es requerido'),
    address: Yup.string().required('Este campo es requerido'),
    // email: Yup.string().email('Ingrese un correo electrónico válido').required('Este campo es requerido'),
    // promotionEmail: Yup.boolean(),
    legacy: Yup.string().required('Este campo es requerido'),
    agency: Yup.string().required('Este campo es requerido'),
  });