
import Loader from "../Loader/Loader";
import Agency from "./components/Agency";
import Booking from "./components/Booking";
import Hotels from "./components/Hotels";
import Purchase from "./components/Purchase";
import useProfile from "./hooks/useProfile";
import { MainContainer } from "./ProfileStyle";



const Profile = () => {
  const { dataLoaded, id, profileData, bookings } = useProfile();

  if(!dataLoaded) return <Loader isLoading={!dataLoaded} background={true} />

  return (
    <>
      <span style={{display: "block",margin: "31px 0 0 49px", color: "rgba(89, 89, 89, 1)", fontWeight:"600"}}>MI CUENTA</span>
      {dataLoaded && (
        <MainContainer>
          <Agency profileData={profileData} id={id} />
          <Booking bookings={bookings} />
          <Hotels />
          <Purchase agencyId={id} />
        </MainContainer>
      )}
    </>
  );
};

export default Profile;
