import Swal from "sweetalert2"


export const commonAlert = (title = "", text = "", type = "success") => {

     Swal.fire({
        icon: type,
        title,
        html: text
     })
}