import React, { useState } from 'react';
import { useEffect } from 'react';
import { CombinationContainer, InputContainer } from '../styles/combinations';
import { Label } from '../styles/ItineraryStyle';
import { Select } from '../styles/FormStyle'

const CombinationsSelects = ({combinations, styles, setPrice}) => {
    const data = combinations.slice(1);
    const validColumns = combinations[0].slice(0, -1).map((_, index) => data.some(row => row[index])).map((isValid, index) => isValid ? index : -1).filter(index => index !== -1);
    const [selectedOptions, setSelectedOptions] = useState(validColumns.map(index => data[0][index]));

    useEffect(() => {
        let price = data.find(combination => {
            return validColumns.every((columnIndex, index) => combination[columnIndex] === selectedOptions[index]);
        })?.slice(-1)[0];
        setPrice(price || 0);
    }, [selectedOptions]);

    const handleChange = (event, index) => {
        setSelectedOptions(selectedOptions.map((value, i) => i === index ? event.target.value : value));
    };

    const getOptions = (index) => {
        return Array.from(new Set(data.filter(combination => validColumns.every((columnIndex, i) => i < index || combination[columnIndex] === selectedOptions[i])).map(combination => combination[index]))).filter(value => value);
    };

    return (
        <CombinationContainer style={styles}>
            {validColumns.map((columnIndex, index) => (
                <InputContainer>
                <Label>
                    {combinations[0][columnIndex]}
                </Label>
                <Select key={index} value={selectedOptions[index]} onChange={event => handleChange(event, index)}>
                    {Array.from(new Set(data.map(combination => combination[columnIndex]))).map((value, i) => (
                        <option key={i} value={value}>{value}</option>
                    ))}
                </Select>
                </InputContainer>
            ))}
            {/* {
               price > 0 ? <p>Price: {price}</p> : 'Esta combinacion no tiene precio'
            } */}
        </CombinationContainer>
    );
};

export default CombinationsSelects;
