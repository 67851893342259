import { Route, Routes } from "react-router-dom";
import Internationals from "../../pages/Internationals/Internationals";
import Groups from "../../pages/Internationals/Groups";
import PackageByType from "../../components/PackageComponents/PackageByType";


const GroupsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Groups />} />
      <Route path="europa" element={ <PackageByType label={"EUROPA"} travelType={"INTERNACIONALES"} isGroup={true} region={"europa"} />}/>
      <Route path="america" element={ <PackageByType label={"AMERICA"} travelType={"INTERNACIONALES"} isGroup={true} region={"america"} />}/>
      <Route path="asia" element={ <PackageByType label={"ASIA"} travelType={"INTERNACIONALES"} isGroup={true} region={"asia"} />}/>
      <Route path="medio oriente" element={ <PackageByType label={"MEDIO ORIENTE"} travelType={"INTERNACIONALES"} isGroup={true} region={"medio oriente"} />}/>
      <Route path="exoticos" element={ <PackageByType label={"EXOTICOS"} travelType={"INTERNACIONALES"} isGroup={true} region={"exoticos"} />}/>
    </Routes>
  );
};

const InternationalRoutes = () => {
  return (
    <Routes>
      <Route index element={<Internationals />} />
      <Route path="grupales/*" element={<GroupsRoutes />} />
      <Route path="regulares" element={ <PackageByType label={"REGULARES"} travelType={"INTERNACIONALES"} />}/>
      <Route path="paquetes de playa" element={<PackageByType label={"PAQUETES DE PLAYA"} travelType={"INTERNACIONALES"} />}/>
      <Route path="montañas" element={<PackageByType label={"MONTAÑAS"} travelType={"INTERNACIONALES"} />}/>
      <Route path="exoticos" element={<PackageByType label={"EXOTICOS"} travelType={"INTERNACIONALES"} />}/>
    </Routes>
  );
};
export default InternationalRoutes;
