import { Modal } from "@mui/material";

const ModalImgPreview = ({ onClose, onItem, selectedImg }) => {
  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      open={onItem}
      onClose={onClose}
    >
      <div style={{ width: "400px" }}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={selectedImg}
          alt=""
        />
      </div>
    </Modal>
  );
};

export default ModalImgPreview;
