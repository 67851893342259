

const IFace = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      baseProfile="tiny"
      overflow="visible"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="#888"
        d="M31 41.9c-.1 0-.3-.1-.4-.2-.1-.1-.2-.3-.2-.4v-9.7c0-.3.3-.6.6-.6h2.6v-2.7H31c-.3 0-.6-.3-.6-.6v-1.8c0-.5.1-1 .4-1.4.2-.3.6-.7 1.4-.7h1.5v-2.5H32c-1.5 0-2.6.4-3.3 1.1-.7.8-1 1.9-1 3.5v1.8c0 .3-.3.6-.6.6h-2.4V31h2.4c.3 0 .6.3.6.6v9.7c0 .2-.1.3-.2.4-.1.1-.3.2-.4.2l-8.2-.1c-2.4 0-4.5-1.7-5-4.1-.1-.4-.1-.7-.1-1.1V20.3c0-2.8 2.3-5.1 5.1-5.1h16.4c2.8 0 5.1 2.3 5.1 5.1v16.4c0 2.8-2.3 5.1-5.1 5.1l-4.3.1zm-16-4.4c.4 1.8 2 3.2 3.9 3.2l7.6.1v-8.6h-2.4c-.3 0-.6-.3-.6-.6v-3.9c0-.3.3-.6.6-.6h2.4v-1.2c0-1.9.5-3.3 1.3-4.3.9-1 2.3-1.5 4.1-1.5h2.2c.3 0 .6.3.6.6v3.7c0 .3-.3.6-.6.6H32c-.2 0-.3.1-.4.2-.1.2-.2.4-.2.8v1.2H34c.3 0 .6.3.6.6v3.9c0 .3-.3.6-.6.6h-2.6v8.6h3.8c2.2 0 4-1.8 4-4V20.3c0-2.2-1.8-4-4-4H18.9c-2.2 0-4 1.8-4 4v16.4c0 .3.1.6.1.8z"
      />
    </svg>
  )
  export default IFace;