import { useNavigate } from "react-router-dom";
import {Container,Content,ImageContainer} from "../../../../styledComponents/packages/CardStyle"


const CardPackage = ({ travel, onRoute, columnCard }) => {

  const navigate = useNavigate();

  return (
    <Container style={{cursor: "pointer"}} columnCard={columnCard} onClick={() => navigate(`/package/${travel._id}`)}>
      <ImageContainer>
      <img src={travel?.itemImages[0] ? travel.itemImages[0]  : "https://photo.netstorming.net/hotel/34658/wide/1.jpg"} alt="card" />
      </ImageContainer>
      <Content subtitle={travel.subtitle}>
            <span style={{ fontWeight: "bold",textAlign:"center", width:"100%" }}>{travel.destination}</span>
          <span className="subtitle" style={{  textAlign:"center", width:"100%" }}  >{travel.subtitle}</span>
   
      </Content>
    </Container>
  );
};

export default CardPackage;
