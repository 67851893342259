import styled from "styled-components";
import {
    ButtonContainer,
    Column,
    ItineraryBox,
  } from "./ItineraryStyle";

export const Container = styled(ItineraryBox)`
  margin: 0 0 3rem 0;
  width: 100%;
  gap: 2rem;
  border: 2rem solid rgba(217, 217, 217, 1);
  padding: 2rem 0 2rem 0;

  position: relative;

  .badge_type{
    position: absolute;
    top: ${({show}) => show ? '-1.5%' : '-3%'};
    font-weight: 600;
    color: #595959;
    text-transform: uppercase;
    margin-left: 1rem;
  }
`;
export const Row = styled(Column)`
  margin: 0 2rem 0 2rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .list_images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 1.2rem;
    div {
      max-width: 250px;
      display: flex;
    }
    img {
      height: 100%;
      max-width: 100%;
      border-radius: 15px;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  ul{
      display: flex;
      flex-direction: column;
      gap: 8px;
  }
  .details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
      margin: 0;
    }
  }

  @media screen and (max-width: 1240px){
      flex-direction: column;
      align-items: center;
  }
`;

export const ContainerImage = styled.div`
  max-width: 200px;
  height: auto;
  img {
    width: 100%;
    height: 100%;
    border-radius: 13px;
  }

  @media screen and (max-width: 1240px){
    max-width: 100%;
  }
`;
export const Button = styled(ButtonContainer)`
  gap: 0.8rem;
`;