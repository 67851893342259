import { useState, useEffect } from 'react';

export let useCheckClass = (data) => {
  const [checkedClass, setCheckedClass] = useState("");

  useEffect(() => {

    const facilitiesToWords = {
        T: "T",
        ST:"TS",
        F: "F",
        SF:"SF",
        D: "D"
      };
  
      let checking = facilitiesToWords[data] || " "


    // Actualizar el estado words solo una vez al final, con todas las palabras recopiladas
    setCheckedClass(checking);
  }, [data]); // El efecto se ejecutará cuando el objeto hotel cambie

  return { checkedClass };
};
