import { Route, Routes } from "react-router-dom";
import Nationals from "../../pages/Nationals/Nationals";
import PackageByType from "../../components/PackageComponents/PackageByType";
// import Package from "../../components/Package/Package";

const NationalRoutes = () => {
    return ( <Routes>
        <Route index element={<Nationals />} />
        <Route path="regulares" element={<PackageByType label={"REGULARES"} travelType={"NACIONALES"} />} />
        <Route path="grupales" element={<PackageByType label={"GRUPALES"} travelType={"NACIONALES"} />} />
    </Routes> );
}
 
export default NationalRoutes;