import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      fill="none"
      viewBox="0 0 92 92"
      radius={'30px'}
    >
      <path fill="#00d95f" d="M0 0h91.562v91.562H0z"></path>
      <g fill="#fff">
        <path d="M22.89 66.976l3.285-12.213c-2.649-4.758-3.49-10.31-2.37-15.637s4.127-10.072 8.469-13.364a22.686 22.686 0 0129.62 1.915 22.592 22.592 0 012.301 29.544 22.66 22.66 0 01-13.276 8.627 22.69 22.69 0 01-15.691-2.164zm12.932-7.862l.763.452a18.337 18.337 0 0022.178-2.694 18.254 18.254 0 003.062-22.094 18.301 18.301 0 00-8.839-7.804 18.34 18.34 0 00-11.772-.8c-3.905 1.037-7.357 3.334-9.818 6.535s-3.791 7.123-3.785 11.158a18.053 18.053 0 002.677 9.476l.478.787-1.836 6.817z"></path>
        <path
          fillRule="evenodd"
          d="M54.34 47.003a3.78 3.78 0 00-3.233-.736c-.84.348-1.383 1.665-1.926 2.324a.802.802 0 01-1.035.232 14.645 14.645 0 01-7.32-6.261.877.877 0 01.117-1.214 5.06 5.06 0 001.293-2.156 4.693 4.693 0 00-.595-2.582 6.042 6.042 0 00-1.875-2.827 2.59 2.59 0 00-2.78.426 5.706 5.706 0 00-1.979 4.518c.002.48.063.956.181 1.42.3 1.116.762 2.181 1.371 3.164a24.4 24.4 0 001.436 2.181 22.033 22.033 0 006.22 5.758 18.752 18.752 0 003.88 1.847 8.6 8.6 0 004.512.71 5.424 5.424 0 004.087-3.034 2.578 2.578 0 00.194-1.55c-.233-1.071-1.668-1.704-2.548-2.22z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
