import styled from "styled-components";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItineraryBox = styled(Column)`
  margin: 3rem var(--margin-large) 6rem var(--margin-large);
  border: 30px solid rgba(217, 217, 217, 1);
  border-radius: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 1022px) {
    margin: 0;
  }

   @media screen and (max-width: 1500px) {

    margin: 3rem 7rem 6rem 7rem
  }
`;
export const Container = styled.div`
  display: flex;
  padding: 1rem;
  /* gap: 2rem; */
`;

export const Row = styled(Column)`
  /* padding: 2rem; */
  margin: 2rem;

  @media screen and (max-width: 1300px) {
    margin: 1rem;
  }
`;

export const ContentRow = styled.div`
  
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    width: auto !important;
    gap: 2rem;
  }
`;

export const Gallery = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  /* border: 1px solid red; */

  div {
    display: flex;
    max-width: 250px;

    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
`;

export const Content = styled(Column)`
  justify-content: center;
  align-items: flex-start;

  gap: 2rem;
  border-radius: 15px;
  /* margin-bottom: 4rem; */
`;

export const Title = styled(Column)`
  gap: 5px;
  h3 {
    margin: 0;
    display: flex;
    font-size: 1.25rem;
    justify-content: space-between;
  }
  span {
    font-size: 1rem;
  }
`;

export const SubTitle = styled(Column)`
  gap: 5px;

  span {
    font-size: 0.9rem;
  }
`;

export const ContainerImage = styled.div`
  display: inline-block;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }
`;

export const BoxImage = styled(Column)`

  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
`

export const Details = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 4rem;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 3px;

    li {
      font-size: 0.9rem;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonContainer = styled(Column)`
  gap: 1rem;

  a,
  button {
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 12px 50px 12px 50px;
    background-color: rgba(236, 104, 28, 1);
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 17px;
    cursor: pointer;
    margin: 0;
  }

  a {
    opacity: ${({ pdf }) => (pdf ? "1" : "0.5")};
    pointer-events: ${({ pdf }) => (pdf ? "" : "none")};
    color: ${({ pdf }) => (pdf ? "black" : "grey")};
  }
`;

export const DeployContainer = styled.div`
  max-height: ${(props) =>
    props.show ? props.deploy.current.scrollHeight : "0"};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

export const Span = styled.span`
  color: rgb(89, 89, 89);
  font-size: 14px;
  font-weight: 600;
`;

export const Label = styled(Span).attrs({ as: "label" })``;
