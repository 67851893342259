import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import {
  CarouselContainer,
  ImageContainer,
  StyledImage,
} from "./CarouselStyle";
import { links } from "../../../data";
import { useDispatch, useSelector } from "react-redux";
import { getCarouselItems } from "../../../redux/main.slice";
import { Skeleton } from "@mui/material";




const Carousel = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const images = useSelector((state) => state.mainSlice.carousel);
  const isLoading = useSelector((state) => state.mainSlice.isLoading)
  const dispatch = useDispatch();

  const openTab = (link) => {
    if(link) window.open(link, '_blank');
  };

  useEffect(() => {
    function handleWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWidth);

    return () => window.removeEventListener("resize", handleWidth);
  }, []);

  useEffect(() => {
    dispatch(getCarouselItems());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dragabble: false,
  };
  
  return (
    <>
    <CarouselContainer>
       {
        !isLoading ?
         <Slider {...settings}>
        {images?.map((item, index) => (
          <ImageContainer  key={index}>
            {width > 768 ? (
              <StyledImage onClick={() => openTab(item.link)} style={{cursor: item.link ? "pointer": ""}} src={item.image} alt="banner" />
              ) : (
                <StyledImage src={item.mobile} alt="banner" />
                )}
          </ImageContainer>
        ))}
      </Slider>
     :  <Skeleton variant="rectangular" style={{ width: "100%", height: "405px"}} animation="pulse" />  
    }
    </CarouselContainer>
        </>
  );
};

export default Carousel;
