import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  city: Yup.string().required('La ciudad es requerida'),
  checkIn: Yup.date().min(new Date(), "La fecha de ingreso debe ser mayor a hoy").required("La fecha de ingreso es requerida"),
  checkOut: Yup.date()
    .required("La fecha de salida es requerida")
    .min(Yup.ref('checkIn'), 'La fecha de salida debe ser posterior a la fecha de entrada'),
});

  export default validationSchema