import { Modal } from "@mui/material";
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { Badge, TableCell, TableContainer, TableHeader, TableRow } from '../styles/bookingStyles';
import { useEffect, useState } from 'react';
import { getPurchases } from '../../../services/purchase.service';
import styled from 'styled-components';
import { Management } from "../styles/managementModal";

const Button = styled.button`
    padding: 5px;
    border-radius: 20px;
    border: none;
    color: white;
    background-color: rgba(236, 104, 28, 1);
    font-weight: 600;
    margin: auto 0 0 0;
`

const columns = [
    {
        header: "PAQUETE",
        accessorKey: "packageId.destination"
    },
    {
        header: "FECHA DE SALIDA",
        accessorKey: "dateSelected"
    },
    {
        header: "SALIDA",
        accessorKey: "locationSelected"
    },
    {
        header: "CATEGORIA",
        accessorKey: "packageId.travelType",
        cell: (info) => info.getValue()?.charAt(0).toUpperCase() + info.getValue()?.slice(1)
    },
    {
        header: "TIPO",
        accessorKey: "packageId.type",
        cell: (info) => info.getValue()?.charAt(0).toUpperCase() + info.getValue()?.slice(1)
    },
    {
        header: "MONTO",
        accessorKey: "priceSelected",
        cell: (info) => `$${info.getValue()}`
    },
    {
        header: "ESTADO",
        accessorKey: "status",
        cell: (info) => {
            if(info.getValue() === 1) return 'Pendiente'
            if(info.getValue() === 2) return 'Aceptada'
            if(info.getValue() === 3) return 'Cancelada'
        }
    },
    {
        header: "Comprobante",
        accessorKey: "status",
        cell: (info) => {
            return <Button onClick={() => console.log(info.row.original.agencyId._id)} key={info.row.original._id}>VER MÁS</Button>
        },

    }
]

const Purchase = ({ agencyId}) => {
    const [purchases, setPurchases] = useState([]);
    // const [open, setOpen] = useState(false);
    const table = useReactTable({data: purchases, columns, getCoreRowModel: getCoreRowModel()});
    
    // const onModal = () => {
    //     setOpen(!open)
    // }

    useEffect(() => {
        getPurchases(agencyId)
        .then((resp) => setPurchases(resp))
        .catch((err) => console.log(err))
    }, [])
    
    return ( <div>
        <Badge>
        PAQUETES
        </Badge>
        <div>
        <TableContainer>
  <thead>
    {
        table.getHeaderGroups().map((headerGroup) => <TableRow key={headerGroup.id}>
            {
                headerGroup.headers.map((header) => <TableHeader key={header.id}>{header.column.columnDef.header}</TableHeader>)
            }
        </TableRow>)
    }
  </thead>
  {
    purchases.length >= 1 ? (<tbody>
        {
            table.getRowModel().rows.map((row) => (<TableRow key={row.id}>
                {
                    row.getVisibleCells().map((cell) =>( <TableCell>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>))
                }
            </TableRow>))
        }
      </tbody>): ""
  }
</TableContainer>
        </div>
        {/* <Modal
      open={open}
      onClose={onModal}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Management>
            <h4>Gestionar reserva</h4>
        </Management>
    </Modal> */}
    </div> );
}
 
export default Purchase;