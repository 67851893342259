import { useState, useEffect } from 'react';

export let useCheckWords = (hotel) => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    // Esta función se ejecutará cada vez que cambie el objeto hotel
    // y se encargará de actualizar el estado words según las condiciones


    const facilitiesToWords = {
        gym: "Gimnasio",
        safebox: "Caja Fuerte",
        airconditioning: "Aire Acondicionado",
        sauna: "Sauna",
        park_auto: "Estacionamiento",
        tennis: "Tenis",
        tv: "TV",
        poolHot: "Piscina Climatizada",
        film: "Sala de Cine",
      };
  
      const newWords = Object.keys(facilitiesToWords).reduce((acc, facility) => {
        if (hotel.hotelsFacilities[facility] === "true") {
          acc.push(facilitiesToWords[facility]);
        }
        return acc;
      }, []);


    // Actualizar el estado words solo una vez al final, con todas las palabras recopiladas
    setWords(newWords);
  }, [hotel]); // El efecto se ejecutará cuando el objeto hotel cambie

  return { words };
};
