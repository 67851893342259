import useColumns from "../../hooks/packages/useColumns";
import { MainContent } from "../../styledComponents/packages/mainLayout";
import { usePackages } from "../../hooks/packages/usePackages";
import { Box } from "../Nationals/Nationals";

const Internationals = () => {
  const { packages } = usePackages("internacionales");
  const options = [
    <option value="PAQUETES DE PLAYA">Paquetes de playa</option>,
    <option value="MONTAÑAS">Montañas</option>,
    <option value="REGULARES">Regulares</option>,
    <option value="GRUPALES">Grupales</option>,
    <option value="EXOTICOS">Exóticos</option>,
  ];
  const { renderRoute, renderColumns } = useColumns({
    packages,
    initialOption: "PAQUETES DE PLAYA",
    options,
  });

  return (
    <>
      {renderRoute()}
      <MainContent>
        <Box style={{ gap: "2.5rem" }}>{renderColumns()}</Box>
      </MainContent>
    </>
  );
};

export default Internationals;
