import React, { useEffect, useState } from "react";
import styled, {keyframes} from "styled-components";
import InputGroup from "./InputGroup";
import MobilePaxForm from "./MobilePaxForm";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 17px 17px;
  margin: 0 var(--margin-large) 0 var(--margin-large);
  gap: 1rem;

  h5{
    margin: 0;
  }

  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;


  @media screen and (max-width: 876px) {
    padding: 1rem;
    margin: 0 1rem;
}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 768px) {

  }
`
const PaxForm = ({hotel, evaluatedSearch, formRef, scrollRef}) => {

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const actualizarAnchoPantalla = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', actualizarAnchoPantalla);

    return () => {
      window.removeEventListener('resize', actualizarAnchoPantalla);
    };
  }, []);

  return (
    <>
      <Container ref={scrollRef}>
        <h5>Registro</h5>
        <Content>
        <InputGroup formRef={formRef} evaluatedSearch={evaluatedSearch} hotel={hotel}/>
        </Content>
      </Container>
    </>
  );
};

export default PaxForm;