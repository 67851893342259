import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Select } from "./SearchStyle";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;

  margin-left: 5%;
  margin-right: 5%;
  

  div {
    display: flex;

    align-items: center;
  }
  label {
    font-size: 11px;
  }
  
`;

const Container2 = styled.div`
  height: 100%;
  max-width: 100%;
 
  @media screen and (max-width: 1366px) {
    
  }
`;

const Container3 = styled.div`
  margin-left: 3%;
  gap: 50px;

  input {
    cursor: pointer;
    height: 23px;
    width: 23px;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-color: #fff;
    appearance: none;
    border: none;
    border-radius: 5px;

    margin-right: 8px;
  }

  input:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  @media screen and (max-width: 400px) {
  gap: 20px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;


  div {
    /* max-width: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;


    label {
      margin-left: 8px;
      font-size: 11px;
    }
    select {
      width: 80px;
    }
  }

  @media screen and (max-width: 900px) {
    justify-content: space-between;
    gap: 10px;
    div {
    max-width: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 5px;

    label {
      margin-left: 8px;
      font-size: 11px;
    }
    select {
      width: 100%;
    }
  }
  }
 

  @media screen and (max-width: 1022px) {
    gap: 10px;
    justify-content: baseline;
    div {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    label {
      margin-left: 8px;
      font-size: 11px;
    }
    select {
      width: 100%;
    }
  }
  }



  @media screen and (max-width: 1200px) {
    gap: 10px;
    div {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    label {
      margin-left: 8px;
      font-size: 11px;
    }
    select {
      width: 100%;
    }
  }
  }

  @media screen and (max-width: 1020px) {
    gap: 10px;
    div {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    label {
      margin-left: 8px;
      font-size: 11px;
    }
    select {
      width: 100%;
    }
  }
  }

  
`;
const Separator = styled.div`
  display: flex;
  justify-content: center;

  hr {
    height: 2px;
    background-color: #cccbcb;
    margin-left: 0%;
    width: 531.5px;
    border: none;
    margin: 0;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  @media screen and (max-width: 1366px) {
    hr {
      
      width: 90%;
    }
  }
`;

const RoomFilter = ({ handleChange, enabled, num, room }) => {


  return (
    <>
      <Container2 style={{ opacity: !(enabled >= num) ? "0.2" : "1" }}>
        <Container>
          <Container3>
            <div>
              <label htmlFor="">Habitación</label>
            </div>
            <div>
              <input disabled={!(enabled >= num)} type="checkbox" />
              <label htmlFor="">Cuna</label>
            </div>
            <div>
              <input disabled={!(enabled >= num)} type="checkbox" />
              <label htmlFor="">Cama extra</label>
            </div>
          </Container3>
        </Container>
        <Separator>
          <hr />
        </Separator>
        <SelectContainer>
          <div>
            <label htmlFor="">Tipo</label>
            <Select
              name={`room${num}.type`}
              value={room.type}
              disabled={!(enabled >= num)}
              onChange={handleChange}
              defaultValue={"twn"}
              key={num}
            required
            >
              <option value="" disabled>Selecione una opción</option>
              <option value="sgl">Simple</option>
              <option  value="twn">Twin</option>
              <option value="dbl">Doble</option>
            </Select>
          </div>
          <div>
            <label htmlFor="adults">Adultos</label>
            <Select
              type="number"
              id="adults"
              value={room.adults}
              name={`room${num}.adults`}
              onChange={handleChange}
              disabled={!(enabled >= num)}
              required
            >
              <option value="" disabled>Cantidad</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Select>
          </div>
          <div>
            <label htmlFor="">Niños</label>
            <Select
              value={room.childs}
              name={`room${num}.childs`}
              onChange={handleChange}
              disabled={!(enabled >= num)}
            >
              <option value="" disabled></option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Select>
          </div>
          <div>
            <label htmlFor="">Infantes</label>
            <Select
              value={room.infants}
              name={`room${num}.infants`}
              onChange={handleChange}
              disabled={!(enabled >= num)}
            >
              <option value="" disabled></option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Select>
          </div>
        </SelectContainer>
      </Container2>
    </>
  );
};

export default RoomFilter;
