import styled from "styled-components";

export const RoomGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`;

export const Input = styled.input`
  max-width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(217, 217, 217, 0.6);
  border: 1px solid whitesmoke;

  &:focus{
    outline: 1px solid rgba(236, 104, 28, 1);
  }

  @media screen and (max-width: 768px) {
  }
`;
export const Select = styled.select`
  max-width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(217, 217, 217, 0.6);
  border: 1px solid whitesmoke;

  @media screen and (max-width: 768px) {
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
export const Button = styled(Input)`
  background-color: #ec681c;
  color: white;
  font-weight: 600;
`;
export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    label{
      user-select: none;
    }
`;