import { useEffect, useState } from "react";
import styled from "styled-components";
import SvgIcon from "./SvgIcon";

export const Button = styled.div`
  position: fixed;
  bottom: ${(props) => (props.backTop ? "120px" : "40px")};
  right: 40px;
  height: 62px;
  width: 62px;
  background-color: transparent;
  border-radius: 30px;
  cursor: pointer;
  z-index: 300;
  overflow: hidden;
`;

const WButton = () => {
  const [backTop, setBackTop] = useState(false);

  const goToWhatsapp = () => {
    window.open(
      "https://wa.me/message/O25R2ZQRRNX3G1",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    const footer = document.getElementById("footer");
    const footerPosition = footer.getBoundingClientRect().top;

    const handleScroll = () => {
      const currentPosition = window.pageYOffset + window.innerHeight;
      if (currentPosition >= footerPosition) {
        setBackTop(true);
      } else {
        setBackTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Button onClick={goToWhatsapp} backTop={backTop}>
        <SvgIcon />
      </Button>
    </>
  );
};

export default WButton;
