import React, { useState } from "react";
import { images } from "./data";
import {
  Container,
  Title,
  Grid,
  Image,
  ImageContainer,
  HoverText,
} from "./GalleryStyle";
import ModalComponent from "./ModalComponent";
import FullGallery from "./FullGallery";

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [showFullGallery, setShowFullGallery] = useState(false);

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowModal(true);
  };

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFullGallery = () => {
    setShowFullGallery(!showFullGallery);
  };

  return (
    <>
      <Container>
        <Title>
          <h2>Fotos de nuestros viajes grupales</h2>
          <p>
            Guardamos cada viaje en nuestro corazón, te compartimos acá algunos
            recuerdos
          </p>
        </Title>
        <Grid>
          {images.slice(0, 4).map((link, i) => {
            const isHovered = hoveredIndex === i;
            return (
              <ImageContainer
                key={i}
                isHovered={isHovered}
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleImageClick(link.url)}
              >
                <Image
                  // isHovered={isHovered}
                  src={link.url}
                  alt=""
                />
                {isHovered && <HoverText>{link.text}</HoverText>}
              </ImageContainer>
            );
          })}
        </Grid>
        <span
          onClick={handleFullGallery}
          style={{
            textAlign: "center",
            color: "#595959",
            fontWeight: "600",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          Ver galería
        </span>
      </Container>
      <ModalComponent
        {...{
          show: showModal,
          onClose: handleCloseModal,
          imageUrl: selectedImageUrl,
        }}
      />
      <FullGallery
        {...{ showFullGallery, setShowFullGallery, handleFullGallery }}
      />
    </>
  );
};

export default Gallery;
