import { useState } from "react";


const useFilters = () => {
    const [scrollReload, setScrollReload] = useState(0)
    const [isFiltered, setIsFiltered] = useState(false);
    const [hasMore, setHasMore] = useState(true);


    return { isFiltered, setHasMore, setIsFiltered, hasMore, scrollReload, setScrollReload };
}
 
export default useFilters;