import { useEffect, useState } from "react";
import styled from "styled-components";
import Columns from "../../pages/Nationals/components/Columns/Columns";
import { Input } from "../../components/NewSearch/SearchStyle";

const Select = styled(Input).attrs({ as: "select" })`
  width: 50%;
`;

const RouteContainer = styled.div`
  margin: 2.4rem 0 2.4rem var(--margin-large);
  display: flex;
  gap: 1rem;
  align-items: center;

  h4 {
    color: black;
    margin: 0;
  }

  select {
    display: none;
  }

  @media screen and (max-width: 876px) {
    select {
      display: block;
    }
  }

  @media screen and (max-width: 1022px) {
    margin: 2rem 0 0.3rem 0.5rem;
    font-size: 12px;
  }
`;

const useColumns = ({ packages, initialOption, options }) => {
  const isMobile = window.innerWidth < 876;
  const [categories, setCategories] = useState([]);
  const [option, setOption] = useState(initialOption);

  const handleOptions = (e) => {
    setOption(e.target.value);
  };

  const renderColumns = () => {
    return (
      <>
        {isMobile && (
          <Columns
            {...{
              packages,
              columnCard: true,
              label: option,
            }}
          />
        )}
        {!isMobile &&
          categories.map((item, index) => (
            <Columns
              {...{
                key: `${index}_${item}`,
                packages,
                columnCard: true,
                label: item,
              }}
            />
          ))}
      </>
    );
  };

  const renderRoute = () => {
    return (
      <RouteContainer>
        <h4>{window.location.pathname.substring(1).toUpperCase()}</h4>
        <Select onChange={handleOptions}>{options}</Select>
      </RouteContainer>
    );
  };

  useEffect(() => {
    setCategories(
      Array.from(new Set(packages.map((item) => item.type.toUpperCase())))
    );

    return () => setCategories([]);
  }, [packages]);

  return { renderColumns, renderRoute };
};

export default useColumns;
