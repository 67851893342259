
const IPlane = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 13.74 13.81"
    {...props}
    width={"30px"}
  >
    <path
      d="M6.22 14a1.54 1.54 0 0 1-1.5-1.5.9.9 0 0 1 .1-.5l1.1-3.1h-2.2l-.6.9a1.72 1.72 0 0 1-1.4.8 1.54 1.54 0 0 1-1.5-1.5.9.9 0 0 1 .1-.5l.5-1.5-.5-1.5a1.26 1.26 0 0 1 .2-1.3 1.7 1.7 0 0 1 2.3-.3l.4.4.6.9H6L4.9 2.2A1.4 1.4 0 0 1 5.1.8 1.72 1.72 0 0 1 7.4.5a1 1 0 0 1 .4.5l2.4 4.2h1.9a1.83 1.83 0 0 1 1.8 1.4 1.77 1.77 0 0 1-1.4 2h-2.3l-2.4 4.3A1.75 1.75 0 0 1 6.22 14ZM3.56 7.69h3.16a.65.65 0 0 1 .6.6v.2l-1.4 3.9a.37.37 0 0 0 .1.3.48.48 0 0 0 .7 0l.1-.1L9.42 8a.55.55 0 0 1 .5-.3h2.3a.65.65 0 0 0 .6-.6V7a.63.63 0 0 0-.6-.5H10a.55.55 0 0 1-.5-.3L6.9 1.7a.43.43 0 0 0-.7-.2.1.1 0 0 0-.1.1c-.1.1-.1.2-.1.4l1.4 3.9a.57.57 0 0 1-.4.7H3.42a.55.55 0 0 1-.5-.3l-.8-1.2a.51.51 0 0 0-.33-.23.47.47 0 0 0-.26 0 .31.31 0 0 0-.14.21.38.38 0 0 0 0 .19L2 7a.51.51 0 0 1 0 .22.49.49 0 0 1 0 .18l-.6 1.7a.31.31 0 0 0 0 .19.28.28 0 0 0 .23.21h.1a.55.55 0 0 0 .5-.3A9.69 9.69 0 0 0 3 8a.62.62 0 0 1 .19-.22.61.61 0 0 1 .37-.09Zm-.22-.22"
      style={{
        fill: "#878889",
      }}
      transform="translate(-.22 -.18)"
    />
  </svg>
)
export default IPlane;
