import styled from "styled-components";

export const MainContainer = styled.div`
  margin: 5.8rem var(--margin-large) 5.8rem var(--margin-large);
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media screen and (max-width: 875px) {
    margin: 20px;
  }
`

export const Container = styled.form`
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 18px;
  display: grid;
  grid-template-columns: auto auto auto;
  flex-direction: column;
  align-items: center;
  padding: 70px 30px 70px 30px;
  
  /* user-select: none; */

  gap: 5px;

  @media screen and (max-width: 875px) {
    display: flex;
    flex-direction: column;

    /* margin: 20px; */
    padding: 20px;
    gap: 30px;
  }
`;

export const DetailsContainer = styled.div`
  /* border: 1px solid yellow; */

  h5, h4 {
    display: flex;
    flex-direction: column;
    color: rgba(89, 89, 89, 1);
    gap: 8px;
    max-width: 50%;
  }
  h4{
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  @media screen and (max-width: 875px) {
    h5, h4{
      max-width: 100%;
      gap: 5px;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  /* border: 1px solid orange; */
  /* justify-content: space-between; */
  gap: 4rem;

   ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -0.8rem;
      width: 2px;
      height: 100%;
      background-color: #ebebeb;
      transition: opacity 0.3s ease;
      z-index: -1;
    }
    /* Otros estilos de las listas... */
  }

  


  li {
    display: flex;
    gap: 5px;
  }
  .li_column {
    flex-direction: column;
  }

  @media screen and (max-width: 875px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const ImageContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  img {
    border-radius: 50px;
    width: 150px;
    height: 150px;
  }
`;

export const EditContainer = styled.div`
  /* border: 1px solid green; */
  display: flex;
  /* justify-content: flex-end; */
  height: 100%;
  gap: 5px;

  button {
    width: 100%;
    height: 35px;
    border-radius: 20px;
    border: none;
    color: white;
    background-color: rgba(236, 104, 28, 1);
    font-weight: 600;
    margin: auto 0 0 0;
  }

  @media screen and (max-width: 875px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 20px;
    button {
      width: 100%;
    }
  }
`;

export const ErrorMesage = styled.div`
  /* fontSize: "14px", color: "red" */
  font-size: 0.81rem;
  color: red;
  font-weight: 100;
`;

export const Label = styled.label`
  color : rgba(89, 89, 89, 1);
  font-size: 0.81rem;
  font-weight: 200;
`

export const Input = styled.input`

width: 100%;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem;
  /* font-size: 0.688rem; */
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;



  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: whitesmoke;
  }
`