import styled from "styled-components";
import { links } from "../../../data";

export const CarouselContainer = styled.div`
  margin: 93px var(--margin-large) 30px var(--margin-large);
  *:focus {
    outline: 0;
    outline: none;
  }
  @media screen and (max-width: 874px) {
    /* margin: 50px 30px 30px 30px; */
    /* margin-top: 60px; */
    margin: 0;
  }

  
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
`;
export const StyledImage = styled.img`
  width: 100%;
  /* height: 60vh; */
  object-fit: contain;

  @media screen and (max-width: 900px) {
    /* height: 50vh; */
  }
  @media screen and (max-width: 1300px) {
    /* height: 50vh; */
    /* object-fit: contain; */
  }
`;

export const DivImage = styled.div`
  background-image: url(${links.banner_main});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
`;
