import { Modal } from "@mui/material";
import {
  Button,
  Container,
  Content,
  Form,
  Input,
  InputContainer,
  ErrorMessage
} from "./LoginStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { logIn } from "../../../services/auth/login.service";
import { setLoading, setUser } from "../../../redux/main.slice";
import { useDispatch } from "react-redux";
import { commonAlert } from "../../../utils/alerts";
import { forgotPassword } from "../../../services/auth/user.service";
import { useState } from "react";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Ingrese un correo electrónico válido")
    .required("El Correo Electrónico es obligatorio"),
  password: Yup.string().required("La Contraseña es obligatoria"),
});

const Login = ({ open, setOpen }) => {

  const [forgotShow, setForgotShow] = useState(false)

  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(!open);
    formik.resetForm();
  };

  const forgotForm = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: async(values) => {
      try{
        dispatch(setLoading(true))
          await forgotPassword(values)
          
      }
      catch(err){
          console.log(err)
          
      }
      finally{
        setForgotShow(false)
        setOpen(false)
        dispatch(setLoading(false))
      }
    }
  })

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const resp = await logIn(values);
        localStorage.setItem("token", JSON.stringify(resp.data.token));
        dispatch(setUser());
        setOpen(false);
        dispatch(setLoading(false));
        commonAlert("Inicio de sesión confirmado!", "", "success")
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false))
        commonAlert("Error al iniciar sesión", "", "error")
      }
    },
  });

  return (
    <Modal
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container>
        <Form onSubmit={formik.handleSubmit}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
          <h4>Iniciar Sesión</h4>
          <span style={{userSelect: "none", cursor: "pointer"}} onClick={handleOpen}>X</span>
            </div>
          <Content>
            <InputContainer>
              <Input
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMessage>{formik.errors.email}</ErrorMessage>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="Contraseña"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMessage>{formik.errors.password}</ErrorMessage>
              )}
            </InputContainer>
          </Content>
          <Button type="submit">Ingresar</Button>
        <span 
        onClick={() => setForgotShow(true)}
        style={{fontSize: "12px", textDecoration: "underline", textAlign: "center", cursor: "pointer", userSelect: "none"}}>Olvidé mi contraseña</span>
        </Form>
        <Modal 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={forgotShow}
        onClose={() => setForgotShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
          <Container>
            <Form onSubmit={forgotForm.handleSubmit}>
              <h4>Recuperar contraseña</h4>
              <Content style={{flexDirection: "column", gap: "1rem"}}>
                <InputContainer>
                  <Input type="text" onChange={forgotForm.handleChange} name="email" value={forgotForm.values.email} placeholder="Ingresa tu correo electronico" />
                </InputContainer>
                <Button style={{width :"100%"}} type="submit">Enviar<area shape="" coords="" href="" alt="" /></Button>
              </Content>
            </Form>
          </Container>
        </Modal>
      </Container>
    </Modal>
  );
};

export default Login;
