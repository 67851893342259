import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;


export const fetchCarouselItems = async() => {

    try{
        const data = await axios.get(`${API_URL}/admin/package/getimage`)
        return data.data;
    }
    catch(err){
        return;
    }
}

export const getAdvertisement = async () => {

    try{
        const data = await axios.get(`${API_URL}/advertisement`)
        console.log(data.data, "asdasdasd")
        return data.data;
    }
    catch(err){
        return;
    }
}