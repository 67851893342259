import React from "react";
import styled from "styled-components";

const Table = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 120px 1fr;
  grid-template-areas:
    "head1 item1"
    "head1 item2"
    "head1 item3"
    "head1 item4"
    ". .";
`;
const Heading = styled.div`
  grid-area: head1;
  border-radius: 10px;
  background-color: whitesmoke;
  color: rgba(148, 148, 148, 1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 600;
  padding: 15px;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 15px;
  gap: 25px;

  button {
    background-color: rgba(236, 104, 28, 1);
    color: white;
    padding: 10px 0px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    width: 100%;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResponsiveRoom = ({ agreement, rooms, navigate, basis, deadline }) => {
  const dateConverter = (date) => {
    const originalDate = new Date(date);
    const day = String(originalDate.getDate()).padStart(2, "0");
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const year = originalDate.getFullYear();
    const hours = String(originalDate.getHours()).padStart(2, "0");
    const minutes = String(originalDate.getMinutes()).padStart(2, "0");

    const convertedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return convertedDate;
  };

  return (
    <>
      {rooms?.map((room, i) => {
        let qroom = room.room;

        if (!Array.isArray(room.room)) {
          qroom = [room.room];
        }
        let roomtype = room.room_type.replace(
          /Non Refundable|Refundable/gi,
          ""
        );
        let refundable = room.room_type.split(" ");
        let isRefundable = refundable.filter(
          (part) =>
            part.toLowerCase() === "refundable" || part.toLowerCase() === "non"
        );
        isRefundable =
          isRefundable.join(" ") === "Non Refundable"
            ? "NO REEMBOLSABLE"
            : `Cancelación hasta ${dateConverter(room?.deadline?.date)}
              `;
          if(room.deadline){
            return (
              <React.Fragment key={i}>
                <Table>
                  <Heading>
                    <span>Tipo de habitación</span>
                    <span>Comidas</span>
                    <span>Plazo de cancelación</span>
                    <span>Total</span>
                    <span></span>
                  </Heading>
                  <Card>
                    <Item style={{ gridArea: "item1" }}>
                      <span>{roomtype}</span>
                      <span>{`${qroom.length}x`}</span>
                    </Item>
                    <Item style={{ gridArea: "item2" }}>
                      <span>{room.room_basis}, {room.meal_basis}</span>
                    </Item>
                    <Item style={{ gridArea: "item3" }}>
                      <span>{isRefundable}</span>
                    </Item>
                    <Item style={{ gridArea: "item4" }}>
                      <span style={{ fontWeight: "bold" }}>
                        USD${room?.totalRoomsPrice}
                      </span>
                    </Item>
                  </Card>
                  <Card>
                    <button onClick={() => navigate(room)}>SELECCIONAR</button>
                  </Card>
                </Table>
              </React.Fragment>
            );
          }
      })}
    </>
  );
};

export default ResponsiveRoom;
