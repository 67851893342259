const statusParse = (code) =>{
    if(code === "cnf") return  "CONFIRMADA"
    if(code === "pnd") return "PENDIENTE"
    if(code === "cxl") return "CANCELADA"
    if(code === "rej") return "RECHAZADA"
    if(code === "snd") return "ENVIADA"

    return "untracked"
}

export default statusParse;