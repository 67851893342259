import styled from "styled-components";
export const ContainerSeparator = styled.div`
  height: 161px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  margin: 8rem 0;
`;
export const ParallaxBackground = styled.div`
  background-image: url(${(props) => props.link});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: grayscale(50%);  */
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;
const Separator = ({link}) => {
  return (
    <ContainerSeparator>
      <ParallaxBackground link={link} ></ParallaxBackground>
    </ContainerSeparator>
  );
};

export default Separator;
