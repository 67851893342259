import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 0.6rem 0.6rem;
  font-size: 0.688rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;



  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: whitesmoke;
  }
`;
export const Select = styled(Input).attrs({ as: "select" })`

  
`;

export const Container = styled.form`
  background-color: whitesmoke;
`;
export const Heading = styled.div`
  background:#E5E4E4;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 120px;

  h2 {
    margin-bottom: 0;
  }
`;

export const Tab = styled.div`
  display: flex;
  margin-left: var(--margin-large);

  span {
    user-select: none;
    color: rgb(89, 89, 89);
    cursor: pointer;
    background-color: #E5E4E4;
    padding: 10px 20px;
    border-radius: 0 0 10px 10px;
  }

  .active{
    background-color: #D6D6D6;
  }

  @media screen and (max-width: 1022px){
      margin: 0;
    }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;

 
  gap: 1.9rem;

  .first{
    width: 68%;
    margin-left: 7rem;

    @media screen and (max-width: 1022px){
      margin: 0;
    }
  }

  .sec{
    gap: 1.9rem;
    margin-right: 7.10rem;

    @media screen and (max-width: 1022px){
      margin: 0;
    }
  }

  .nights{
    width: 15%;
  }
  
  

 

 

  @media screen and (max-width: 1022px) {
    font-size: 12px;
   
    flex-direction: column;
    gap: 10px;
    margin: 0;
    .first {
      width: 100%;
    }
    .sec {
      width: 100%;
      justify-content: space-between;
      
    }
    .date {
      
      width:30%;
    }
    .nights {
      label {
        display: inline-block;
      }
    }
  }
`;

export const ContainerTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;

  .date input{
    width: 120%;
  }

  .date{
    position: relative;
  }

.date input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
  
  
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  label{
    font-size: 0.688rem;
  }
  
  .center{
    border: 1px solid red;
  }

  @media screen and (max-width: 878px) {
    margin: 0 !important;
  }

`;

export const Content = styled.div`
  margin:70px 100px 0 100px;
  

  .flecha-abajo{
    position: absolute;
  top: 60%;
  right: -39.5px; /* Ajusta la posición horizontal del ícono */
  transform: translateY(-50%);
  width: 90px;
  height: 90px;
  pointer-events: none;
  }


    @media screen and (max-width: 1022px){
      margin: 1rem;
    }
  `;


export const Content7 = styled.div`

margin-top: 70px;


@media screen and (max-width: 1022px){
      margin-top: 2rem;
    }

`;

export const Content8 = styled.div`

    display: flex;
    justify-content: end;
    gap: 10px;
    margin: 50px var(--margin-large) 0 var(--margin-large);

    button {
      background-color: rgb(236, 104, 28);
      color: white;
      padding: 0.6rem 5rem;
      border: none;
      border-radius: 15px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;

      span{
        font-weight: 300;
      }
}

@media screen and (max-width: 1022px){
      margin: 50px 1rem 0 1rem;

      button{
        width: 100%;
      }
    }

`;

export const FilterContainer = styled.div`

/* style={{ margin: "0 var(--margin-large) 0 var(--margin-large)"}} */
margin: 0 var(--margin-large) 0 var(--margin-large);

@media screen and (max-width: 1170px) {
    margin: 0 1rem 0 1rem;
  }
`