import { useState } from "react";
import { Select } from "../styles/FormStyle";
import { Column, Label, Span } from "../styles/ItineraryStyle";

const Variables = ({ selects, texts, selectTitle, formik }) => {

    const handleSelectChange = (e, index) => {
        const option = e.target.value;
        const updatedSelects = formik.values.selects.map((select, i) => {
          if (i === index) {
            return { title: formik.values.selects[index].title, option };
          }
          return select;
        });
        formik.setFieldValue("selects", updatedSelects);
      };

  return (
    <Column style={{gap: "3rem", marginTop: "2rem"}}>
        <Column>
      {texts?.map((item, index) => (
          <Column  key={index}>
          <Span>{item.title}</Span>
          <p>{item.text}</p>
        </Column>
      ))}
      </Column>
      <Column style={{gap: "1rem"}}>
        {selects?.length >= 1 && <Span>{selectTitle}</Span>}
        <div style={{display: "flex", gap: "1.5rem", flexWrap: "wrap"}}>
          {selects?.map((item, index) => (
            <Column key={index} style={{gap :"0.8rem"}}>
                <Label style={{fontSize: "12px"}} htmlFor={index}>{item.title}</Label>
                <Select onChange={(e) => handleSelectChange(e, index)}>
                    {item.options?.map((option,indexs) => <option value={option} key={indexs}>{option}</option>)}
                </Select>
            </Column>
          ))}
        </div>
      </Column>
    </Column>
  );
};

export default Variables;
