import axios from "axios";
import jwt from "jwt-decode";
import { commonAlert } from "../../utils/alerts";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_URL

export const getUserById = async (id) => {
  try {
    const data = await axios.get(`${API_URL}/user/${id}`);
    const bookings = await axios.get(`${API_URL}/admin/book?id=${id}`);
    let references = bookings.data.map((item) => {
        if(!item.source) return item.reference_code
    });
    let response =  await axios.get(
      `${API_URL}/netstorming/bookTracking?reference=${references.join(",")}`
    );

    let trackingData = response.data;

    if (!Array.isArray(trackingData)) trackingData = [trackingData];

    const combinedData = bookings.data.map((item) => {
      const trackingItem = trackingData.find(
        (tracking) => tracking.reference.code === item.reference_code
      );
      if (trackingItem) {
        return { ...item, tracking: trackingItem };
      }
      return item;
    });
    data.data.bookings = combinedData;
    return data.data;
  } catch (e) {
    console.log(e);
    alert("No se encontró el usuario");
  }
};

export const updateUser = async (user) => {
  try {
    const data = await axios.put(`${API_URL}/user/${user._id}`, user);
    commonAlert( "Información actualizada", "", "success")
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const forgotPassword = async(body) => {

  try{
       await axios.post(`${API_URL}/user/forgot-password`, body)
       Swal.fire({
        title: "Te enviamos un correo para recuperar tu contraseña!",
        icon: "success",
      });
  }
  catch(err){
    Swal.fire({
      title: "Este correo no existe!",
      icon: "error",
    });
  }
}

export const createNewPassword = async (newPassword, token) => {
  try {
    let decoded = jwt(token);
    await axios.post(
      `${API_URL}/user/new-password`,
      { newPassword, id: decoded.userId },
      { headers: { Reset: token } }
    );
  } catch (err) {
    console.log(err);
  }
};

export const verifyRecovery = async (token) => {
  try {
    const response = await axios.post(
      `${API_URL}/user/verify-recovery`,
      {},
      {
        headers: {
          Reset: token,
        },
      }
    );

    if (response.data && response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};


export const addHotelFav = async(id, method) => {

  const token = localStorage.getItem("token");
  const cookie = document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith("accessToken="));

  const tokenCookie = cookie?.substring("accessToken=".length);
  const headers = {
    Authorization: `Bearer ${
      tokenCookie ? JSON.stringify(tokenCookie) : token
    }`,
  };
  const data = await fetch(`${API_URL}/user/addhotel?hotelId=${id}&method=${method}`, {
    headers,
    method: "PATCH",
  })
  
  return data.json()
};
