import Card from "../Card/Card";
import {
  Column,
  ColumnContainer,
  Header,
} from "../../../../styledComponents/packages/ColumnsStyle";
import { useState, useEffect } from "react";

const Columns = ({
  label,
  isFirst,
  isLast,
  travelType,
  columnCard,
  isGroup,
  region,
  packages,
}) => {
  const [packagesFiltered, setPackagesFiltered] = useState([]);

  useEffect(() => {
    const filteredPackages = packages
      //   .filter((item) => item.travelType === travelType.toLowerCase())
      .filter((item) =>
        isGroup ? item.region === region : item.type === label.toLowerCase()
      );

    setPackagesFiltered(filteredPackages);
  }, [packages, travelType, isGroup, region, label]);

  return (
    <ColumnContainer isFirst={isFirst} isLast={isLast}>
      <Header>{label.toUpperCase()}</Header>
      <Column>
        {packagesFiltered.length > 0 ? (
          packagesFiltered.map((travel, index) => (
            <Card
              key={index}
              travel={travel}
              onRoute={true}
              columnCard={columnCard}
            />
          ))
        ) : (
          <div style={{ maxWidth: "250px" }}>
            <h5>¡Muy pronto nuevos paquetes!</h5>
            <p>
              Escribinos para cotizar el destino que buscás y vivir el viaje de
              tu vida.
            </p>
          </div>
        )}
      </Column>
    </ColumnContainer>
  );
};

export default Columns;
