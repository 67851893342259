import { Modal } from "@mui/material";
import styled from "styled-components";
import { images } from "./data";
import { HoverText } from "./GalleryStyle";
import { useState } from "react";

const Section = styled.section`
  line-height: 0;
  -webkit-column-count: 4;
  -webkit-column-gap: 10px;
  -webkit-row-gap: 10px;
  -moz-column-count: 4;
  -moz-column-gap: 10px;
  -moz-row-gap: 10px;
  column-count: 4;
  column-gap: 10px;
  row-gap: 10px;
  margin: 5rem;

  img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }


@media (max-width: 1200px) {

  column-count: 3;
  margin: 5rem;
}

@media (max-width: 980px) {

    column-count: 2;
    margin: 3rem;
}

@media (max-width: 640px) {
    column-count: 1;
    margin: 1rem;

  }
`;
const ImageContainer = styled.div`
  position: relative;
  height: 300px;
  margin-bottom: 10px;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: ${(props) =>
    props.hovered ? "1" : props.hoveredIndex != null ? "0.5" : "1"};
`;

const FullGallery = ({
  handleFullGallery,
  showFullGallery,
  setShowFullGallery,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      style={{
        overflow: "auto",
        background: "white",
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'white', // Cambia el color y la opacidad según tus preferencias
        },
      }}
      onClose={handleFullGallery}
      open={showFullGallery}
    ><div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
      <h2 style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", margin: "2rem", position:"relative"}}>
        <span onClick={handleFullGallery} style={{position:"absolute", left:"2%", fontSize:"16px", userSelect:"none", cursor:"pointer", textDecoration:"underline"}}>Volver</span>
        <span style={{textAlign:"center"}}>Galería de fotos</span>
      </h2>
      <hr style={{border:"1px solid rgb(236, 104, 28)", width:"92%"}} />
      <Section class="photos">
        {images?.map((image, index) => {
          const isHovered = hoveredIndex === index;
          return (
            <ImageContainer
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              key={index}
              style={{ position: "relative" }}
              >
              <img src={image.url} alt="item" />
              {isHovered && <HoverText>{image.text}</HoverText>}
            </ImageContainer>
          );
        })}
      </Section>
          </div>
    </Modal>
  );
};

export default FullGallery;
