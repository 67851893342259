import { useEffect, useState } from "react";
import axios from "axios";

const useDollar = () => {
  const [dollar, setDollar] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/master/dolar")
      .then((resp) => {
        setDollar(resp.data);
      })
      .catch(() => "");
  }, []);
  return { dollar };
};

export default useDollar;
