import { Input } from "../../HotelCard/forms/form";
import { InputGroup } from "../styles/FormStyle";

const InputItem = ({formik, name, index, placeholder}) => {
    return ( <InputGroup>
        <Input
          onChange={formik.handleChange}
          value={formik.values.paxes[index][name]}
          onBlur={formik.handleBlur}
          name={`paxes.${index}.${name}`}
          placeholder={placeholder}
        />
        {formik.errors[name] && formik?.touched?.paxes[index][name] && (
          <span>{formik.errors[name]}</span>
        )}
      </InputGroup> );
}

export default InputItem;