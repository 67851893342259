import React, { useRef, useState } from "react";
import { Modal } from "@mui/material";
import { Button, ModalContent } from "../FooterStyle";
import { Input } from "../../../components/auth/Login/LoginStyle";
import { commonAlert } from "../../../utils/alerts";
import { sendMail } from "../../../services/mailer.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/main.slice";

const ModalFooter = ({ open, setOpen,textArea }) => {
  const refModal = useRef(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return "El correo electrónico es obligatorio";
    } else if (!emailRegex.test(email)) {
      return "Ingresa un correo electrónico válido";
    }

    return null;
  };

  const handleSubmit = async () => {
    const error = validateEmail(email);

    if (error) {
      setEmailError(error);
    } else {
      try {
        dispatch(setLoading(true));
        await sendMail(email, textArea);
        commonAlert(
          "La consulta fue enviada con exito!",
          "Nos estaremos comunicando a tu correo muy pronto",
          "success"
        );
        setEmailError("");
        setOpen(false);
        dispatch(setLoading(false));
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display: "flex",
        justifyContent: "center",
        alignItems: "center"}}
    >
      {/* <ModalContainer> */}
        <ModalContent ref={refModal}>
          <i
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              right: "3.5%",
              top: "4.5%",
              userSelect: "none",
            }}
          >
            X
          </i>
          <span htmlFor="">Déjanos tu correo para resolver tus dudas!</span>
          <Input
            type="text"
            placeholder="johndoe@gmail.com"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && (
            <span style={{ color: "red", fontSize: "12px" }}>{emailError}</span>
          )}
          <Button onClick={handleSubmit}>Enviar</Button>
        </ModalContent>
      {/* </ModalContainer> */}
    </Modal>
  );
};

export default ModalFooter;
