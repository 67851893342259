
export const isArray = (obj) => {
  let array = obj;
  if (!Array.isArray(array)) {
    array = [array];
  }
  return array;
};

export const roomCounts = (rooms) => {
  
  return isArray(rooms).reduce((counts, room) => {
    const roomType = room.type;
    const requiredCount = parseInt(room.required);
    if (!isNaN(requiredCount)) {
      counts[roomType] = (counts[roomType] || 0) + requiredCount;
    }
    return counts;
  }, {});
};
