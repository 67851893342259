import Autosuggest from "react-autosuggest";
import "../../NewSearch/Search.css"
import { Modal } from "@mui/material";
import {Content1,Content2,Content3,Content4,Content9,Filter, FilterContainer, Span, Button, Order, ResetFilter, HotelSearchContainer} from "../styles/filterStyles"
import { Input, InputContainer, Select } from "../../NewSearch/SearchStyle";
import MapView from "../../MapView/MapView";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setLoading, updateHotels } from "../../../redux/main.slice";
import { getPagination, hotelFilter } from "../../../services/hotels.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { scrollTo } from "../../../utils/scroll";

const Filters = ({setIsFiltered, setHasMore, setScrollReload, isList}) => {

  const hotels = useSelector((state) => state.mainSlice?.hotels)
  const [hotelSuggestion, setHotelSuggestion] = useState(hotels?.hotelsNameAndCoords)
  const [hotelSelected, setHotelSelected] = useState("")
  const [hotelCode, setHotelCode] = useState("")
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()


  const dispatch = useDispatch()
  const buildQuery = (values) => {
    const queryParams = [];
      for(let key in values){
        if(values[key]) queryParams.push(`${key}=${encodeURIComponent(values[key])}`);
      }
      return queryParams.join("&")
  }
  
  const formik = useFormik({
    initialValues: {
      room: "",
      category: "",
      food: "",
      stars: "",
      orderBy: "",
      orderType: "",
      minPrice: "",
      maxPrice: "",
      hotelName: hotelSelected,
    },
    onSubmit: async(values) => {
        try{
          setIsFiltered(true)
          setHasMore(true)
          values = {...values, orderBy: values.orderBy.split("-")[0], orderType: values.orderBy.split("-")[1], hotelCode}
          const querys = buildQuery(values);
          dispatch(setLoading(true));
          const data = await hotelFilter(querys);
          dispatch(updateHotels({
            ...data,
            hotels: data.hotels
          }))
          localStorage.setItem("filters", querys)
          setHotelCode("")
          dispatch(setLoading(false));
        }
        catch(err){
          console.log(err);
        }
    }
  })

  const resetFilters = async() => {
      try{
        setScrollReload(scrollReload => scrollReload + 1)
        setIsFiltered(false)
        setHasMore(true)
        dispatch(setLoading(true))
        const data = await getPagination(0, false)
        formik.resetForm()
        setHotelCode("")
        setHotelSelected("")
        localStorage.removeItem("filters")
        dispatch(updateHotels(data))
        navigate("/")
        setTimeout(() => {
          scrollTo("search")
        },300)
        dispatch(setLoading(false))
      }
      catch(err){
        console.log(err)
        dispatch(setLoading(false))
      }
  }

  const onSuggestionFetchRequested = ({value}) => {
      setHotelSuggestion(filterNames(value))
  }
  
  const filterNames = (value) => {
    const inputValue = value.trim().toLowerCase();
  
  
    let dataFiltered = hotels.hotelsNameAndCoords.filter((hotel) => {
      const normalizedHotelName = hotel.name.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
  
      return normalizedHotelName.includes(inputValue);
    });
  
    dataFiltered.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
  
      // Comparar las primeras letras de los nombres
      if (nameA.startsWith(inputValue) && !nameB.startsWith(inputValue)) {
        return -1;
      } else if (!nameA.startsWith(inputValue) && nameB.startsWith(inputValue)) {
        return 1;
      } else {
        // Si las primeras letras son iguales, ordenar alfabéticamente
        return nameA.localeCompare(nameB);
      }
    });
  
    return dataFiltered.length === 0 ? [] : dataFiltered;
  };
  

  const onSuggestionsClearRequested = () => {
    setHotelSuggestion([])
  }
  const getSuggestionValue = (suggestion) => {
    setHotelCode(suggestion.code)
    return suggestion.name
  }
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const onSuggestionSelected = (event, { suggestion }) => {
    event.target.value = suggestion.name;
    formik.setFieldValue("hotelName", suggestion.name);
    // setSuggestCode(suggestion.code);
    setHotelSuggestion(suggestion.name);
  };
  
  const handleNameChange = (e) => {
      // setHotelSelected(e.target.value);
      formik.setFieldValue("hotelName", e.target.value);
  }
  const inputProps = {
    placeholder: "Hotel",
    value: formik.values.hotelName,
    onChange: handleNameChange,
    name: "hotelName",
    // onKeyDown: onKeyDown,
    onblur: (event) => {
      // set cursor to beginning of input
      event.target.setSelectionRange(0, 0);
      formik.handleBlur(event);
    },
  };

  const handleOpen = () => {
    setOpen(!open);
  };


  return (<FilterContainer onSubmit={formik.handleSubmit}>
        <div>
            <Span>Filtros</Span>
        </div>
      {
        isList && <div style={{display: "flex", gap: "1rem"}}>
        <InputContainer style={{display: "flex", width: "100%"}}>
          <label htmlFor="">Destino</label>
          <Input value={JSON.parse(localStorage.getItem('cityNameSearch'))} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
        <InputContainer style={{display: "flex"}}>
          <label htmlFor="">Desde</label>
          <Input type="date" value={new Date(JSON.parse(localStorage.getItem("search")).checkIn).toISOString().split("T")[0]} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
        <InputContainer style={{display: "flex"}}>
          <label htmlFor="">Hasta</label>
          <Input type="date" value={new Date(JSON.parse(localStorage.getItem("search")).checkOut).toISOString().split("T")[0]} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
        <InputContainer style={{display: "flex"}}>
          <label htmlFor="">Simple</label>
          <Input value={JSON.parse(localStorage.getItem("search")).sgl} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
        <InputContainer style={{display: "flex"}}>
          <label htmlFor="">Doble</label>
          <Input value={JSON.parse(localStorage.getItem("search")).dbl} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
        <InputContainer style={{display: "flex"}}>
          <label htmlFor="">Twin</label>
          <Input value={JSON.parse(localStorage.getItem("search")).twin} disabled style={{background: "whitesmoke"}}/>
        </InputContainer>
      </div>
      }
    <Filter>
      <Content1>
        <InputContainer style={{maxWidth: "100px"}} className="food-container">
          <label htmlFor="food">Planes de comida</label>
          <Select
            id="food"
              onChange={formik.handleChange}
              value={formik.values.food}
            name="food"
          >
            <option value="">Por defecto</option>
            <option value="room only">Solo Habitación</option>
            <option value="breakfast">Desayuno incluido</option>
            <option value="lunch">Almuerzo incluido</option>
            <option value="dinner">Cena incluida</option>
            <option value="inclusive">Todo incluido</option>
          </Select>
        </InputContainer>
        <InputContainer style={{maxWidth: "100px"}} className="food-container">
          <label htmlFor="category">Categoría</label>
          <Select
            id="category"
            placeholder=""
            onChange={formik.handleChange}
            value={formik.values.category}
            name="category"
          >
            <option value="">Todas</option>
            <option value="T">Túrista</option>
            <option value="ST">Superior Túrista</option>
            <option value="F">Primera Categoria</option>
            <option value="SF">Superior Primera Categoria</option>
            <option value="D">Deluxe</option>
          </Select>
        </InputContainer>
        <InputContainer
          className="stars-container"
          name="food"
        >
          <label htmlFor="stars">Estrellas</label>
          <Select 
            value={formik.values.stars}
            onChange={formik.handleChange}
          name="stars" id="stars" placeholder="">
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </Select>
        </InputContainer>
      </Content1>
      <Content2>
        <label>Precio</label>
        <hr></hr>
        <Content4>
          <InputContainer
            className="from-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "45%",
                gap: "15px",
              }}
            >
              <div>
                <label htmlFor="minPrice">Desde</label>
                <Input name="minPrice" id="minPrice" type="number"  
            value={formik.values.minPrice}
            onChange={formik.handleChange} placeholder="" />
              </div>
              <div>
                <label htmlFor="maxPrice">Hasta</label>
                <Input name="maxPrice" id="maxPrice"
            value={formik.values.maxPrice}
            type="number" onChange={formik.handleChange} placeholder="" />
              </div>
            </div>
            <Content9>
              <label htmlFor="">Moneda</label>
              <Select disabled id="" placeholder="">
                <option value="USD">USD</option>
              </Select>
            </Content9>
          </InputContainer>
        </Content4>
      </Content2>
      <Content3>
        
      </Content3>
      
    </Filter>
      <HotelSearchContainer>
        <Order>
          <div>
        <Span>Ordenar</Span>
        <Select name="orderBy" onChange={formik.handleChange} >
            <option value="price-asc">Precio (menor a mayor)</option>
            <option value="price-desc">Precio (mayor a menor)</option>
            <option value="">Recomendaciones</option>
            <option value="">Categoría de estrellas</option>
        </Select>
          </div>
      </Order>
        <InputContainer style={{flexDirection: "row", alignItems: "center", gap: "20px", width: "100%"}}>
       <Span>Buscar</Span>
      {
        hotels?.hotelsNameAndCoords?.length && (<div style={{ width: "100%",}}>
          <Autosuggest 
          suggestions={hotelSuggestion}
          onSuggestionsFetchRequested={onSuggestionFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
        />
        </div> )
      }
     </InputContainer>
        </HotelSearchContainer>
        <InputContainer className="center">
          <Span htmlFor="">Ubicación</Span>
          <MapView formik={formik} setHotelCode={setHotelCode} height="400px" width="100%" hotels={hotels?.hotelsNameAndCoords} coords={hotels.hotelsNameAndCoords[0]?.coords}  zoom={13} />
          <small onClick={handleOpen} style={{fontWeight: 600, cursor: "pointer", userSelect: "none", marginLeft: "auto"}}>Ver mapa</small>
          <Modal 
          open={open}
          onClose={handleOpen}
          style={{ display: "flex", alignItems: "center", justifyContent: "center"}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
            <div style={{width: "100%", height: "50%"}}>
                 <MapView formik={formik} setHotelCode={setHotelCode} height="100%" width="100%" hotels={hotels?.hotelsNameAndCoords} coords={hotels.hotelsNameAndCoords[0]?.coords}  zoom={12} />
            </div>
          </Modal>
        </InputContainer>
        <div style={{ display: "flex", flexDirection: "column", marginLeft: "auto", justifyContent: "center", gap: "1rem"}}>
      <Button type="submit">FILTRAR</Button>
      <ResetFilter onClick={resetFilters} >BORRAR FILTROS</ResetFilter>
        </div>
  </FilterContainer>
  );
};

export default Filters;
 
 
