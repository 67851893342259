import styled from "styled-components";
import { Column } from "../../styles/ItineraryStyle";

export const ModalContainer = styled(Column)`
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 1rem;
  gap: 1.5rem;
  width: 500px;
  /* margin: 5rem; */
  align-items: center;

  h4 {
    margin: 0;
  }
  a {
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  max-height: 70vh; /* Por ejemplo, establece una altura máxima para el contenedor modal */
  overflow-y: auto; /* Añade scroll si es necesario */
`;

export const ImageContainer = styled.div`
  width: 100%;

  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }

  .overlay:hover {
    opacity: 1;
  }

  .buttons {
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;

    span {
      background-color: white;
      padding: 0.4rem;
      border-radius: 18px;
      cursor: pointer;
    }
  }

  .overlay:hover .buttons {
    opacity: 1;
  }
`;
