import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import mainReducer from "./main.slice";
import thunk from 'redux-thunk';

const middleware = [...getDefaultMiddleware(), thunk];


const store = configureStore({
  reducer: {
    mainSlice: mainReducer,
  },
  middleware,
  
});

export default store;
