
const IWhatsapp = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      baseProfile="tiny"
      overflow="visible"
      viewBox="0 0 45 50"
      {...props}
    >
      <g fill="#888">
        <path d="M34.7 14.3c-2.7-2.7-6.3-4.2-10.2-4-7.1.2-12.9 5.9-13.3 13-.1 2.5.4 5 1.5 7.2l-1.5 6c-.1.4 0 .9.3 1.2.2.2.5.3.8.3h.3l5.8-1.6c2.2 1.2 4.7 1.7 7.2 1.6 7.1-.4 12.8-6.2 13-13.3.3-4.1-1.1-7.7-3.9-10.4zm-9 22.3c-2.4.1-4.7-.4-6.8-1.5-.1-.1-.3-.1-.4 0l-6 1.7 1.6-6.1c.1-.2 0-.3 0-.5-1.1-2.1-1.6-4.4-1.5-6.8.4-6.4 5.7-11.7 12.2-11.9 3.5-.1 6.8 1.2 9.3 3.7 2.5 2.5 3.8 5.8 3.7 9.3-.4 6.4-5.6 11.8-12.1 12.1z" />
        <path d="M32.3 27.8c0-.4-.1-.9-.5-1.2-.1-.1-.3-.2-.5-.2l-2.3-.8c-.3-.1-.7-.2-1-.2-.8.1-1.3.8-1.5 1l-.1.1s0 .1-.1.1c-.2-.1-.3-.2-.5-.3-.2-.1-.4-.2-.6-.4-.1-.1-.4-.3-.7-.5-.1-.1-.3-.3-.5-.4-.3-.3-.6-.6-.8-.9 0 0-.2-.4-.7-1.1l.1-.1.1-.1c.2-.2.9-.8.9-1.6 0-.4-.1-.7-.2-1l-.9-2.2c-.1-.2-.2-.3-.2-.4-.6-.6-1.1-.6-1.5-.6h-.2c-.3 0-.5 0-.8.1-.5.2-1.2.8-1.5 1.3v.1c-.2.3-.5.9-.6 1.7-.3 1.7.5 3 .9 3.8.2.3.4.6.8 1.1l1.2 1.5c.4.5.9.9 1.4 1.4.4.3 1.2 1 2.3 1.8.5.3.8.5 1.2.7.6.3 1.6.8 2.9.8.3 0 .6 0 .9-.1.8-.2 1.3-.5 1.6-.7h.1c.5-.3 1.1-1 1.2-1.5.1-.3.1-.5 0-.8.1-.3.1-.3.1-.4zm-1.1.7c-.1.2-.4.7-.7.8h-.1c-.2.2-.6.4-1.2.6-1.3.3-2.4-.3-3.1-.6-.3-.2-.5-.3-1.1-.6-1.1-.7-1.8-1.3-2.2-1.6l-1.2-1.2h-.1l-1.2-1.5c-.4-.5-.5-.7-.7-1-.4-.7-1-1.8-.8-3 .1-.6.3-1 .5-1.3V19c.2-.3.6-.7.8-.8H21c0 .1.1.1.1.1l1 2.3c.1.2.2.4.1.4 0 .1-.1.4-.6.9v.1c-.2.2-.4.4-.5.8 0 .4.2.7.3.8.1.1.7 1 .8 1.1.3.4.6.7 1 1.1.2.2.4.4.6.5.3.3.6.5.8.6.3.2.5.3.8.5.1.1.3.1.4.2.2.1.5.3.8.2.4-.1.6-.3.8-.5l.1-.1c.4-.5.7-.6.8-.6.1 0 .2 0 .4.1l2.3.8s.1 0 .1.1v.5c.1.3.1.3.1.4z" />
      </g>
    </svg>
  )
  export default IWhatsapp;